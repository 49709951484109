import { Role } from "./role.model";

export interface CustomerProfile {
    id: number;
    marketAreaCustomerNumber: string;
    sgvId: string;
    companyLocationNumber: string;
    assignments?: BasicUserInfo[];
    customerName: string;
    tomGroup: TomGroup | undefined;
    roles: Role[];
    siteName: string;
    completionStatus: ProfileStatusType;
    changed: boolean;
    changeAmount: number;
    customerId: string;
    customerProfileId: number;
}

export interface BasicUserInfo {
    userId?: number;
    userName?: string;
    salutation?: string;
    firstname?: string;
    lastname?: string;
    email?: string;
    phone?: string;
}

export interface CustomerProfileResponse {
    customerProfiles: CustomerProfile[],
    errors: Error[]
}

export interface TomGroup {
    description: string;
    id: number;
    groupName: string;
}

export enum RoleName {
    SENDER = 'SENDER',
    CUSTOMER = 'CUSTOMER',
    RECIPIENT = 'RECIPIENT'
}
/*
export interface Role {
    abbreviation: string;
    id: number;
    role: RoleName;
}
*/
export enum ProfileStatusType {
    INCOMPLETE = 'INCOMPLETE',
    COMPLETE = 'COMPLETE',
    ALL = 'ALL'
}

export interface CustomerProfileSave {
    customerProfileId?: number | null,
    sgv: string | null,
    partnerId: string | null,
    group: number | null | undefined,
    roles: number[],
    isComplete?: boolean
}