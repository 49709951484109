// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.details-h2-normal {
  font-weight: normal;
}

.elm-button {
  font-weight: bold;
}

.details-info-block {
  display: flex;
}
.details-info-block .info-block-part {
  width: 45%;
}
.details-info-block .info-block-part > div {
  display: flex;
}
.details-info-block .info-block-part > div div {
  width: 50%;
  text-align: left;
  margin: 5px 0;
}
.details-info-block .info-block-part:first-of-type {
  margin-right: 10%;
}

.elm-progress {
  height: 15px;
  padding-top: 10px;
}

tbody tr td:first-of-type {
  font-weight: bold;
}

.border {
  height: 1px;
  border: solid 1px #c8cdd2;
  margin-top: 5px;
  margin-bottom: 30px;
}

input[type=file] {
  display: none;
}

.custom-file-upload {
  display: inline-block;
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 0.2rem 1rem;
  font-size: 1rem;
  font-weight: bold;
  min-height: 2.25rem;
  align-items: center;
  justify-content: center;
  background-color: #ec0016;
  color: #ffffff;
}

.button-group {
  padding-top: 5%;
  text-align: center;
}
.button-group button {
  width: 135px;
  font-weight: bold;
  margin-left: 30px;
  margin-right: 5px;
}
.button-group button:last-of-type {
  margin-right: unset;
}
.button-group input {
  width: 135px;
  font-weight: bold;
  margin-left: 5px;
  margin-right: 30px;
}
.button-group input:last-of-type {
  margin-right: unset;
}

.modal-header {
  padding-top: 1px;
  padding-left: 30px;
  height: 70px;
}
.modal-header .close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
