// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-group {
  padding-top: 10%;
  text-align: center;
}
.button-group button {
  width: 135px;
  margin-left: 30px;
  margin-right: 5px;
}
.button-group button:last-of-type {
  margin-right: unset;
}

.modal-details {
  overflow-y: auto;
  max-height: 250px;
  white-space: pre-line;
  margin-top: 1em;
}

.modal-header {
  padding-top: 1px;
  padding-left: 30px;
  height: 70px;
  color: #ec0016;
}
.modal-header .close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
}

.border-line {
  height: 1px;
  border: solid 1px #c8cdd2;
  margin-top: 23px;
  margin-bottom: 9px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
