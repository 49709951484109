// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.side-navigation-block {
  position: fixed;
  left: 0;
  top: 0;
  width: 290px;
  height: 100vh;
  background-color: #fff;
  z-index: 9999;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1882352941);
}
.side-navigation-block h2 {
  padding-left: 16px;
}

.icon-block {
  display: flex;
  justify-content: space-between;
  padding: 24px 16px;
}
.icon-block .icon {
  width: 32px;
  height: 32px;
}

nav ul li {
  border-bottom: 1px solid #D4D5D7;
}
nav ul li svg {
  margin-right: 10px;
}
nav ul li a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  padding-left: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
}
nav ul li a i {
  margin-right: 10px;
}
nav ul li a:hover {
  background-color: #F7F7F7;
}
nav ul .expandable-menu-item {
  position: relative;
}
nav ul .expandable-menu-item .expandable-menu-title {
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
}
nav ul .expandable-menu-item .expandable-menu-title:hover {
  background-color: #F7F7F7;
}
nav ul .expandable-menu-item i {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 10px;
  right: 6px;
}
nav ul .expandable-menu-item input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 44px;
  cursor: pointer;
  opacity: 0;
  z-index: 1;
}
nav ul .expandable-menu-item .expand-less {
  display: none;
}
nav ul .expandable-menu-item input[type=checkbox]:hover ~ .expandable-menu-title {
  background-color: #F7F7F7;
}
nav ul .expandable-menu-item input[type=checkbox]:checked ~ .expand-less {
  display: block;
}
nav ul .expandable-menu-item input[type=checkbox]:checked ~ .expand-more {
  display: none;
}
nav ul .expandable-menu-item input[type=checkbox]:checked ~ .nested-items {
  display: block;
}
nav ul .expandable-menu-item .nested-items {
  display: none;
}
nav ul .expandable-menu-item .nested-items a {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 50px;
}

.active-link {
  font-weight: bold;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
