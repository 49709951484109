import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-confirmation-display',
  templateUrl: './confirmation-display.component.html',
  styleUrls: ['./confirmation-display.component.scss']
})
export class ConfirmationDisplayComponent implements OnInit {
  confirmationText: string;

  constructor( private dialogRef: MatDialogRef<ConfirmationDisplayComponent>,
               @Inject(MAT_DIALOG_DATA) displayText: string) {
    this.confirmationText = displayText;
  }

  ngOnInit(): void {
  }

}
