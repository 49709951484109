// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.remarkicon {
  float: right;
  margin-block-start: -25px;
}

section {
  margin: 15px 16px 16px;
  padding: 30px 18px 30px 30px;
  background-color: #fff;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}

.header-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.controls-filter {
  display: flex;
  width: 1795px;
  justify-content: space-between;
  margin-bottom: 35px;
}
.controls-filter div i {
  margin-left: 0px;
}

.controls-block {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 10px 13px 10px 0px;
}
.controls-block h3 {
  margin: 0;
}
.controls-block div {
  margin: 5px 10px;
}
.controls-block div select {
  min-width: 150px;
  max-width: 250px;
}
.controls-block .sort-block {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
}
.controls-block .sort-block i {
  margin-bottom: 5px;
}
.controls-block .download-block * {
  cursor: pointer;
}

.cmp-pagination ol {
  text-align: right;
}

.active-page {
  font-size: 1.2rem;
}

.scroll-element {
  height: 2px;
}

.schedule {
  color: #C50014;
}

.error {
  color: #FFBB00;
}

.check-circle {
  color: #508B1B;
}

.arrow-forward {
  color: #0087B9;
}

.remove {
  color: #C50014;
}

.list-table {
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.08), 0 5px 30px 0 rgba(0, 0, 0, 0.05);
}
.list-table th {
  padding: 13px 20px 13px 5px;
}
.list-table th:first-of-type {
  min-width: 54px;
}
.list-table th:last-of-type {
  min-width: 85px;
}
.list-table tbody .white-row {
  background-color: #fff;
}
.list-table tbody .white-row:hover {
  background-color: #D7DCE1;
}
.list-table tbody > tr:not(.white-row) {
  background-color: #eef1f3;
}
.list-table tbody > tr:not(.white-row):hover {
  background-color: #D7DCE1;
}
.list-table tbody .deactivated-row {
  opacity: 0.4;
}
.list-table tbody .train-row td {
  padding: 0;
  height: 52px;
}
.list-table tbody .progress-bar-block {
  height: 7px;
  border-radius: 3px;
  background-color: #f8f8f8;
  margin-top: 4px;
  margin-bottom: 9px;
  margin-right: 10%;
  padding: 0 0 0 0;
}
.list-table tbody .progress-bar-block .progress-bar {
  height: 7px;
  width: 0%;
  border-radius: 3px;
  background-color: #63A615;
}
.list-table tbody .progress-bar-block .progress-train-late {
  background-color: #FFD800;
}
.list-table tbody .progress-bar-block .progress-train-tooLate {
  background-color: #ec0016;
}
.list-table tbody .progress-bar-block .progress-train-parked {
  background-color: #535050;
}
.list-table tbody .vertical-align {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.list-table .icons-block .cmp-dropdown {
  display: inline-flex;
}
.list-table .icons-block .cmp-dropdown summary::before {
  --icon-glyph: "";
}
.list-table .icons-block .disruption {
  position: absolute;
  top: 10px;
  left: 19px;
  height: 14px;
  width: 14px;
}
.list-table .icons-block summary::before {
  background-color: rgba(0, 106, 150, 0.01);
  padding: 0;
  transform: translate(2px, -4px);
}
.list-table .icons-block .cmp-overflow-menu {
  display: inline-flex;
}
.list-table .icons-block .cmp-dropdown menu {
  margin: 0;
}
.list-table .icons-block .cmp-dropdown menu li button {
  min-width: 270px;
  overflow: visible;
  display: block;
  cursor: pointer;
}

.center-content {
  display: flex;
  justify-content: center;
}

.order-row {
  display: none;
}
.order-row td {
  background-color: #fff;
  padding-bottom: 0;
}

.order-row-show {
  display: table-row;
}

.arrow {
  text-align: right;
}
.arrow svg {
  transform: rotate(0);
  transition: all ease-in-out 0.3s;
  z-index: 1;
}

.arrow-up {
  fill: #0087b9;
  border-left: 4px solid #0087b9;
}
.arrow-up svg {
  transform: rotate(-90deg);
}

.deactivated-arrow {
  opacity: 0.4;
}
.deactivated-arrow .icon {
  cursor: default;
}

.blue-border {
  border-left: 4px solid #0087b9;
}

.trains-sub-table {
  margin-left: 51px;
}
.trains-sub-table th {
  border: none;
  font-weight: bold;
  padding: 8px 20px 7px 20px;
}
.trains-sub-table th:first-of-type > div {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.trains-sub-table th:first-of-type small {
  font-weight: normal;
}
.trains-sub-table th:nth-last-child(3) {
  min-width: 118px;
}
.trains-sub-table th:nth-last-child(4) {
  min-width: 122px;
}
.trains-sub-table th:last-of-type {
  width: 100%;
}
.trains-sub-table tr {
  background: #fff;
}
.trains-sub-table tr td:first-of-type {
  min-width: 140px;
}
.trains-sub-table th, .trains-sub-table td {
  border-bottom: 1px solid rgba(40, 45, 55, 0.2);
  padding: 20px;
}
.trains-sub-table .arrow-right-corner {
  fill: #ec0016;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
