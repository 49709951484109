import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GoodModel } from 'src/app/models/Cargo.model';

@Component({
  selector: 'app-nhm-code-details',
  templateUrl: './nhm-code-details.component.html',
  styleUrls: ['./nhm-code-details.component.scss']
})
export class NhmCodeDetailsComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public nhmCodeDetails: {nhmCodes: GoodModel[], orderNumber: string | undefined, zabOrderNumber: string | undefined }) { }

  ngOnInit(): void {
  }

}
