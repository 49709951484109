import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import '@angular/common/locales/global/de';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpLoaderFactory, SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HeaderComponent } from './general/header/header.component';
import { ErrorComponent } from './general/error/error.component';
import { LocaleComponent } from './locale/locale.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CustomerSelectComponent } from './customer-select/customer-select.component';
import { TrainIdPipe } from './shared/pipes/train-id.pipe';
import { EnvServiceFactory, EnvServiceProvider } from './services/env/env.service.provider';
import { SideNavigationComponent } from './general/side-navigation/side-navigation.component';
import { TrainTypePipe } from './shared/pipes/train-type.pipe';
import { ModalWindows } from './shared/modal-windows/modal-windows';
import { OAuthModule, OAuthStorage } from "angular-oauth2-oidc";
import { AuthGuard } from './auth/auth.guard';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { FooterModule } from './general/footer/footer.module';
import { SystemInformationModule } from './trainorder/system-information/system-information.module';
import { MonthViewComponent } from './trainorder/month-view/month-view.component';
import { MonthFilterComponent } from './trainorder/month-view/month-filter/month-filter.component';
import { PopupMenuComponent } from './shared/popup-menu/popup-menu.component';
import { ListViewComponent } from './trainorder/list-view/list-view.component';
import { ListViewFilterComponent } from './trainorder/list-view/list-view-filter/list-view-filter.component';
import { WeekViewComponent } from './trainorder/week-view/week-view.component';
import { WeekViewFilterComponent } from './trainorder/week-view/week-view-filter/week-view-filter.component';
import { DBUIElementsModule } from '@db-ui/ngx-elements-enterprise/dist/lib';
import { LanguageInterceptor } from './interceptors/language.interceptor';
import { GlobalErrorHandler } from './general/error-handler/error-handler';

const envService = EnvServiceFactory();

export function storageFactory() : OAuthStorage {
  return localStorage
}
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LocaleComponent,
    ErrorComponent,
    WeekViewComponent,
    MonthViewComponent,
    ListViewComponent,
    CustomerSelectComponent,
    SideNavigationComponent,
    WeekViewFilterComponent,
    MonthFilterComponent,
    ListViewFilterComponent,
    PopupMenuComponent
  ],
  imports: [
    DBUIElementsModule,
    MatDialogModule,
    MatSelectModule,
    BrowserModule,
    SharedModule,
    AppRoutingModule,
    FooterModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    SystemInformationModule,
    NgMultiSelectDropDownModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [envService.backendUrl, envService.backendUrlUserManagement, envService.backendUrlOm],
        sendAccessToken: true
      }
    })
  ],
  providers: [
    TrainIdPipe,
    { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'de-DE' },
    { provide: OAuthStorage, useFactory: storageFactory },    
    EnvServiceProvider,
    TrainTypePipe,
    ModalWindows,
    AuthGuard,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
