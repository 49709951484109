import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ErrorData } from '../api-error/ErrorData';

@Component({
  selector: 'app-api-info',
  templateUrl: './api-info.component.html',
  styleUrls: ['./api-info.component.scss']
})
export class ApiInfoComponent {
  errorText?: string;

  constructor(private dialogRef: MatDialogRef<ApiInfoComponent>,
    @Inject(MAT_DIALOG_DATA) data: ErrorData) {
    this.errorText = data.text;
  }

}
