// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.arrow {
  height: 24px;
}
.arrow svg {
  transform: rotate(90deg);
  transition: all ease-in-out 0.3s;
  z-index: 1;
}

.arrow-up svg {
  transform: rotate(-90deg);
}

.arrow-div {
  align-items: center;
  display: flex;
}

.order-details-area {
  display: none;
}

.order-details-area-show {
  display: block;
}

.details-info-block {
  display: flex;
  padding-left: 30px;
  padding-right: 30px;
}
.details-info-block .info-block-part {
  width: 90%;
}
.details-info-block .info-block-part > div {
  display: flex;
}
.details-info-block .info-block-part > div div {
  width: 100%;
  text-align: left;
  margin: 5px 0;
}
.details-info-block .info-block-part:first-of-type {
  margin-right: 10%;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
