import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { DateTimePipe } from './pipes/date-time.pipe';
import { TrainDetailsComponent } from './train-details/train-details.component';
import { TrackingHistoryComponent } from './tracking-history/tracking-history.component';
import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TrainIdPipe } from './pipes/train-id.pipe';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NhmCodePipe } from './pipes/nhm-code.pipe';
import { CustomerSgvNamePipe } from './pipes/customer-sgv-name.pipe';
import { BorderCodeNamePipe} from "./pipes/border-code-name.pipe";
import { ConfirmationDisplayComponent } from './confirmation-display/confirmation-display.component';
import { TrainTypePipe } from './pipes/train-type.pipe';
import { ErrorDialogComponent } from './api-error/api-error.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { ApiInfoComponent } from './api-info/api-info.component';
import { BorderStationPipe } from './pipes/border-station.pipe';
import { RolesPipe } from './pipes/roles.pipe';
import { MarketareaCustomerNamePipe } from './pipes/marketarea-customer-name.pipe';
import { ProfileListUpdateComponent } from '../user-management/profile-list/profile-list-update/profile-list-update.component';
import { CustomerProfileAssignementPipe } from './pipes/customer-profile-assignement.pipe';
import { MarketSegmentNamePipe } from './pipes/market-segment-name.pipe';
import { DBUIElementsModule } from '@db-ui/ngx-elements-enterprise/dist/lib';
import { ConfirmationModule } from './confirmations/confirmation.module';
import { WagonNumberPipe } from './pipes/wagon-number.pipe';
import { OrderNumberPipe } from './pipes/order-number.pipe';

@NgModule({
    declarations: [
        DateTimePipe, 
        TrainDetailsComponent, 
        TrackingHistoryComponent, 
        TrainIdPipe, 
        NhmCodePipe, 
        CustomerSgvNamePipe, 
        BorderCodeNamePipe, 
        ConfirmationDisplayComponent, 
        TrainTypePipe, 
        ErrorDialogComponent, 
        FileUploadComponent, 
        ApiInfoComponent, 
        BorderStationPipe, 
        RolesPipe, 
        MarketareaCustomerNamePipe, 
        ProfileListUpdateComponent, 
        CustomerProfileAssignementPipe, 
        MarketSegmentNamePipe,
        WagonNumberPipe,
        OrderNumberPipe
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        DBUIElementsModule,
        MatDialogModule,
        ConfirmationModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
            isolate: false,
        }),
    ],
    exports: [
        DBUIElementsModule,
        TranslateModule,
        CommonModule,
        FormsModule,
        DateTimePipe,
        MatDialogModule,
        TrainDetailsComponent,
        TrackingHistoryComponent,
        TrainIdPipe,
        NhmCodePipe,
        CustomerSgvNamePipe,
        BorderCodeNamePipe,
        TrainTypePipe,
        BorderStationPipe,
        ReactiveFormsModule,
        RolesPipe,
        MarketareaCustomerNamePipe,
        CustomerProfileAssignementPipe,
        MarketSegmentNamePipe,
        WagonNumberPipe,
        OrderNumberPipe
    ],
    providers: [
        {
            provide: MAT_DIALOG_DATA,
            useValue: {},
        },
    ],
})
export class SharedModule {}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
