import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { TranslateModule } from "@ngx-translate/core";
import { SystemInformationComponent } from "./system-information.component";
import { MatTabsModule } from '@angular/material/tabs';
import { UserManualModule } from "./user-manual/user-manual.module";
import { ReleaseInformationModule } from "./release-information/release-information.module";

@NgModule({
  declarations: [
    SystemInformationComponent
  ],
  imports: [
    CommonModule, 
    MatDialogModule,
    TranslateModule.forChild(),
    MatTabsModule,
    UserManualModule,
    ReleaseInformationModule
  ],
  providers: [
    {
      provide: MatDialogRef,
      useValue: {}
    }
  ]
})
export class SystemInformationModule {

}