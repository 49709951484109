// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-group {
  padding-top: 5%;
  text-align: center;
  display: inline-flex;
  justify-content: flex-end;
  width: 100%;
}
.button-group button {
  margin-left: 10px;
}
.button-group button:last-of-type {
  margin-right: unset;
}
.button-group input {
  width: 135px;
  font-weight: normal;
  margin-left: 5px;
  margin-right: 30px;
}
.button-group input:last-of-type {
  margin-right: unset;
}

.modal-header {
  padding-top: 1px;
  padding-left: 30px;
  height: 70px;
}
.modal-header .close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
