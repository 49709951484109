// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.controls-block {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.controls-block .filter-reset {
  cursor: pointer;
}
.controls-block .tbl-header-filter-reset-download {
  padding-left: 30px;
  padding-bottom: 18px;
}
.controls-block .cmp-overflow-menu menu {
  width: 100%;
}
.controls-block .cmp-overflow-menu button {
  padding: 1rem;
}
.controls-block .cmp-overflow-menu summary {
  cursor: pointer;
  font-size: 1rem;
}
.controls-block .cmp-overflow-menu summary::before {
  display: none;
  --icon-glyph: "";
}

.controls {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.controls .filter-reset {
  cursor: pointer;
}

.main-block {
  display: grid;
  margin-top: 5px;
  margin-bottom: 15px;
  grid-template-columns: 20% 20% 20% 20% 20%;
}
.main-block div {
  position: relative;
}
.main-block .input-field {
  margin-left: 5px;
  margin-right: 5px;
}
.main-block .hide-icon {
  visibility: hidden;
}
.main-block .show-icon {
  visibility: visible;
}
.main-block .icon-block {
  position: absolute;
  width: 24px;
  height: 24px;
  right: 15px;
  top: 32px;
}
.main-block .icon-block .icon {
  position: absolute;
  vertical-align: middle;
}

.controls {
  display: flex;
  justify-content: space-between;
}
.controls .controls-calendar {
  display: flex;
  align-items: center;
  width: 1795px;
}
.controls .controls-calendar img {
  cursor: pointer;
}
.controls span {
  display: inline-block;
  margin-left: 15px;
  margin-right: 25px;
}
.controls a {
  text-decoration: underline;
}
.controls .current-period {
  font-weight: bold;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
