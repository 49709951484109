import { Pipe, PipeTransform } from '@angular/core';
import { BasicUserInfo } from 'src/app/user-management/model/profile.model';

@Pipe({
  name: 'customerProfileAssignement'
})
export class CustomerProfileAssignementPipe implements PipeTransform {

  transform(value: BasicUserInfo[] | undefined, ...args: unknown[]): unknown {
    if(!value || value == null) return '';

    const users = value.map(el => '- ' + el.userName);
    return users.join('\n');
  }

}
