import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Injectable } from "@angular/core";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor() { }

    handleError(error: any) {
        console.error(error);
        // TODO implement error handling for different error types
        // e.g:
        if (error instanceof HttpErrorResponse) {
            console.error("status:", error.status);
            throw error;
        }
        if (error == 'test') {
            console.log("Test error caught...");
            return;
        }
        if (error.status && error.status == 654) {
            console.log("Status:", error.status);
            console.log("Test error caught...", error);
            return;
        }

        throw error;
    }
}