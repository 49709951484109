import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: 'app-delete-order-template-dialog',
    templateUrl: './delete-order-template-dialog.component.html',
    styleUrls: ['./delete-order-template-dialog.component.scss']
})
export class DeleteOrderTemplateDialogComponent implements OnInit {
    templateId: string;

    constructor(
        private dialogRef: MatDialogRef<DeleteOrderTemplateDialogComponent>,
        @Inject(MAT_DIALOG_DATA) templateNum: string
    ) {
        this.templateId = templateNum;
    }

    ngOnInit(): void {
    }

    public onConfirmationClick() {
        this.dialogRef.close(true);
    }

    public onDiscardClick() {
        this.dialogRef.close(false);
    }
}
