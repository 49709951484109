// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `section {
  margin: 15px 16px 16px;
  padding: 30px 18px 30px 30px;
  background-color: #fff;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}

.controls {
  display: flex;
  justify-content: space-between;
}
.controls .controls-calendar {
  display: flex;
  align-items: center;
  width: 1795px;
}
.controls .controls-calendar img {
  cursor: pointer;
}
.controls span {
  display: inline-block;
  margin-left: 15px;
  margin-right: 25px;
}
.controls a {
  text-decoration: underline;
}
.controls .current-period {
  font-weight: bold;
}

.calendar table {
  table-layout: fixed;
  font-size: 0.9em;
}
.calendar table th {
  padding: 8px 20px 13px 5px;
  text-align: center;
  color: #000;
  min-width: 175px;
  border-bottom: 1px solid #000;
  resize: none;
}
.calendar table tbody tr:nth-child(odd) td:nth-of-type(1),
.calendar table tbody tr:nth-child(odd) td:nth-of-type(2),
.calendar table tbody tr:nth-child(odd) td:nth-of-type(3) {
  background-color: #eef1f3;
}
.calendar table tbody td:nth-of-type(9),
.calendar table tbody td:nth-of-type(10) {
  background: rgba(240, 243, 245, 0.3);
}
.calendar table tbody td {
  vertical-align: middle;
  height: 96px;
  width: 170px;
  overflow: unset;
  resize: none;
}
.calendar table tbody .train-number {
  font-weight: bold;
}
.calendar table tbody .day-cell {
  position: relative;
  border: 1px solid #d7dce1;
  padding: unset;
}
.calendar table tbody .day-cell:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.calendar table tbody .day-cell:hover summary::before {
  background-color: #d7dce1;
}
.calendar table tbody #special-trains,
.calendar table tbody #regular-trains {
  height: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: 1px solid #3c414b;
  border-bottom: 1px solid #3c414b;
  background-color: #fff;
}
.calendar table tbody tr:last-of-type td,
.calendar table tbody tr:last-of-type .day-cell {
  border-bottom: unset;
}

.today {
  background: #004860;
  padding: 3px;
  color: #fff;
  border-radius: 3px;
}

.train-info {
  width: 100%;
  height: 100%;
  border-left: 6px solid #004b64;
  background-color: rgba(0, 106, 150, 0.05);
  padding: 8px;
}
.train-info .time-block .time-block-line {
  display: flex;
  justify-content: space-between;
}
.train-info .time-block div {
  display: flex;
}
.train-info .time-block div:first-of-type {
  justify-content: flex-end;
  align-items: baseline;
}
.train-info .time-block div:last-of-type {
  justify-content: space-between;
}
.train-info .time-block div span {
  font-size: 14px;
}
.train-info .time-block div span.eta {
  font-size: 8px;
  margin-right: 5px;
}
.train-info .time-block div span.eta-time {
  font-weight: bold;
}
.train-info .time-block div .train-late {
  color: #ec0016;
}
.train-info .time-block .holiday {
  width: 18px;
  height: 18px;
  margin: 0 33px 8px 0;
  padding: 0 4px 0 5px;
  border-radius: 1px;
  background-color: #004b64;
  opacity: 0;
}
.train-info .time-block .holiday .holiday-text {
  vertical-align: top;
  width: 9px;
  height: 18px;
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
  color: #fdfdfd;
  cursor: pointer;
}
.train-info .time-block .holiday-show {
  opacity: 1;
}
.train-info .progress-bar-block {
  height: 7px;
  border-radius: 3px;
  background-color: #fdfdfd;
  margin-top: 4px;
  margin-bottom: 9px;
}
.train-info .progress-bar-block .progress-bar {
  height: 7px;
  width: 0%;
  border-radius: 3px;
  background-color: #63A615;
}
.train-info .progress-bar-block .progress-train-late {
  background-color: #FFD800;
}
.train-info .progress-bar-block .progress-train-tooLate {
  background-color: #ec0016;
}
.train-info .progress-bar-block .progress-train-parked {
  background-color: #535050;
}
.train-info .icons-block {
  position: relative;
  display: flex;
  justify-content: flex-end;
  height: 24px;
}
.train-info .icons-block > div {
  margin-right: 15px;
}
.train-info .icons-block .cmp-overflow-menu summary::before {
  --icon-glyph: "";
}
.train-info .icons-block .disruption {
  position: absolute;
  top: 10px;
  left: 19px;
  height: 14px;
  width: 14px;
}
.train-info .icons-block summary::before {
  background-color: rgba(0, 106, 150, 0.01);
  padding: 0;
  transform: translate(2px, -4px);
}
.train-info .icons-block .cmp-overflow-menu menu {
  right: -233px;
}
.train-info .icons-block .cmp-overflow-menu menu li button {
  min-width: 270px;
  min-height: 50px;
  overflow: visible;
  display: block;
  cursor: pointer;
}

.deactivated {
  border-left: 6px solid rgb(43, 43, 43);
  opacity: 0.4;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
