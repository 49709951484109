import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ApiOrderReductionRequest, OrderDetails, OrderItem } from 'src/app/models/ApiOrders.model';
import { ModalWindows } from 'src/app/shared/modal-windows/modal-windows';
import { TrainorderService } from '../../services/trainorder.service';
import { ErrorProcessingService } from "../../../services/error-processing.spec";

@Component({
  selector: 'app-capacity-reduction',
  templateUrl: './capacity-reduction.component.html',
  styleUrls: ['./capacity-reduction.component.scss'],
})
export class CapacityReductionComponent implements OnInit {
  
  public reductionForm: FormGroup;
  
  protected error: string;
  protected orderDetails: OrderDetails;

  get plannedNumberOfWagons(): FormControl {
    return this.reductionForm.controls['plannedNumberOfWagons'] as FormControl;
  }
  get plannedNetWeight(): FormControl {
    return this.reductionForm.controls['plannedNetWeight'] as FormControl;
  }
  get otherInformation(): FormControl {
    return this.reductionForm.controls['otherInformation'] as FormControl;
  }

  constructor(@Inject(MAT_DIALOG_DATA) public param: { orderItem: OrderItem },
              private trainorderService: TrainorderService,              
              private translateService: TranslateService,
              private modalWindows: ModalWindows,
              private errorProcessing: ErrorProcessingService
  ) { }

  ngOnInit(): void {
    this.createForm();
    this.fetchOrderDetails();
  }

  private fetchOrderDetails(): void {
    this.trainorderService.sendOrderDetailsRequest(this.param.orderItem.orderNumber).subscribe({
      next: (orderDetails: OrderDetails) => {
        this.orderDetails = orderDetails;
      },
      error: (error: Error) => {
          console.error('Failed to fetch data', error);
      }
    });
  }

  private createForm(): void {
    this.reductionForm = new FormGroup({
      plannedNumberOfWagons: new FormControl(this.param.orderItem.numberOfWagons, [Validators.min(1), Validators.max(+this.param.orderItem.numberOfWagons), Validators.required]),
      plannedNetWeight: new FormControl(this.param.orderItem.netWeight, [Validators.max(+this.param.orderItem.netWeight), Validators.min(1), Validators.required]),
      otherInformation: new FormControl(),
    });
  }

  protected reduceValues(): void {
    if(this.plannedNumberOfWagons.value == this.param.orderItem.numberOfWagons && this.plannedNetWeight.value == this.param.orderItem.netWeight) {
      this.modalWindows.openInfoDialog({text: this.translateService.instant("Shared.Errors.No-reduction-of-wagons-nor-weight")});
      return;
    }
    let body: ApiOrderReductionRequest = {
      reducedNumberOfWagons: this.plannedNumberOfWagons.value,
      reducedWeight: this.plannedNetWeight.value,
      reductionNote: this.otherInformation.value
    };

    this.trainorderService.postOrderReduce(this.param.orderItem.orderNumber, body).subscribe((response: any) => {
      this.resetAndCloseOrderForm();
    }, (response: HttpErrorResponse) => {
      console.error(response);
      let message = this.errorProcessing.extractMessage(response);
      this.modalWindows.openErrorDialog({ apiProblem: response.error });
      this.error = message;
    });
  }

  private resetAndCloseOrderForm(): void {
    this.modalWindows.closeAllModalWindows();
    this.modalWindows.openConfirmationDialog(this.translateService.instant('Order-component.Cancellation.Reduced'), 3);
  }
}
