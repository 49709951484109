import { Component, OnInit, ViewChild } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { AuthService } from 'src/app/auth/auth.service';
import { Observable, of } from 'rxjs';
import { LocalStorageService } from 'src/app/services/local-storage/local-storage.service';
import { EnvService } from 'src/app/services/env/env.service';
import { CustomerSelectComponent } from 'src/app/customer-select/customer-select.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @ViewChild('customer_select', { static: false }) customerSelection!: CustomerSelectComponent;
  
  public isMenuOpened: boolean = false;
  protected nickname: Observable<string>;

  constructor(public oauthService: OAuthService, private authService: AuthService, private storageService: LocalStorageService, private envService: EnvService) { }

  ngOnInit() {
    if(this.storageService.getUsername()) {
      this.nickname = of(this.storageService.getUsername() || '');
    } else {
      this.storageService.watchLocalStorage().subscribe(_ => this.nickname = of(this.storageService.getUsername() || ''));
    }
  }

  protected tryLogin() {    
    console.log("try login from header triggered");
    this.oauthService.logOut(true);
    this.authService.tryLogin();
  }

  protected logout() {
    this.authService.logoutAndTryLogin();
  }

  public closeMenu(): void {
    this.isMenuOpened = false;
  }

  protected isLoggedIn(): boolean {
    const hasValidToken = this.oauthService.getAccessToken() != null;
    
    return hasValidToken;
  }
}
