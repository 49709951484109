import {Component, OnInit} from '@angular/core';
import {Observable, of, Subject, Subscription} from 'rxjs';
import {FormControl, FormGroup} from '@angular/forms';
import {TrainorderService} from '../trainorder/services/trainorder.service';
import {Authorization, CustomerData, CustomerProfile} from '../models/authorization';
import {LocalStorageService} from '../services/local-storage/local-storage.service';
import {PermissionService} from '../trainorder/permission/PermissionService';
import {Customer, CustomerResponse, Site, SiteResponse} from "../models/ApiCustomers.model";
import {CustomerSgvNamePipe} from "../shared/pipes/customer-sgv-name.pipe";
import {debounceTime} from "rxjs/operators";

export interface DropDownModel {
    idField: number;
    textField: string;
}

@Component({
    selector: 'app-customer-select',
    templateUrl: './customer-select.component.html',
    styleUrls: ['./customer-select.component.scss'],
    providers: [CustomerSgvNamePipe]
})
export class CustomerSelectComponent implements OnInit {

    public customerSelected: Subject<CustomerProfile> = new Subject<CustomerProfile>();
    
    protected customerSelectList: CustomerData[] = [];
    protected customers4Dropdown: DropDownModel[];
    protected activeCustomers: CustomerProfile[] = [];
    protected observeOptionsList$: Observable<boolean> = of(false);
    protected selectedItems = [];
    protected allProfiles = false;
    protected partnerIdList: Site[];
    protected sgvAutoComplete: Customer[] = [];
    protected selectCustomerForm: FormGroup = new FormGroup({
        selectedCustomer: new FormControl(''),
        sgvId: new FormControl(''),
        partnerId: new FormControl({
            disabled: true,
            value: ''
        })
    });

    private sgvInputChange: Subject<string> = new Subject<string>();

    constructor(
        private permissionService: PermissionService,
        private trainorderService: TrainorderService,
        private storageService: LocalStorageService,
        private customerSgvNamePipe: CustomerSgvNamePipe
    ) {
        this.registerForPermissionChanges();
        this.registerForInputChanges();
    }

    ngOnInit(): void {
        this.loadCustomerProfiles();
    }

    private registerForInputChanges(): void {
        this.sgvInputChange.pipe(debounceTime(500)).subscribe((input) => {
            if (input.length === 0) {
                this.clearSearchInput();
            } else {
                this.getSgvAutocompleteSuggestions(input);
            }
        });
    }

    private registerForPermissionChanges(): void {
        this.permissionService.hasPermission(null, [Authorization.READ_ALL_PROFILES]).subscribe(result => {
            this.allProfiles = result;
            if(this.allProfiles) this.setFormFields4Admin();
        }, (error: any) => {
            console.error(error);
        });
    }

    private setFormFields4Admin() {
        const activeProfiles = this.storageService.getActiveProfiles();
        if(activeProfiles != null && activeProfiles.length > 0) {
            const activeProfile = activeProfiles[0];
            this.selectCustomerForm.get("sgvId")?.setValue(this.customerSgvNamePipe.transform({name: activeProfile.customerName, sgvNumber: activeProfile.sgvId}));
            this.selectCustomerForm.get('partnerId')?.enable();
            this.updateActiveProfiles(true, activeProfile);
        }
    }

    private loadCustomerProfiles() {
        this.customerSelectList = this.storageService.getCustomerProfiles();
        if (this.customerSelectList != null && this.customerSelectList.length) {
            this.selectedCustomer.setValue(0);
            this.setProfiles();
            return;
        }

        // no profile was set. wait for permissions to load
        const watchPermissionSubscrition = this.storageService.watchPermissions().subscribe(
            {
                next: (_) => {
                    this.customerSelectList = this.storageService.getCustomerProfiles();
                    if (this.customerSelectList != null && this.customerSelectList.length) {
                        this.selectedCustomer.setValue(0);
                        this.setProfiles();
                    }
                    watchPermissionSubscrition.unsubscribe();
                },
                error: (error: any) => {
                    console.error(error);
                }
            }
        );
    }

    private setProfiles() {
        this.toDropdownModel();
        this.setActiveProfilesInSelectField();
    }

    private setActiveProfilesInSelectField() {
        const activeProfiles = this.storageService.getActiveProfiles();
        if (activeProfiles) {
            this.activeCustomers = activeProfiles;
            const activeProfile: CustomerProfile = this.activeCustomers[0];
            const idx = this.findIndexOfCustomerProfile(activeProfile);
            if (idx >= 0)
                this.selectedCustomer.setValue(idx);
        }
        this.observeOptionsList$ = of(true);
    }

    private findIndexOfCustomerProfile(cp: CustomerProfile): number {
        return this.customerSelectList.findIndex(cd => cd.sgvId == cp.sgvId && cd.partnerId == cp.partnerId);
    }

    private toDropdownModel() {
        this.customers4Dropdown = [];
        let counter = 0;
        this.customerSelectList.forEach((cp: CustomerData) => {
            this.customers4Dropdown.push({idField: counter, textField: cp.siteName});
            counter++;
        });
    }

    protected clearSearchInput(): void {
        this.selectCustomerForm.get('sgvId')?.setValue(null);
        this.customerSelectList = [];
        this.selectCustomerForm.get('partnerId')?.setValue(null);
        this.selectCustomerForm.get('partnerId')?.disable();
        this.partnerIdList = [];
        this.sgvAutoComplete = new Array();
        this.storageService.removeActiveProfiles();
        const cp: CustomerProfile = {
            sgvId: '', // TODO shouldn't it be null or undefined? 
            partnerId: '' // TODO shouldn't it be null or undefined?
        }
        this.customerSelected.next(cp);
       
    }

    protected selectCustomer(event: any): void {
        const customerData = this.customerSelectList[event.target.value];
        // const customerData = this.readCustomerSelect();
        if(customerData) {
            this.storageService.setActiveProfiles([customerData], true);
            this.customerSelected.next(customerData);
        }
    }

    protected getSgvIdsList(event: any) {
        this.sgvInputChange.next(event.target.value);
    }

    protected selectSgvId(event: any) {
        if (!event.target.value || event.target.value == '' || event.target.value == null) return;
        this.selectCustomerForm.get("partnerId")?.setValue(null);
        this.selectCustomerForm.get('partnerId')?.enable();
        this.sgvAutoComplete = new Array();
        this.updateActiveProfiles(true);
    }

    private updateActiveProfiles(updateSites = false, activeProfile: CustomerProfile | null = null) {
        const sgvAndName = this.customerSgvNamePipe.transform(this.selectCustomerForm.get('sgvId')?.value) as Customer;
        if (sgvAndName.sgvNumber) {
            if (updateSites) {
                this.trainorderService.getSites4Sgv(sgvAndName.sgvNumber).subscribe((result: SiteResponse) => { // FIXME: nicht in dieser Funktion
                    this.partnerIdList = result;
                    // this.partnerIdList.unshift({ name: '', partnerId: '' });

                    // if an activeProfile was selected previously, set form field-partnerid, if partnerid is in activeProfile
                    if(activeProfile != null && activeProfile.partnerId && activeProfile.partnerId.length > 0) {
                        this.selectCustomerForm.get("partnerId")?.setValue(activeProfile.partnerId);
                    }
                    // else: select first entry of list
                    else if(result.length > 0) {
                        this.selectCustomerForm.get("partnerId")?.setValue(result[0].partnerId);
                    }
                    this.setActiveProfile(sgvAndName, activeProfile);
                }, (error: any) => {
                    console.error(error);
                });
            } else {
                this.setActiveProfile(sgvAndName, activeProfile);
            }
        } else {
            this.setActiveProfile(sgvAndName, activeProfile);
        }
    }

    private setActiveProfile(sgvAndName: Customer, activeProfile: CustomerProfile | null = null) {
        const partnerId = this.selectCustomerForm.get('partnerId')?.value ? this.selectCustomerForm.get('partnerId')?.value : activeProfile != null ? activeProfile.partnerId : '';
        const profile: CustomerData = {
            sgvId: sgvAndName.sgvNumber,
            partnerId: partnerId,
            customerName: sgvAndName.name,
            authorization: [],
            siteName: ''
        };

        this.storageService.setActiveProfiles([profile], true);
        this.customerSelected.next(profile);
    }

    protected selectPartnerId(event: any) {
        if (!event.target.value || event.target.value == '' || event.target.value == null) {
            // remove partner id from active profile
            const aps = this.storageService.getActiveProfiles();
            if(aps && aps.length > 0) {
                const ap = aps[0];
                ap.partnerId = '';
                this.storageService.setActiveProfiles([ap]);
            }
        }
        this.updateActiveProfiles();        
    }

    protected getSgvAutocompleteSuggestions(input: string): void {
        let foundCustomer = this.sgvAutoComplete.find(el => input.indexOf(el.name) > 0);
        if (foundCustomer != null) {
            return;
        }

        if (input.length > 2) {
            this.sgvAutoComplete = [];
            this.trainorderService.getCustomers(input).subscribe((result: CustomerResponse) => {
                this.sgvAutoComplete = result;
                if(this.sgvAutoComplete) {
                    const tempList: Customer[] = [];
                    for (let customer of this.sgvAutoComplete) {
                        if(customer.sgvNumber.length > 0) {
                            const tempCustomer = tempList.find(c => {
                                return c.sgvNumber === customer.sgvNumber;
                            });
                            if (!tempCustomer) {
                                tempList.push(customer);
                            }
                        }
                    }
                    this.sgvAutoComplete = tempList;
                }
            }, (error: any) => {
                console.error(error);
            });
        }
    }

    get selectedCustomer() {
        return this.selectCustomerForm.get("selectedCustomer") as FormControl;
    }
}