// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea {
  resize: none;
}

.head-resumen {
  margin-left: 11px;
  font-weight: bold;
  font-size: 1.3em;
}

.form-control-block {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}
.form-control-block button:last-of-type {
  margin-left: 1rem;
}
.form-control-block .error-block {
  margin-bottom: 7px;
}

.service-hint {
  font-size: 0.9rem;
}

.disruption {
  display: flex;
  background: #fad2d6;
  margin: 20px 0 20px 0;
  padding: 10px;
}
.disruption div:last-of-type {
  margin-left: 1px;
}

.arrow {
  height: 24px;
}
.arrow svg {
  transform: rotate(90deg);
  transition: all ease-in-out 0.3s;
  z-index: 1;
}

.arrow-up svg {
  transform: rotate(-90deg);
}

.arrow-div {
  align-items: center;
  display: flex;
}

.order-details-area {
  display: none;
}

.order-details-area-show {
  display: block;
}

.details-info-block {
  display: flex;
  padding-left: 30px;
  padding-right: 30px;
}
.details-info-block .info-block-part {
  width: 90%;
}
.details-info-block .info-block-part > div {
  display: flex;
}
.details-info-block .info-block-part > div div {
  width: 100%;
  text-align: left;
  margin: 5px 0;
}
.details-info-block .info-block-part:first-of-type {
  margin-right: 10%;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
