// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.adjustment {
  display: flex;
  justify-content: space-between;
}

#other-information {
  width: 100%;
  height: 150px;
  background-color: #f0f3f5;
  border: none;
  resize: none;
}

.textarea-length {
  float: right;
}

.order-summary {
  display: flex;
  align-items: center;
}

.controls-block {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}
.controls-block button:last-of-type {
  margin-left: 1rem;
}
.controls-block .error-block {
  margin-bottom: 7px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
