import { Injectable, Inject, LOCALE_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OrderItem } from 'src/app/models/ApiOrders.model';
import { TrainIdentifier, TrainSummary } from 'src/app/models/ApiTrainsList.models';
import { OrderTemplateSummary } from 'src/app/models/OrderTemplateModels';
import { formatDate} from '@angular/common';

export enum ExportType {
    ORDERS_LIST = "orders-list",
    TRAINS_LIST = "trains-list",
    ORDER_TEMPLATE_LIST = "order-template-list"
}
@Injectable({
    providedIn: 'root',
})
export class FileExportService {
    
    constructor(
        private translate: TranslateService, @Inject(LOCALE_ID) private locale: string)  {
    }

    /**
     * @param data array of train orders
     */
     exportTrainsToCsv(data: TrainSummary[]): void {
        // Columns, that are needed in the csv file (we don't take the whole object)
        const dataObjectHeader: any = {
            trainId: this.translate.instant('Order-view-page.Train-id-label'),
            trainNumber: this.translate.instant('Order-view-page.Train-number-label'),
            currentTrainNumber: this.translate.instant('Order-view-page.Current-train-number-label'),
            productionDate: this.translate.instant('Order-view-page.Production-date-label'),
            sendingStation: this.translate.instant('Order-view-page.Shipping-station-label'),
            receivingStation: this.translate.instant('Order-view-page.Receiving-station-label'),
            operationalMode: this.translate.instant('Order-view-page.Operational-mode-label'),
            plannedDeparture: this.translate.instant('Order-view-page.Sending-date-label'),
            actualDeparture: this.translate.instant('Order-view-page.Actual-sending-date-label'),
            plannedArrival: this.translate.instant('Order-view-page.ETA-label'),
            actualArrival: this.translate.instant('Order-view-page.Actual-arrival-label'),
            delayInMinutes: this.translate.instant('Order-view-page.delayInMinutes-label'),
            orderStatus: this.translate.instant('Order-view-page.Train-status-label'),
            cancelReasonName: this.translate.instant('Order-view-page.Train-cancel-label'),
            cancellationFee: this.translate.instant('Order-view-page.cancellationFee-CSV-label')
        };
        
        this.exportToCsv(data, dataObjectHeader, 'Train_List_Export.csv');
    }
    
    /**
     * @param data array of orders
     */
    exportOrdersToCsv(data: OrderItem[]): void {
        // Columns, that are needed in the csv file (we don't take the whole object)
        const dataObjectHeader: any = {
            customerReference: this.translate.instant('Order-component.Table-header.Customer-reference'),
            orderNumber: this.translate.instant('Order-component.Table-header.Order-number'),
            orderStatus: this.translate.instant('Order-component.Table-header.Order-status'),            
            shipmentDate: this.translate.instant('Order-component.Table-header.Shipping-date'),
            sendingStation: this.translate.instant('Order-component.Table-header.Shipping-station'),
            receivingStation: this.translate.instant('Order-component.Table-header.Receiving-station'),
            nhmCodes: this.translate.instant('Order-component.Table-header.NHM-Code'),
            length: this.translate.instant('Order-component.Table-header.Length-in-m-csv'),
            weight: this.translate.instant('Order-component.Table-header.Amount-in-tons')
        };

        this.exportToCsv(data, dataObjectHeader, 'Order_List_Export.csv', ExportType.ORDERS_LIST);
    }

    exportOrderTemplatesToCsv(data: OrderTemplateSummary[]): void {
        const dataObjectHeader: any = {
            templateId: this.translate.instant('Order-templates-component.Template-nr'),
            trainType: this.translate.instant('Order-templates-component.Train-type'),
            sender: this.translate.instant('Order-templates-component.Sender'),
            sendingStation: this.translate.instant('Order-component.Table-header.Shipping-station'),
            receiver: this.translate.instant('Order-details.Receiver'),
            receivingStation: this.translate.instant('Order-component.Table-header.Receiving-station'),
            validFrom: this.translate.instant('Order-templates-component.Valid-from'),
            validTo: this.translate.instant('Order-templates-component.Valid-to')
        };
        
        this.exportToCsv(data, dataObjectHeader, 'Order_Templates_List_Export.csv');
    }

    exportToCsv(data: any[], dataObjectHeader: any, fileName: string, exportType: ExportType | null = null ) {
        const csv = data.map((row: any) => {
            return Object.keys(dataObjectHeader)
            // trainId: this.translate.instant('Order-view-page.Train-id-label'),
            // trainNumber: this.translate.instant('Order-view-page.Train-number-label'),
            // currentTrainNumber: this.translate.instant('Order-view-page.Current-train-number-label'),
            // productionDate: this.translate.instant('Order-view-page.Production-date-label'),
                .map((fieldName) => {
                    if(fieldName == 'trainId') {
                        const trainIdentifier: TrainIdentifier = row['trains'][0];
                        return trainIdentifier.trainId;
                    }
                    if(fieldName == 'trainNumber') {
                        const trainIdentifier: TrainIdentifier = row['trains'][0];
                        return trainIdentifier.trainNumber;
                    }
                    if(fieldName == 'currentTrainNumber') {
                        return row[fieldName];
                    }
                    if(fieldName == 'productionDate') {
                        const trainIdentifier: TrainIdentifier = row['trains'][0];
                        return trainIdentifier.productionDate;
                    }
                    if(fieldName == 'cancellationFee') {
                        if(row['orderStatus'] == 'CANCELED') {
                            return row[fieldName] ? this.translate.instant('yes') : this.translate.instant('no');
                        }
                        return '';
                    }
                    if(fieldName == "orderNumber") {
                        return row[fieldName] == null || row[fieldName] == 'null' ? '' : row[fieldName] + "\t";
                    }
                    if(fieldName == "nhmCodes") {
                        return row[fieldName] == null || row[fieldName] == 'null' ? '' : row[fieldName] + "\t";
                    }
                    if(fieldName == "plannedDeparture") {
                        return row[fieldName] == null || row[fieldName] == 'null' ? '' : formatDate(row[fieldName], 'yyyy-MM-dd H:mm', this.locale);
                    }
                    if(fieldName == "actualDeparture") {
                        return row[fieldName] == null || row[fieldName] == 'null' ? '' : formatDate(row[fieldName], 'yyyy-MM-dd H:mm', this.locale);
                    }
                    if(fieldName == "plannedArrival") {
                        return row[fieldName] == null || row[fieldName] == 'null' ? '' : formatDate(row[fieldName], 'yyyy-MM-dd H:mm', this.locale);
                    }
                    if(fieldName == "actualArrival") {
                        return row[fieldName] == null || row[fieldName] == 'null' ? '' : formatDate(row[fieldName], 'yyyy-MM-dd H:mm', this.locale);
                    }
                    if(fieldName == "operationalMode") {
                        return row[fieldName] == null || row[fieldName] == 'null' ? '' : this.translate.instant(row[fieldName]);
                    }
                    if(fieldName == "delayInMinutes" && !row['actualArrival']) {
                        return null
                    }
                    if (fieldName == 'cancelReasonName') {
                        return row[fieldName] == null || row[fieldName] == 'null' ? '' : row[fieldName] + "\t";
                    }
                    if(fieldName == "sendingStation" || fieldName == "receivingStation") {
                        return row[fieldName] == null || row[fieldName] == 'null' ? '' : row[fieldName].name;
                    } else if(Array.isArray(row[fieldName])) {
                        if(row[fieldName].length < 1) return '';

                        // if(typeof row[fieldName][0] == Object) {
                        //     const arr: any[] = [];
                        //     row[fieldName].forEach((el: any) => {
                        //         arr.push(JSON.stringify(el));
                        //     });
                        //     return arr.join(",");
                        // }
                        return row[fieldName].join(",");
                    } else if(fieldName == 'orderStatus') {
                        if(exportType == ExportType.ORDERS_LIST) {
                            return this.translate.instant(row[fieldName]);
                        }
                        return !row[fieldName] || row[fieldName] != 'CANCELED' ? this.translate.instant('ordered') : this.translate.instant('canceled');
                    }
                    let rowData = JSON.stringify(row[fieldName]);
                    return !rowData || rowData == 'null' ? '' : rowData;
                })
                .join(';');
        });
        csv.unshift(Object.keys(dataObjectHeader).map(key => dataObjectHeader[key]).join(';'));
        const csvArray = csv.join('\r\n');

        const blob: Blob = new Blob([csvArray], { type: 'text/csv' });
        const a = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
    }

    exportToFormattetXlsx(data: any[], dataObjectHeader: any, fileName: string) {
        
    }
}
