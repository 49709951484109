// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `section {
  margin: 15px 16px 16px;
  padding: 30px 18px 30px 30px;
  background-color: #fff;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}

.arrow {
  text-align: right;
  border-left: 4px solid transparent;
}
.arrow svg {
  transform: rotate(0);
  transition: all ease-in-out 0.3s;
  z-index: 1;
}
.arrow .up {
  fill: #0087b9;
  border-left: 4px solid #0087b9;
}
.arrow .up svg {
  transform: rotate(-90deg);
}

.arrow-up {
  fill: #0087b9;
  border-left: 4px solid #0087b9;
}
.arrow-up svg {
  transform: rotate(-90deg);
  transition: all ease-in-out 0.3s;
  z-index: 1;
}

.train-row-hide {
  display: none;
}

.train-row {
  display: table-row;
  animation: fade_in_show 0.5s;
}
.train-row td {
  background-color: #fff;
  padding-bottom: 0;
}

@keyframes fade_in_show {
  0% {
    opacity: 0;
    transform: all ease-in-out;
  }
  100% {
    opacity: 1;
    transform: all ease-in-out;
  }
}
.blue-border {
  background-color: white !important;
  border-left: 4px solid #0087b9;
  width: 100%;
}
.blue-border .subtable {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}
.blue-border .subtable .tile-head {
  width: 437px;
  font-size: 85%;
  background-color: white;
  text-align: center;
  padding-top: 8px;
  border: 1px solid whitesmoke;
}
.blue-border .subtable .tile-row {
  width: 40px;
  background-color: white;
  border: 1px solid whitesmoke;
  margin: 0;
  padding: 0;
}

table tr:nth-child(odd) td {
  background-color: #eef1f3;
}

table tr:nth-child(even) td {
  background-color: #eef1f3;
}

.tile {
  background-color: #DFEEFA;
  border-left: 2px solid #4471C4;
  text-align: center;
  padding-top: 4px;
  cursor: pointer;
}

.tile:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.tile:hover summary::before {
  background-color: #d7dce1;
}

.deactivated {
  border-left: 2px solid rgb(43, 43, 43);
  opacity: 0.4;
}

.title {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  margin: 0px;
  padding-left: 10px;
  background-color: #f0f3f5;
  color: #131821;
}

.items p {
  margin: 0px;
  padding: 10px;
}

.colspanTile {
  width: 100%;
  transition: all ease-in-out 0.3s;
}

.items p:hover {
  color: black;
  background: whitesmoke;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.table-header-top {
  position: sticky;
  top: 0%;
  z-index: 10;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
