import { Pipe, PipeTransform } from '@angular/core';
import { Customer } from 'src/app/models/ApiCustomers.model';
import { customerSgvNameFormat } from '../constants/Constants';

@Pipe({
  name: 'customerSgvName'
})
export class CustomerSgvNamePipe implements PipeTransform {

  transform(value: Customer|null|string, ...args: unknown[]): string|Customer {
    if(value == null) return '';

    if(typeof value == 'string') {
      const endSgv = value.indexOf(') ');
      const sgv = value.substring(1, endSgv);
      const name = value.substring(endSgv + 2);
      return {name: name, sgvNumber: sgv};
    }

    return customerSgvNameFormat.replace('$sgv', value.sgvNumber).replace('$name', value.name);
  }

}
