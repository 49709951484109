import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomerProfileSave, RoleName, TomGroup } from 'src/app/user-management/model/profile.model';
import { Role } from 'src/app/user-management/model/role.model';

@Component({
  selector: 'app-profile-list-update',
  templateUrl: './profile-list-update.component.html',
  styleUrls: ['./profile-list-update.component.scss']
})
export class ProfileListUpdateComponent {
  
  profiles: CustomerProfileSave[] = [];
  availableRoles: Role[];
  tomGroups: TomGroup[];

  RoleName = RoleName;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<ProfileListUpdateComponent>) {
    this.profiles = data.current.profiles; 
    this.availableRoles = data.availableRoles;
    this.tomGroups = data.tomGroups;
  }

  getGroupName(id: number | null | undefined): string {
    if (id === null || id === undefined) {
      console.error('Failed to find group name: Invalid group id' + id);
      return '';  
    }
    let group = this.tomGroups.find(group => { return group.id === id; });
    if (group) {
      return group.groupName;
    }
    console.error('Failed to find group name [id: ' + id + ']');
    return '';
  }

  hasRole(rolename: RoleName, profile: CustomerProfileSave): boolean {
    for (let role of profile.roles) {
      switch(rolename) {
        case RoleName.CUSTOMER: {
          const r = this.availableRoles.find(r => { return r.role === 'CUSTOMER';} );
          if (r) {
            if (role === r.id) {
              return true;
            }
          } else {
            console.error('Failed to find role: CUSTOMER');
          }
        } break;
        case RoleName.RECIPIENT: {
          const r = this.availableRoles.find(r => { return r.role === 'RECIPIENT';} );
          if (r) {
            if (role === r.id) {
              return true;
            }
          } else {
            console.error('Failed to find role: RECIPIENT');
          }
        } break;
        case RoleName.SENDER: {
          const r = this.availableRoles.find(r => { return r.role === 'SENDER';} );
          if (r) {
            if (role === r.id) {
              return true;
            }
          } else {
            console.error('Failed to find role: SENDER');
          }
        } 
      }      
    }
    return false;
  } 

  close(decision: boolean){
    this.dialogRef.close(decision);
  }  
}
