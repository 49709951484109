import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private authService: AuthService) { }

    canActivate() {
        // if user does not have an access token he has never been logged in, so...
        if (!this.authService.hasAccessToken()) {
            // ...try login
            return this.authService.tryLogin();
        }

        if(this.authService.isAccessTokenValid()) {
            return true;
        }

        // if access token is not valid
        return this.authService.onNotValidAccessToken();
    }
}