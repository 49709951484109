// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-header {
  background: #f0f3f5;
  margin: 0;
  height: 132px;
}
.modal-header span {
  font-weight: normal;
}
.modal-header h2 {
  margin-top: 16px;
}
.modal-header .close-icon {
  position: absolute;
  top: 35px;
  right: 35px;
}

.file-upload {
  display: inline-block;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: bold;
  min-height: 2.75rem;
  align-items: center;
  justify-content: center;
  background-color: #ec0016;
  color: #ffffff;
  border-radius: 4px;
  cursor: pointer;
}

.buttons-right {
  float: right;
}
.buttons-right .elm-button {
  margin-left: 10px;
}

.parting-line {
  height: 1px;
  border: solid 1px #c8cdd2;
  margin-top: 20px;
  margin-bottom: 6px;
}

.elm-textarea-custom {
  border-radius: 4px;
  color: #282d37;
  display: block;
  font-size: 1rem;
  min-height: 20.75rem;
  line-height: 1.5rem;
  padding: 0.375rem 0.625rem;
  resize: none;
  width: 100%;
  overflow: auto;
  margin: auto;
}
.elm-textarea-custom textarea {
  background-color: #ccc8d2;
}

.border {
  height: 1px;
  border: solid 1px #c8cdd2;
  margin-top: 30px;
  margin-bottom: 30px;
}

input[type=file] {
  display: none;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-right {
  display: flex;
  justify-content: flex-end;
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pointer {
  cursor: pointer;
}

::ng-deep .mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs > .mat-mdc-tab-header .mat-mdc-tab {
  flex-grow: 0;
}

::ng-deep .mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
  font-weight: bold;
  color: black;
}

::ng-deep .mat-mdc-tab.mdc-tab .mdc-tab__text-label {
  font-size: 115%;
}

::ng-deep .mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: white;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: grey;
  --mat-tab-header-active-ripple-color: white;
  --mat-tab-header-inactive-ripple-color: white;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: black;
  --mat-tab-header-active-hover-label-text-color: white;
  --mat-tab-header-active-focus-indicator-color: white;
  --mat-tab-header-active-hover-indicator-color: white;
}

::ng-deep .mat-tab-label-active {
  font-weight: bold;
}

::ng-deep .mat-mdc-tab-header {
  border: 1px solid white;
}

::ng-deep .mdc-tab-indicator {
  background-color: white;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
