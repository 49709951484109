import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderNumber'
})
export class OrderNumberPipe implements PipeTransform {

  transform(orderNumber: number, orderAuthority?: number): string {
    if (!orderNumber) {
      return null;
    }

    let formattedNumber = orderNumber.toString().replace(/[^0-9]/g, '');
    let maxLength = 15;

    if (orderAuthority != null) {
      formattedNumber = `${orderAuthority}${formattedNumber}`;
      maxLength = 17;
    }

    formattedNumber = formattedNumber.slice(0, maxLength);

    const formatPattern = orderAuthority != null ? /^(\d{2})(\d{8})(\d{3})(\d{4})$/ : /^(\d{8})(\d{3})(\d{4})$/;
    const formatReplacement = orderAuthority != null ? '$1 $2 $3 $4' : '$1 $2 $3';

    return formattedNumber.replace(formatPattern, formatReplacement);
  }
}