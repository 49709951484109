import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiError, ApiProblem } from 'src/app/models/ApiModels';
import { ErrorData } from './ErrorData';

@Component({
  selector: 'app-api-error',
  templateUrl: './api-error.component.html',
  styleUrls: ['./api-error.component.scss']
})
export class ErrorDialogComponent {
  errorText?: string;
  apiErrors?: ApiError[];
  apiProblem?: ApiProblem;
  instance?: String;
  text?: String;

  constructor(private dialogRef: MatDialogRef<ErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: ErrorData) {
    this.errorText = data.text?.split(" | ").join("\n");
    this.apiProblem = data.apiProblem;
    this.apiErrors = data.apiProblem?.errors;
    this.instance = data.apiProblem?.instance.substring(9,500);
    this.text = data.text;
  }

}
