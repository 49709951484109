import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrderDetails, OrderItem } from 'src/app/models/ApiOrders.model';

@Component({
  selector: 'app-order-details-modal',
  templateUrl: './order-details-modal.component.html',
  styleUrls: ['./order-details-modal.component.scss']
})
export class OrderDetailsModalComponent {

  protected orderDetails: OrderDetails;
  protected orderNumber: string;

  constructor(@Inject(MAT_DIALOG_DATA) private param: { orderDetails: OrderDetails, orderNumber: string }) {
    this.orderDetails = param.orderDetails;
    this.orderNumber = param.orderNumber;      
  }
}
