import { NgModule } from '@angular/core';
import { DBUIElementsModule } from '@db-ui/ngx-elements-enterprise/dist/lib';
import { ConfirmationComponent } from './confirmation.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpLoaderFactory } from '../shared.module';
import { HttpClient } from '@angular/common/http';

@NgModule({
  declarations: [
    ConfirmationComponent
  ],
  imports: [
    DBUIElementsModule,
    TranslateModule.forChild({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
      },
      isolate: false,
  }), 
  ],
  exports: [
    ConfirmationComponent
  ]  
})
export class ConfirmationModule {

}