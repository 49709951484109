// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
.cdk-global-overlay-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 999;
}

.icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.mouse-pointer {
  cursor: pointer;
}

.action-icon {
  cursor: pointer;
}

.additional-buttons-block button {
  font-weight: normal;
  margin-left: 10px;
  margin-right: 10px;
}
.additional-buttons-block button:last-of-type {
  margin-right: unset;
}

.block-scroll {
  display: block;
  position: fixed;
}

/* Document
 * ========================================================================== */
/**
 * 1. Correct the line height in all browsers.
 */
:where(html) {
  line-height: 1.15; /* 1 */
}

/* Sections
 * ========================================================================== */
/**
 * Correct the font size and margin on \`h1\` elements within \`section\` and
 * \`article\` contexts in Chrome, Edge, Firefox, and Safari.
 */
:where(h1) {
  font-size: 2em;
  margin-block-end: 0.67em;
  margin-block-start: 0.67em;
}

/* Grouping content
 * ========================================================================== */
/**
 * Remove the margin on nested lists in Chrome, Edge, and Safari.
 */
:where(dl, ol, ul) :where(dl, ol, ul) {
  margin-block-end: 0;
  margin-block-start: 0;
}

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Correct the inheritance of border color in Firefox.
 */
:where(hr) {
  box-sizing: content-box; /* 1 */
  color: inherit; /* 2 */
  height: 0; /* 1 */
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd \`em\` font sizing in all browsers.
 */
:where(pre) {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/* Text-level semantics
 * ========================================================================== */
/**
 * Add the correct text decoration in Safari.
 */
:where(abbr[title]) {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
:where(b, strong) {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd \`em\` font sizing in all browsers.
 */
:where(code, kbd, samp) {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/**
 * Add the correct font size in all browsers.
 */
:where(small) {
  font-size: 80%;
}

/* Tabular data
 * ========================================================================== */
/**
 * 1. Correct table border color in Chrome, Edge, and Safari.
 * 2. Remove text indentation from table contents in Chrome, Edge, and Safari.
 */
:where(table) {
  border-color: currentColor; /* 1 */
  text-indent: 0; /* 2 */
}

/* Forms
 * ========================================================================== */
/**
 * Remove the margin on controls in Safari.
 */
:where(button, input, select) {
  margin: 0;
}

/**
 * Remove the inheritance of text transform in Firefox.
 */
:where(button) {
  text-transform: none;
}

/**
 * Correct the inability to style buttons in iOS and Safari.
 */
:where(button, input:is([type=button i], [type=reset i], [type=submit i])) {
  -webkit-appearance: button;
}

/**
 * Add the correct vertical alignment in Chrome, Edge, and Firefox.
 */
:where(progress) {
  vertical-align: baseline;
}

/**
 * Remove the inheritance of text transform in Firefox.
 */
:where(select) {
  text-transform: none;
}

/**
 * Remove the margin in Firefox and Safari.
 */
:where(textarea) {
  margin: 0;
}

/**
 * 1. Correct the odd appearance in Chrome, Edge, and Safari.
 * 2. Correct the outline style in Safari.
 */
:where(input[type=search i]) {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}

/**
 * Remove the inner padding in Chrome, Edge, and Safari on macOS.
 */
::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style upload buttons in iOS and Safari.
 * 2. Change font properties to \`inherit\` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */
:where(button, input:is([type=button i], [type=color i], [type=reset i], [type=submit i]))::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */
:where(button, input:is([type=button i], [type=color i], [type=reset i], [type=submit i]))::-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Remove the additional :invalid styles in Firefox.
 */
:where(:-moz-ui-invalid) {
  box-shadow: none;
}

/* Interactive
 * ========================================================================== */
/*
 * Add the correct styles in Safari.
 */
:where(dialog) {
  background-color: white;
  border: solid;
  color: black;
  height: -moz-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: fit-content;
}

:where(dialog:not([open])) {
  display: none;
}

/*
 * Add the correct display in all browsers.
 */
:where(summary) {
  display: list-item;
}

html,
body {
  margin: 0;
  padding: 0;
}

body {
  min-height: 100vh;
}

html {
  background-color: #fdfdfd;
  font-size: 16px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  min-width: 300px;
  overflow-x: hidden;
  overflow-y: scroll;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

body,
button,
input,
optgroup,
select,
textarea {
  font-family: "DB Screen Sans", Helvetica, Arial, sans-serif;
}

code,
pre {
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: auto;
}

body {
  color: #282d37;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

a {
  color: #282d37;
}
a strong {
  color: currentColor;
}
a:hover {
  color: #5d616a;
}

code {
  background-color: #f5f5f5;
  color: #e20015;
  font-size: 0.875em;
  font-weight: normal;
  padding: 0.25em 0.5em 0.25em;
}

hr {
  background-color: #f5f5f5;
  border: none;
  display: block;
  height: 2px;
  margin: 1.5rem 0;
}

img {
  height: auto;
  max-width: 100%;
}

small {
  font-size: 0.875em;
}

fieldset {
  border: none;
}

pre {
  -webkit-overflow-scrolling: touch;
  background-color: #f5f5f5;
  color: #282d37;
  font-size: 0.875em;
  overflow-x: auto;
  padding: 1.25rem 1.5rem;
  white-space: pre;
  word-wrap: normal;
}
pre code {
  background-color: transparent;
  color: currentColor;
  font-size: 1em;
  padding: 0;
}

html {
  box-sizing: border-box;
}

*,
::before,
::after {
  box-sizing: inherit;
}

img,
embed,
iframe,
object,
video {
  height: auto;
  max-width: 100%;
}

iframe {
  border: 0;
}

nav ol,
nav ul,
[role=navigation] ol,
[role=navigation] ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

:root {
  --db-focus-outline-offset: 1px;
  --db-focus-outline-color: #2c95c4;
}

:is(a, button, input, textarea, summary) {
  --db-focus-outline-size: max(2px, 0.08em);
}
:is(a, button, input, textarea, summary):focus-visible {
  outline: var(--db-focus-outline-size) var(--db-focus-outline-style, solid) var(--db-focus-outline-color, currentColor);
  outline-offset: var(--db-focus-outline-offset, var(--db-focus-outline-size));
}

.cmp-tab-bar > input[type=radio], .cmp-tab-bar > * > input[type=radio], .cmp-pagination .elm-headline, .cmp-notification > em, .cmp-mainnavigation > input[type=checkbox][id] {
  clip: rect(0, 0, 0, 0);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}

.rea-footer::after, .rea-main::after, .rea-header::after {
  clear: both;
  content: " ";
  display: table;
}

@font-face {
  font-family: "DB Screen Head";
  font-style: normal;
  font-weight: 300;
  src: local("DB Screen Head Light"), url('dbscreenhead-light.f43da5c107deb3fa.woff2?4r0080') format("woff2"), url('dbscreenhead-light.1a7adc7dde73b017.woff?4r0080') format("woff");
}
@font-face {
  font-family: "DB Screen Head";
  font-style: normal;
  font-weight: 700;
  src: local("DB Screen Head Black"), url('dbscreenhead-black.8003423d7b7d3b4c.woff2?4r0080') format("woff2"), url('dbscreenhead-black.f5016903774a6513.woff?4r0080') format("woff");
}
@font-face {
  font-family: "DB Screen Sans";
  font-style: normal;
  font-weight: 400;
  src: local("DB Screen Sans Regular"), url('dbscreensans-regular.6ef8863722b88772.woff2?4r0080') format("woff2"), url('dbscreensans-regular.b183e825ff917eb3.woff?4r0080') format("woff");
}
@font-face {
  font-family: "DB Screen Sans";
  font-style: normal;
  font-weight: 700;
  src: url('dbscreensans-bold.97637e7159f07350.woff2?4r0080') format("woff2"), url('dbscreensans-bold.7626b4444e55c152.woff?4r0080') format("woff");
}
[data-icon=account]::before,
[data-icon-before=account]::before {
  --icon-glyph: "🙂";
}

[data-icon-after=account]::after {
  --icon-glyph: "🙂";
}

[data-icon=add]::before,
[data-icon-before=add]::before {
  --icon-glyph: "+";
}

[data-icon-after=add]::after {
  --icon-glyph: "+";
}

[data-icon=add-circle]::before,
[data-icon-before=add-circle]::before {
  --icon-glyph: "⊕";
}

[data-icon-after=add-circle]::after {
  --icon-glyph: "⊕";
}

[data-icon=air-condition]::before,
[data-icon-before=air-condition]::before {
  --icon-glyph: "❄";
}

[data-icon-after=air-condition]::after {
  --icon-glyph: "❄";
}

[data-icon=alarm]::before,
[data-icon-before=alarm]::before {
  --icon-glyph: "⏰";
}

[data-icon-after=alarm]::after {
  --icon-glyph: "⏰";
}

[data-icon=alarm-add]::before,
[data-icon-before=alarm-add]::before {
  --icon-glyph: "󡁢";
}

[data-icon-after=alarm-add]::after {
  --icon-glyph: "󡁢";
}

[data-icon=alert]::before,
[data-icon-before=alert]::before {
  --icon-glyph: "🔔";
}

[data-icon-after=alert]::after {
  --icon-glyph: "🔔";
}

[data-icon=alert-off]::before,
[data-icon-before=alert-off]::before {
  --icon-glyph: "🔕";
}

[data-icon-after=alert-off]::after {
  --icon-glyph: "🔕";
}

[data-icon=calendar]::before,
[data-icon-before=calendar]::before {
  --icon-glyph: "📆";
}

[data-icon-after=calendar]::after {
  --icon-glyph: "📆";
}

[data-icon=check-circle]::before,
[data-icon-before=check-circle]::before {
  --icon-glyph: "✅";
}

[data-icon-after=check-circle]::after {
  --icon-glyph: "✅";
}

[data-icon=credit-card]::before,
[data-icon-before=credit-card]::before {
  --icon-glyph: "💳";
}

[data-icon-after=credit-card]::after {
  --icon-glyph: "💳";
}

[data-icon=delete]::before,
[data-icon-before=delete]::before {
  --icon-glyph: "🗑";
}

[data-icon-after=delete]::after {
  --icon-glyph: "🗑";
}

[data-icon=discount]::before,
[data-icon-before=discount]::before {
  --icon-glyph: "󡁦";
}

[data-icon-after=discount]::after {
  --icon-glyph: "󡁦";
}

[data-icon=document]::before,
[data-icon-before=document]::before {
  --icon-glyph: "🖹";
}

[data-icon-after=document]::after {
  --icon-glyph: "🖹";
}

[data-icon=done]::before,
[data-icon-before=done]::before {
  --icon-glyph: "✓";
}

[data-icon-after=done]::after {
  --icon-glyph: "✓";
}

[data-icon=download]::before,
[data-icon-before=download]::before {
  --icon-glyph: "⭳";
}

[data-icon-after=download]::after {
  --icon-glyph: "⭳";
}

[data-icon=edit]::before,
[data-icon-before=edit]::before {
  --icon-glyph: "✏";
}

[data-icon-after=edit]::after {
  --icon-glyph: "✏";
}

[data-icon=filter]::before,
[data-icon-before=filter]::before {
  --icon-glyph: "󡁩";
}

[data-icon-after=filter]::after {
  --icon-glyph: "󡁩";
}

[data-icon=generic-card]::before,
[data-icon-before=generic-card]::before {
  --icon-glyph: "󡁰";
}

[data-icon-after=generic-card]::after {
  --icon-glyph: "󡁰";
}

[data-icon=giftcard]::before,
[data-icon-before=giftcard]::before {
  --icon-glyph: "🎁";
}

[data-icon-after=giftcard]::after {
  --icon-glyph: "🎁";
}

[data-icon=heart]::before,
[data-icon-before=heart]::before {
  --icon-glyph: "♡";
}

[data-icon-after=heart]::after {
  --icon-glyph: "♡";
}

[data-icon=help]::before,
[data-icon-before=help]::before {
  --icon-glyph: "?";
}

[data-icon-after=help]::after {
  --icon-glyph: "?";
}

[data-icon=info]::before,
[data-icon-before=info]::before {
  --icon-glyph: "🛈";
}

[data-icon-after=info]::after {
  --icon-glyph: "🛈";
}

[data-icon=legal]::before,
[data-icon-before=legal]::before {
  --icon-glyph: "§";
}

[data-icon-after=legal]::after {
  --icon-glyph: "§";
}

[data-icon=log-out]::before,
[data-icon-before=log-out]::before {
  --icon-glyph: "↦";
}

[data-icon-after=log-out]::after {
  --icon-glyph: "↦";
}

[data-icon=minus]::before,
[data-icon-before=minus]::before {
  --icon-glyph: "−";
}

[data-icon-after=minus]::after {
  --icon-glyph: "−";
}

[data-icon=print]::before,
[data-icon-before=print]::before {
  --icon-glyph: "🖨";
}

[data-icon-after=print]::after {
  --icon-glyph: "🖨";
}

[data-icon=remove]::before,
[data-icon-before=remove]::before {
  --icon-glyph: "⊝";
}

[data-icon-after=remove]::after {
  --icon-glyph: "⊝";
}

[data-icon=schedule]::before,
[data-icon-before=schedule]::before {
  --icon-glyph: "🕓";
}

[data-icon-after=schedule]::after {
  --icon-glyph: "🕓";
}

[data-icon=search]::before,
[data-icon-before=search]::before {
  --icon-glyph: "🔍";
}

[data-icon-after=search]::after {
  --icon-glyph: "🔍";
}

[data-icon=send]::before,
[data-icon-before=send]::before {
  --icon-glyph: "◹";
}

[data-icon-after=send]::after {
  --icon-glyph: "◹";
}

[data-icon=settings]::before,
[data-icon-before=settings]::before {
  --icon-glyph: "⚙";
}

[data-icon-after=settings]::after {
  --icon-glyph: "⚙";
}

[data-icon=share]::before,
[data-icon-before=share]::before {
  --icon-glyph: "󡁷";
}

[data-icon-after=share]::after {
  --icon-glyph: "󡁷";
}

[data-icon=shopping-bag]::before,
[data-icon-before=shopping-bag]::before {
  --icon-glyph: "🛍";
}

[data-icon-after=shopping-bag]::after {
  --icon-glyph: "🛍";
}

[data-icon=shopping-basket]::before,
[data-icon-before=shopping-basket]::before {
  --icon-glyph: "󡁹";
}

[data-icon-after=shopping-basket]::after {
  --icon-glyph: "󡁹";
}

[data-icon=shopping-cart]::before,
[data-icon-before=shopping-cart]::before {
  --icon-glyph: "🛒";
}

[data-icon-after=shopping-cart]::after {
  --icon-glyph: "🛒";
}

[data-icon=star]::before,
[data-icon-before=star]::before {
  --icon-glyph: "☆";
}

[data-icon-after=star]::after {
  --icon-glyph: "☆";
}

[data-icon=swap-horizontal]::before,
[data-icon-before=swap-horizontal]::before {
  --icon-glyph: "⇆";
}

[data-icon-after=swap-horizontal]::after {
  --icon-glyph: "⇆";
}

[data-icon=swap-vertical]::before,
[data-icon-before=swap-vertical]::before {
  --icon-glyph: "⇵";
}

[data-icon-after=swap-vertical]::after {
  --icon-glyph: "⇵";
}

[data-icon=upload]::before,
[data-icon-before=upload]::before {
  --icon-glyph: "󡂁";
}

[data-icon-after=upload]::after {
  --icon-glyph: "󡂁";
}

[data-icon=visibility]::before,
[data-icon-before=visibility]::before {
  --icon-glyph: "👁";
}

[data-icon-after=visibility]::after {
  --icon-glyph: "👁";
}

[data-icon=visibility-off]::before,
[data-icon-before=visibility-off]::before {
  --icon-glyph: "󡂂";
}

[data-icon-after=visibility-off]::after {
  --icon-glyph: "󡂂";
}

[data-icon=website]::before,
[data-icon-before=website]::before {
  --icon-glyph: "🌐";
}

[data-icon-after=website]::after {
  --icon-glyph: "🌐";
}

[data-icon=zoom-in]::before,
[data-icon-before=zoom-in]::before {
  --icon-glyph: "󡂃";
}

[data-icon-after=zoom-in]::after {
  --icon-glyph: "󡂃";
}

[data-icon=zoom-out]::before,
[data-icon-before=zoom-out]::before {
  --icon-glyph: "󡂄";
}

[data-icon-after=zoom-out]::after {
  --icon-glyph: "󡂄";
}

[data-icon=fast-backward-10]::before,
[data-icon-before=fast-backward-10]::before {
  --icon-glyph: "󡂅";
}

[data-icon-after=fast-backward-10]::after {
  --icon-glyph: "󡂅";
}

[data-icon=fast-backward-30]::before,
[data-icon-before=fast-backward-30]::before {
  --icon-glyph: "󡂆";
}

[data-icon-after=fast-backward-30]::after {
  --icon-glyph: "󡂆";
}

[data-icon=fast-backward-empty]::before,
[data-icon-before=fast-backward-empty]::before {
  --icon-glyph: "⤺";
}

[data-icon-after=fast-backward-empty]::after {
  --icon-glyph: "⤺";
}

[data-icon=fast-forward-10]::before,
[data-icon-before=fast-forward-10]::before {
  --icon-glyph: "󡂇";
}

[data-icon-after=fast-forward-10]::after {
  --icon-glyph: "󡂇";
}

[data-icon=fast-forward-30]::before,
[data-icon-before=fast-forward-30]::before {
  --icon-glyph: "󡂈";
}

[data-icon-after=fast-forward-30]::after {
  --icon-glyph: "󡂈";
}

[data-icon=fast-forward-empty]::before,
[data-icon-before=fast-forward-empty]::before {
  --icon-glyph: "⤻";
}

[data-icon-after=fast-forward-empty]::after {
  --icon-glyph: "⤻";
}

[data-icon=pause]::before,
[data-icon-before=pause]::before {
  --icon-glyph: "⏸";
}

[data-icon-after=pause]::after {
  --icon-glyph: "⏸";
}

[data-icon=play]::before,
[data-icon-before=play]::before {
  --icon-glyph: "▷";
}

[data-icon-after=play]::after {
  --icon-glyph: "▷";
}

[data-icon=skip-backward]::before,
[data-icon-before=skip-backward]::before {
  --icon-glyph: "⏮";
}

[data-icon-after=skip-backward]::after {
  --icon-glyph: "⏮";
}

[data-icon=skip-forward]::before,
[data-icon-before=skip-forward]::before {
  --icon-glyph: "⏭";
}

[data-icon-after=skip-forward]::after {
  --icon-glyph: "⏭";
}

[data-icon=stop]::before,
[data-icon-before=stop]::before {
  --icon-glyph: "□";
}

[data-icon-after=stop]::after {
  --icon-glyph: "□";
}

[data-icon=subtitles]::before,
[data-icon-before=subtitles]::before {
  --icon-glyph: "󡂑";
}

[data-icon-after=subtitles]::after {
  --icon-glyph: "󡂑";
}

[data-icon=volume-down]::before,
[data-icon-before=volume-down]::before {
  --icon-glyph: "🔉";
}

[data-icon-after=volume-down]::after {
  --icon-glyph: "🔉";
}

[data-icon=volume-mute]::before,
[data-icon-before=volume-mute]::before {
  --icon-glyph: "🔈";
}

[data-icon-after=volume-mute]::after {
  --icon-glyph: "🔈";
}

[data-icon=volume-off]::before,
[data-icon-before=volume-off]::before {
  --icon-glyph: "🔇";
}

[data-icon-after=volume-off]::after {
  --icon-glyph: "🔇";
}

[data-icon=volume-up]::before,
[data-icon-before=volume-up]::before {
  --icon-glyph: "🔊";
}

[data-icon-after=volume-up]::after {
  --icon-glyph: "🔊";
}

[data-icon=call]::before,
[data-icon-before=call]::before {
  --icon-glyph: "📞";
}

[data-icon-after=call]::after {
  --icon-glyph: "📞";
}

[data-icon=chat]::before,
[data-icon-before=chat]::before {
  --icon-glyph: "💬";
}

[data-icon-after=chat]::after {
  --icon-glyph: "💬";
}

[data-icon=conversation]::before,
[data-icon-before=conversation]::before {
  --icon-glyph: "󡂕";
}

[data-icon-after=conversation]::after {
  --icon-glyph: "󡂕";
}

[data-icon=fax]::before,
[data-icon-before=fax]::before {
  --icon-glyph: "🖷";
}

[data-icon-after=fax]::after {
  --icon-glyph: "🖷";
}

[data-icon=feedback]::before,
[data-icon-before=feedback]::before {
  --icon-glyph: "⍰";
}

[data-icon-after=feedback]::after {
  --icon-glyph: "⍰";
}

[data-icon=mail]::before,
[data-icon-before=mail]::before {
  --icon-glyph: "✉";
}

[data-icon-after=mail]::after {
  --icon-glyph: "✉";
}

[data-icon=mobile-off]::before,
[data-icon-before=mobile-off]::before {
  --icon-glyph: "📴";
}

[data-icon-after=mobile-off]::after {
  --icon-glyph: "📴";
}

[data-icon=mobile-phone]::before,
[data-icon-before=mobile-phone]::before {
  --icon-glyph: "📱";
}

[data-icon-after=mobile-phone]::after {
  --icon-glyph: "📱";
}

[data-icon=wifi]::before,
[data-icon-before=wifi]::before {
  --icon-glyph: "󡂘";
}

[data-icon-after=wifi]::after {
  --icon-glyph: "󡂘";
}

[data-icon=wifi-off]::before,
[data-icon-before=wifi-off]::before {
  --icon-glyph: "󡅉";
}

[data-icon-after=wifi-off]::after {
  --icon-glyph: "󡅉";
}

[data-icon=mask]::before,
[data-icon-before=mask]::before {
  --icon-glyph: "😷";
}

[data-icon-after=mask]::after {
  --icon-glyph: "😷";
}

[data-icon=wc]::before,
[data-icon-before=wc]::before {
  --icon-glyph: "🚻";
}

[data-icon-after=wc]::after {
  --icon-glyph: "🚻";
}

[data-icon=wc-men]::before,
[data-icon-before=wc-men]::before {
  --icon-glyph: "🚹";
}

[data-icon-after=wc-men]::after {
  --icon-glyph: "🚹";
}

[data-icon=wc-sign]::before,
[data-icon-before=wc-sign]::before {
  --icon-glyph: "🚾";
}

[data-icon-after=wc-sign]::after {
  --icon-glyph: "🚾";
}

[data-icon=wc-woman]::before,
[data-icon-before=wc-woman]::before {
  --icon-glyph: "🚺";
}

[data-icon-after=wc-woman]::after {
  --icon-glyph: "🚺";
}

[data-icon=entry-aid]::before,
[data-icon-before=entry-aid]::before {
  --icon-glyph: "󡅐";
}

[data-icon-after=entry-aid]::after {
  --icon-glyph: "󡅐";
}

[data-icon=luggage-rack]::before,
[data-icon-before=luggage-rack]::before {
  --icon-glyph: "🧳";
}

[data-icon-after=luggage-rack]::after {
  --icon-glyph: "🧳";
}

[data-icon=medical]::before,
[data-icon-before=medical]::before {
  --icon-glyph: "✚";
}

[data-icon-after=medical]::after {
  --icon-glyph: "✚";
}

[data-icon=power-outlet]::before,
[data-icon-before=power-outlet]::before {
  --icon-glyph: "🔌";
}

[data-icon-after=power-outlet]::after {
  --icon-glyph: "🔌";
}

[data-icon=coffee-cup]::before,
[data-icon-before=coffee-cup]::before {
  --icon-glyph: "☕";
}

[data-icon-after=coffee-cup]::after {
  --icon-glyph: "☕";
}

[data-icon=drink]::before,
[data-icon-before=drink]::before {
  --icon-glyph: "󡄁";
}

[data-icon-after=drink]::after {
  --icon-glyph: "󡄁";
}

[data-icon=restaurant]::before,
[data-icon-before=restaurant]::before {
  --icon-glyph: "🍽";
}

[data-icon-after=restaurant]::after {
  --icon-glyph: "🍽";
}

[data-icon="1st-class"]::before,
[data-icon-before="1st-class"]::before {
  --icon-glyph: "⒈";
}

[data-icon-after="1st-class"]::after {
  --icon-glyph: "⒈";
}

[data-icon="2nd-class"]::before,
[data-icon-before="2nd-class"]::before {
  --icon-glyph: "⒉";
}

[data-icon-after="2nd-class"]::after {
  --icon-glyph: "⒉";
}

[data-icon=alternative-connection]::before,
[data-icon-before=alternative-connection]::before {
  --icon-glyph: "󡄂";
}

[data-icon-after=alternative-connection]::after {
  --icon-glyph: "󡄂";
}

[data-icon=booking]::before,
[data-icon-before=booking]::before {
  --icon-glyph: "󡄃";
}

[data-icon-after=booking]::after {
  --icon-glyph: "󡄃";
}

[data-icon=capacity-indicator]::before,
[data-icon-before=capacity-indicator]::before {
  --icon-glyph: "󡄄";
}

[data-icon-after=capacity-indicator]::after {
  --icon-glyph: "󡄄";
}

[data-icon=capacity-indicator-fully-booked]::before,
[data-icon-before=capacity-indicator-fully-booked]::before {
  --icon-glyph: "󡄆";
}

[data-icon-after=capacity-indicator-fully-booked]::after {
  --icon-glyph: "󡄆";
}

[data-icon=capacity-indicator-high]::before,
[data-icon-before=capacity-indicator-high]::before {
  --icon-glyph: "󡄈";
}

[data-icon-after=capacity-indicator-high]::after {
  --icon-glyph: "󡄈";
}

[data-icon=capacity-indicator-low]::before,
[data-icon-before=capacity-indicator-low]::before {
  --icon-glyph: "󡄐";
}

[data-icon-after=capacity-indicator-low]::after {
  --icon-glyph: "󡄐";
}

[data-icon=destination]::before,
[data-icon-before=destination]::before {
  --icon-glyph: "📍";
}

[data-icon-after=destination]::after {
  --icon-glyph: "📍";
}

[data-icon=intermediary-stop]::before,
[data-icon-before=intermediary-stop]::before {
  --icon-glyph: "󡄓";
}

[data-icon-after=intermediary-stop]::after {
  --icon-glyph: "󡄓";
}

[data-icon=round-trip]::before,
[data-icon-before=round-trip]::before {
  --icon-glyph: "⮔";
}

[data-icon-after=round-trip]::after {
  --icon-glyph: "⮔";
}

[data-icon=single-trip]::before,
[data-icon-before=single-trip]::before {
  --icon-glyph: "⤞";
}

[data-icon-after=single-trip]::after {
  --icon-glyph: "⤞";
}

[data-icon=start]::before,
[data-icon-before=start]::before {
  --icon-glyph: "⦿";
}

[data-icon-after=start]::after {
  --icon-glyph: "⦿";
}

[data-icon=home]::before,
[data-icon-before=home]::before {
  --icon-glyph: "⌂";
}

[data-icon-after=home]::after {
  --icon-glyph: "⌂";
}

[data-icon=map]::before,
[data-icon-before=map]::before {
  --icon-glyph: "🗺";
}

[data-icon-after=map]::after {
  --icon-glyph: "🗺";
}

[data-icon=map-stop]::before,
[data-icon-before=map-stop]::before {
  --icon-glyph: "󡄕";
}

[data-icon-after=map-stop]::after {
  --icon-glyph: "󡄕";
}

[data-icon=place]::before,
[data-icon-before=place]::before {
  --icon-glyph: "󡄖";
}

[data-icon-after=place]::after {
  --icon-glyph: "󡄖";
}

[data-icon=set-position]::before,
[data-icon-before=set-position]::before {
  --icon-glyph: "🞋";
}

[data-icon-after=set-position]::after {
  --icon-glyph: "🞋";
}

[data-icon=trainstation]::before,
[data-icon-before=trainstation]::before {
  --icon-glyph: "🚉";
}

[data-icon-after=trainstation]::after {
  --icon-glyph: "🚉";
}

[data-icon=arrow-back]::before,
[data-icon-before=arrow-back]::before {
  --icon-glyph: "←";
}

[data-icon-after=arrow-back]::after {
  --icon-glyph: "←";
}

[data-icon=arrow-down]::before,
[data-icon-before=arrow-down]::before {
  --icon-glyph: "↓";
}

[data-icon-after=arrow-down]::after {
  --icon-glyph: "↓";
}

[data-icon=arrow-forward]::before,
[data-icon-before=arrow-forward]::before {
  --icon-glyph: "→";
}

[data-icon-after=arrow-forward]::after {
  --icon-glyph: "→";
}

[data-icon=arrow-up]::before,
[data-icon-before=arrow-up]::before {
  --icon-glyph: "↑";
}

[data-icon-after=arrow-up]::after {
  --icon-glyph: "↑";
}

[data-icon=cancel]::before,
[data-icon-before=cancel]::before {
  --icon-glyph: "⮾";
}

[data-icon-after=cancel]::after {
  --icon-glyph: "⮾";
}

[data-icon=chevron-left]::before,
[data-icon-before=chevron-left]::before {
  --icon-glyph: "<";
}

[data-icon-after=chevron-left]::after {
  --icon-glyph: "<";
}

[data-icon=chevron-right]::before,
[data-icon-before=chevron-right]::before {
  --icon-glyph: ">";
}

[data-icon-after=chevron-right]::after {
  --icon-glyph: ">";
}

[data-icon=close]::before,
[data-icon-before=close]::before {
  --icon-glyph: "⨯";
}

[data-icon-after=close]::after {
  --icon-glyph: "⨯";
}

[data-icon=expand-less]::before,
[data-icon-before=expand-less]::before {
  --icon-glyph: "⌃";
}

[data-icon-after=expand-less]::after {
  --icon-glyph: "⌃";
}

[data-icon=expand-more]::before,
[data-icon-before=expand-more]::before {
  --icon-glyph: "⌄";
}

[data-icon-after=expand-more]::after {
  --icon-glyph: "⌄";
}

[data-icon=fullscreen]::before,
[data-icon-before=fullscreen]::before {
  --icon-glyph: "󡄙";
}

[data-icon-after=fullscreen]::after {
  --icon-glyph: "󡄙";
}

[data-icon=fullscreen-exit]::before,
[data-icon-before=fullscreen-exit]::before {
  --icon-glyph: "󡄠";
}

[data-icon-after=fullscreen-exit]::after {
  --icon-glyph: "󡄠";
}

[data-icon=grid-view]::before,
[data-icon-before=grid-view]::before {
  --icon-glyph: "𐌎";
}

[data-icon-after=grid-view]::after {
  --icon-glyph: "𐌎";
}

[data-icon=link]::before,
[data-icon-before=link]::before {
  --icon-glyph: "🡢";
}

[data-icon-after=link]::after {
  --icon-glyph: "🡢";
}

[data-icon=link-external]::before,
[data-icon-before=link-external]::before {
  --icon-glyph: "🡥";
}

[data-icon-after=link-external]::after {
  --icon-glyph: "🡥";
}

[data-icon=list]::before,
[data-icon-before=list]::before {
  --icon-glyph: "󡄡";
}

[data-icon-after=list]::after {
  --icon-glyph: "󡄡";
}

[data-icon=menu]::before,
[data-icon-before=menu]::before {
  --icon-glyph: "󡄢";
}

[data-icon-after=menu]::after {
  --icon-glyph: "󡄢";
}

[data-icon=more-horizontal]::before,
[data-icon-before=more-horizontal]::before {
  --icon-glyph: "⋯";
}

[data-icon-after=more-horizontal]::after {
  --icon-glyph: "⋯";
}

[data-icon=more-vertical]::before,
[data-icon-before=more-vertical]::before {
  --icon-glyph: "⋮";
}

[data-icon-after=more-vertical]::after {
  --icon-glyph: "⋮";
}

[data-icon=refresh]::before,
[data-icon-before=refresh]::before {
  --icon-glyph: "🗘";
}

[data-icon-after=refresh]::after {
  --icon-glyph: "🗘";
}

[data-icon=error]::before,
[data-icon-before=error]::before {
  --icon-glyph: "󡄣";
}

[data-icon-after=error]::after {
  --icon-glyph: "󡄣";
}

[data-icon=error-triangle]::before,
[data-icon-before=error-triangle]::before {
  --icon-glyph: "⚠";
}

[data-icon-after=error-triangle]::after {
  --icon-glyph: "⚠";
}

[data-icon=notify]::before,
[data-icon-before=notify]::before {
  --icon-glyph: "󡄦";
}

[data-icon-after=notify]::after {
  --icon-glyph: "󡄦";
}

[data-icon=notify-cutoff]::before,
[data-icon-before=notify-cutoff]::before {
  --icon-glyph: "󡄧";
}

[data-icon-after=notify-cutoff]::after {
  --icon-glyph: "󡄧";
}

[data-icon=aisle]::before,
[data-icon-before=aisle]::before {
  --icon-glyph: "💺";
}

[data-icon-after=aisle]::after {
  --icon-glyph: "💺";
}

[data-icon=elderly]::before,
[data-icon-before=elderly]::before {
  --icon-glyph: "󡄨";
}

[data-icon-after=elderly]::after {
  --icon-glyph: "󡄨";
}

[data-icon=handicapped]::before,
[data-icon-before=handicapped]::before {
  --icon-glyph: "♿";
}

[data-icon-after=handicapped]::after {
  --icon-glyph: "♿";
}

[data-icon=table]::before,
[data-icon-before=table]::before {
  --icon-glyph: "󡄩";
}

[data-icon-after=table]::after {
  --icon-glyph: "󡄩";
}

[data-icon=window]::before,
[data-icon-before=window]::before {
  --icon-glyph: "󡄰";
}

[data-icon-after=window]::after {
  --icon-glyph: "󡄰";
}

[data-icon=bahnbonus]::before,
[data-icon-before=bahnbonus]::before {
  --icon-glyph: "󡄱";
}

[data-icon-after=bahnbonus]::after {
  --icon-glyph: "󡄱";
}

[data-icon=bahnbonus-card]::before,
[data-icon-before=bahnbonus-card]::before {
  --icon-glyph: "󡄲";
}

[data-icon-after=bahnbonus-card]::after {
  --icon-glyph: "󡄲";
}

[data-icon=bahncard]::before,
[data-icon-before=bahncard]::before {
  --icon-glyph: "󡄴";
}

[data-icon-after=bahncard]::after {
  --icon-glyph: "󡄴";
}

[data-icon=commuter-ticket]::before,
[data-icon-before=commuter-ticket]::before {
  --icon-glyph: "󡄶";
}

[data-icon-after=commuter-ticket]::after {
  --icon-glyph: "󡄶";
}

[data-icon=komfort-check-in]::before,
[data-icon-before=komfort-check-in]::before {
  --icon-glyph: "󡄷";
}

[data-icon-after=komfort-check-in]::after {
  --icon-glyph: "󡄷";
}

[data-icon=komfort-check-in-check]::before,
[data-icon-before=komfort-check-in-check]::before {
  --icon-glyph: "✔";
}

[data-icon-after=komfort-check-in-check]::after {
  --icon-glyph: "✔";
}

[data-icon=komfort-check-in-circle]::before,
[data-icon-before=komfort-check-in-circle]::before {
  --icon-glyph: "🤇";
}

[data-icon-after=komfort-check-in-circle]::after {
  --icon-glyph: "🤇";
}

[data-icon=my-travel]::before,
[data-icon-before=my-travel]::before {
  --icon-glyph: "󡄸";
}

[data-icon-after=my-travel]::after {
  --icon-glyph: "󡄸";
}

[data-icon=outward-journey]::before,
[data-icon-before=outward-journey]::before {
  --icon-glyph: "󡄹";
}

[data-icon-after=outward-journey]::after {
  --icon-glyph: "󡄹";
}

[data-icon=qr-code]::before,
[data-icon-before=qr-code]::before {
  --icon-glyph: "󡅀";
}

[data-icon-after=qr-code]::after {
  --icon-glyph: "󡅀";
}

[data-icon=return-journey]::before,
[data-icon-before=return-journey]::before {
  --icon-glyph: "󡅁";
}

[data-icon-after=return-journey]::after {
  --icon-glyph: "󡅁";
}

[data-icon=ticket]::before,
[data-icon-before=ticket]::before {
  --icon-glyph: "🎫";
}

[data-icon-after=ticket]::after {
  --icon-glyph: "🎫";
}

[data-icon=ticket-discount]::before,
[data-icon-before=ticket-discount]::before {
  --icon-glyph: "󡅂";
}

[data-icon-after=ticket-discount]::after {
  --icon-glyph: "󡅂";
}

[data-icon=ticket-multiple]::before,
[data-icon-before=ticket-multiple]::before {
  --icon-glyph: "󡅃";
}

[data-icon-after=ticket-multiple]::after {
  --icon-glyph: "󡅃";
}

[data-icon=time-outward-journey]::before,
[data-icon-before=time-outward-journey]::before {
  --icon-glyph: "󡅄";
}

[data-icon-after=time-outward-journey]::after {
  --icon-glyph: "󡅄";
}

[data-icon=time-return-journey]::before,
[data-icon-before=time-return-journey]::before {
  --icon-glyph: "󡅅";
}

[data-icon-after=time-return-journey]::after {
  --icon-glyph: "󡅅";
}

[data-icon=timetable]::before,
[data-icon-before=timetable]::before {
  --icon-glyph: "󡅆";
}

[data-icon-after=timetable]::after {
  --icon-glyph: "󡅆";
}

[data-icon=bicycle]::before,
[data-icon-before=bicycle]::before {
  --icon-glyph: "🚲";
}

[data-icon-after=bicycle]::after {
  --icon-glyph: "🚲";
}

[data-icon=car]::before,
[data-icon-before=car]::before {
  --icon-glyph: "󡅑";
}

[data-icon-after=car]::after {
  --icon-glyph: "󡅑";
}

[data-icon=car-sequence]::before,
[data-icon-before=car-sequence]::before {
  --icon-glyph: "󡅇";
}

[data-icon-after=car-sequence]::after {
  --icon-glyph: "󡅇";
}

[data-icon=ice-side-view]::before,
[data-icon-before=ice-side-view]::before {
  --icon-glyph: "🚄";
}

[data-icon-after=ice-side-view]::after {
  --icon-glyph: "🚄";
}

[data-icon=sprinter]::before,
[data-icon-before=sprinter]::before {
  --icon-glyph: "󡅈";
}

[data-icon-after=sprinter]::after {
  --icon-glyph: "󡅈";
}

[data-icon=walking]::before,
[data-icon-before=walking]::before {
  --icon-glyph: "🚶";
}

[data-icon-after=walking]::after {
  --icon-glyph: "🚶";
}

[data-icon=logo]::before,
[data-icon-before=logo]::before {
  --icon-glyph: "󡅒";
}

[data-icon-after=logo]::after {
  --icon-glyph: "󡅒";
}

[data-icon=buggy]::before,
[data-icon-before=buggy]::before {
  --icon-glyph: "󡅓";
}

[data-icon-after=buggy]::after {
  --icon-glyph: "󡅓";
}

[data-icon=childrens-compartment]::before,
[data-icon-before=childrens-compartment]::before {
  --icon-glyph: "🍼";
}

[data-icon-after=childrens-compartment]::after {
  --icon-glyph: "🍼";
}

[data-icon=parent-child-compartment]::before,
[data-icon-before=parent-child-compartment]::before {
  --icon-glyph: "👪";
}

[data-icon-after=parent-child-compartment]::after {
  --icon-glyph: "👪";
}

[data-icon=quiet-zone]::before,
[data-icon-before=quiet-zone]::before {
  --icon-glyph: "🤫";
}

[data-icon-after=quiet-zone]::after {
  --icon-glyph: "🤫";
}

[data-icon=duplicate]::before,
[data-icon-before=duplicate]::before {
  --icon-glyph: "󡅔";
}

[data-icon-after=duplicate]::after {
  --icon-glyph: "󡅔";
}

[data-icon=image]::before,
[data-icon-before=image]::before {
  --icon-glyph: "🖼";
}

[data-icon-after=image]::after {
  --icon-glyph: "🖼";
}

[data-icon=lock-close]::before,
[data-icon-before=lock-close]::before {
  --icon-glyph: "🔒";
}

[data-icon-after=lock-close]::after {
  --icon-glyph: "🔒";
}

[data-icon=lock-open]::before,
[data-icon-before=lock-open]::before {
  --icon-glyph: "🔓";
}

[data-icon-after=lock-open]::after {
  --icon-glyph: "🔓";
}

[data-icon=restricted-mobility-toilet]::before,
[data-icon-before=restricted-mobility-toilet]::before {
  --icon-glyph: "󡅣";
}

[data-icon-after=restricted-mobility-toilet]::after {
  --icon-glyph: "󡅣";
}

[data-icon=shopping-basket-disabled]::before,
[data-icon-before=shopping-basket-disabled]::before {
  --icon-glyph: "󡅕";
}

[data-icon-after=shopping-basket-disabled]::after {
  --icon-glyph: "󡅕";
}

[data-icon=shopping-cart-disabled]::before,
[data-icon-before=shopping-cart-disabled]::before {
  --icon-glyph: "󡅖";
}

[data-icon-after=shopping-cart-disabled]::after {
  --icon-glyph: "󡅖";
}

[data-icon=gps]::before,
[data-icon-before=gps]::before {
  --icon-glyph: "󡅗";
}

[data-icon-after=gps]::after {
  --icon-glyph: "󡅗";
}

[data-icon=gps-north]::before,
[data-icon-before=gps-north]::before {
  --icon-glyph: "󡅘";
}

[data-icon-after=gps-north]::after {
  --icon-glyph: "󡅘";
}

[data-icon=local-train]::before,
[data-icon-before=local-train]::before {
  --icon-glyph: "🚈";
}

[data-icon-after=local-train]::after {
  --icon-glyph: "🚈";
}

[data-icon=rail-and-fly]::before,
[data-icon-before=rail-and-fly]::before {
  --icon-glyph: "󡅙";
}

[data-icon-after=rail-and-fly]::after {
  --icon-glyph: "󡅙";
}

[data-icon=seat-priority]::before,
[data-icon-before=seat-priority]::before {
  --icon-glyph: "󡅢";
}

[data-icon-after=seat-priority]::after {
  --icon-glyph: "󡅢";
}

[data-icon=standing-room]::before,
[data-icon-before=standing-room]::before {
  --icon-glyph: "󡅠";
}

[data-icon-after=standing-room]::after {
  --icon-glyph: "󡅠";
}

[data-icon=mixed-class]::before,
[data-icon-before=mixed-class]::before {
  --icon-glyph: "󡅡";
}

[data-icon-after=mixed-class]::after {
  --icon-glyph: "󡅡";
}

[data-icon=transportation-train-ec]::before,
[data-icon-before=transportation-train-ec]::before {
  --icon-glyph: "";
}

[data-icon-after=transportation-train-ec]::after {
  --icon-glyph: "";
}

[data-icon=transportation-train-ic]::before,
[data-icon-before=transportation-train-ic]::before {
  --icon-glyph: "";
}

[data-icon-after=transportation-train-ic]::after {
  --icon-glyph: "";
}

[data-icon=transportation-train-ice]::before,
[data-icon-before=transportation-train-ice]::before {
  --icon-glyph: "";
}

[data-icon-after=transportation-train-ice]::after {
  --icon-glyph: "";
}

[data-icon=transportation-train-rb]::before,
[data-icon-before=transportation-train-rb]::before {
  --icon-glyph: "";
}

[data-icon-after=transportation-train-rb]::after {
  --icon-glyph: "";
}

[data-icon=transportation-train-re]::before,
[data-icon-before=transportation-train-re]::before {
  --icon-glyph: "";
}

[data-icon-after=transportation-train-re]::after {
  --icon-glyph: "";
}

[data-icon=transportation-bikesharing]::before,
[data-icon-before=transportation-bikesharing]::before {
  --icon-glyph: "";
}

[data-icon-after=transportation-bikesharing]::after {
  --icon-glyph: "";
}

[data-icon=transportation-bus-local]::before,
[data-icon-before=transportation-bus-local]::before {
  --icon-glyph: "";
}

[data-icon-after=transportation-bus-local]::after {
  --icon-glyph: "";
}

[data-icon=transportation-carsharing]::before,
[data-icon-before=transportation-carsharing]::before {
  --icon-glyph: "";
}

[data-icon-after=transportation-carsharing]::after {
  --icon-glyph: "";
}

[data-icon=transportation-bus-long-distance]::before,
[data-icon-before=transportation-bus-long-distance]::before {
  --icon-glyph: "";
}

[data-icon-after=transportation-bus-long-distance]::after {
  --icon-glyph: "";
}

[data-icon=transportation-airplane]::before,
[data-icon-before=transportation-airplane]::before {
  --icon-glyph: "";
}

[data-icon-after=transportation-airplane]::after {
  --icon-glyph: "";
}

[data-icon=transportation-s-bahn]::before,
[data-icon-before=transportation-s-bahn]::before {
  --icon-glyph: "";
}

[data-icon-after=transportation-s-bahn]::after {
  --icon-glyph: "";
}

[data-icon=transportation-ship]::before,
[data-icon-before=transportation-ship]::before {
  --icon-glyph: "";
}

[data-icon-after=transportation-ship]::after {
  --icon-glyph: "";
}

[data-icon=transportation-taxi]::before,
[data-icon-before=transportation-taxi]::before {
  --icon-glyph: "";
}

[data-icon-after=transportation-taxi]::after {
  --icon-glyph: "";
}

[data-icon=transportation-train-tram]::before,
[data-icon-before=transportation-train-tram]::before {
  --icon-glyph: "";
}

[data-icon-after=transportation-train-tram]::after {
  --icon-glyph: "";
}

[data-icon=transportation-underground]::before,
[data-icon-before=transportation-underground]::before {
  --icon-glyph: "";
}

[data-icon-after=transportation-underground]::after {
  --icon-glyph: "";
}

[data-icon=transportation-walking]::before,
[data-icon-before=transportation-walking]::before {
  --icon-glyph: "";
}

[data-icon-after=transportation-walking]::after {
  --icon-glyph: "";
}

[data-icon-variant="20-outline"][data-icon]::before, [data-icon-variant="20-outline"][data-icon-before]::before,
[data-icon-variant-before="20-outline"][data-icon]::before,
[data-icon-variant-before="20-outline"][data-icon-before]::before {
  --icon-font-family: "icons-20-outline",
  	"missing-icons" !important;
  --icon-font-size: 1.25rem;
}

[data-icon-variant-after="20-outline"][data-icon-after]::after {
  --icon-font-family: "icons-20-outline",
  	"missing-icons" !important;
  --icon-font-size: 1.25rem;
}

[data-icon-variant="24-outline"][data-icon]::before, [data-icon-variant="24-outline"][data-icon-before]::before,
[data-icon-variant-before="24-outline"][data-icon]::before,
[data-icon-variant-before="24-outline"][data-icon-before]::before {
  --icon-font-family: "icons-24-outline",
  	"missing-icons" !important;
  --icon-font-size: 1.5rem;
}

[data-icon-variant-after="24-outline"][data-icon-after]::after {
  --icon-font-family: "icons-24-outline",
  	"missing-icons" !important;
  --icon-font-size: 1.5rem;
}

[data-icon-variant="32-outline"][data-icon]::before, [data-icon-variant="32-outline"][data-icon-before]::before,
[data-icon-variant-before="32-outline"][data-icon]::before,
[data-icon-variant-before="32-outline"][data-icon-before]::before {
  --icon-font-family: "icons-32-outline",
  	"missing-icons" !important;
  --icon-font-size: 2rem;
}

[data-icon-variant-after="32-outline"][data-icon-after]::after {
  --icon-font-family: "icons-32-outline",
  	"missing-icons" !important;
  --icon-font-size: 2rem;
}

[data-icon-variant="48-outline"][data-icon]::before, [data-icon-variant="48-outline"][data-icon-before]::before,
[data-icon-variant-before="48-outline"][data-icon]::before,
[data-icon-variant-before="48-outline"][data-icon-before]::before {
  --icon-font-family: "icons-48-outline",
  	"missing-icons" !important;
  --icon-font-size: 3rem;
}

[data-icon-variant-after="48-outline"][data-icon-after]::after {
  --icon-font-family: "icons-48-outline",
  	"missing-icons" !important;
  --icon-font-size: 3rem;
}

[data-icon-variant="64-outline"][data-icon]::before, [data-icon-variant="64-outline"][data-icon-before]::before,
[data-icon-variant-before="64-outline"][data-icon]::before,
[data-icon-variant-before="64-outline"][data-icon-before]::before {
  --icon-font-family: "icons-64-outline",
  	"missing-icons" !important;
  --icon-font-size: 4rem;
}

[data-icon-variant-after="64-outline"][data-icon-after]::after {
  --icon-font-family: "icons-64-outline",
  	"missing-icons" !important;
  --icon-font-size: 4rem;
}

[data-icon-variant="20-filled"][data-icon]::before, [data-icon-variant="20-filled"][data-icon-before]::before,
[data-icon-variant-before="20-filled"][data-icon]::before,
[data-icon-variant-before="20-filled"][data-icon-before]::before {
  --icon-font-family: "icons-20-filled",
  	"missing-icons" !important;
  --icon-font-size: 1.25rem;
}

[data-icon-variant-after="20-filled"][data-icon-after]::after {
  --icon-font-family: "icons-20-filled",
  	"missing-icons" !important;
  --icon-font-size: 1.25rem;
}

[data-icon-variant="24-filled"][data-icon]::before, [data-icon-variant="24-filled"][data-icon-before]::before,
[data-icon-variant-before="24-filled"][data-icon]::before,
[data-icon-variant-before="24-filled"][data-icon-before]::before {
  --icon-font-family: "icons-24-filled",
  	"missing-icons" !important;
  --icon-font-size: 1.5rem;
}

[data-icon-variant-after="24-filled"][data-icon-after]::after {
  --icon-font-family: "icons-24-filled",
  	"missing-icons" !important;
  --icon-font-size: 1.5rem;
}

[data-icon-variant="32-filled"][data-icon]::before, [data-icon-variant="32-filled"][data-icon-before]::before,
[data-icon-variant-before="32-filled"][data-icon]::before,
[data-icon-variant-before="32-filled"][data-icon-before]::before {
  --icon-font-family: "icons-32-filled",
  	"missing-icons" !important;
  --icon-font-size: 2rem;
}

[data-icon-variant-after="32-filled"][data-icon-after]::after {
  --icon-font-family: "icons-32-filled",
  	"missing-icons" !important;
  --icon-font-size: 2rem;
}

[data-icon-variant="48-filled"][data-icon]::before, [data-icon-variant="48-filled"][data-icon-before]::before,
[data-icon-variant-before="48-filled"][data-icon]::before,
[data-icon-variant-before="48-filled"][data-icon-before]::before {
  --icon-font-family: "icons-48-filled",
  	"missing-icons" !important;
  --icon-font-size: 3rem;
}

[data-icon-variant-after="48-filled"][data-icon-after]::after {
  --icon-font-family: "icons-48-filled",
  	"missing-icons" !important;
  --icon-font-size: 3rem;
}

[data-icon-variant="64-filled"][data-icon]::before, [data-icon-variant="64-filled"][data-icon-before]::before,
[data-icon-variant-before="64-filled"][data-icon]::before,
[data-icon-variant-before="64-filled"][data-icon-before]::before {
  --icon-font-family: "icons-64-filled",
  	"missing-icons" !important;
  --icon-font-size: 4rem;
}

[data-icon-variant-after="64-filled"][data-icon-after]::after {
  --icon-font-family: "icons-64-filled",
  	"missing-icons" !important;
  --icon-font-size: 4rem;
}

@font-face {
  font-family: "missing-icons";
  src: url('icons-empty.6a719ec2c9312b93.woff2?4r2095') format("woff2"), url('icons-empty.b0990e71a0d6b034.woff?4r2095') format("woff");
  font-display: block;
}
@font-face {
  font-family: "icons-20-outline";
  src: url('icons-20-outline-action.67da64a2935ea790.woff2?4r2095') format("woff2"), url('icons-20-outline-action.6279f312836e21f6.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+1f642,U+e1062,U+e1066,U+e1069-e1070,U+e1077,U+e1079,U+e1081-e1084,U+002B,U+2295,U+23F0,U+1F514-1F515,U+1F4C6,U+2705,U+1F4B3,U+1F5D1,U+1F5B9,U+2713,U+2B73,U+270F,U+1F381,U+2661,U+003F,U+1F6C8,U+00A7,U+21A6,U+2212,U+1F5A8,U+229D,U+1F553,U+1F50D,U+25F9,U+2699,U+1F6CD,U+1F6D2,U+2606,U+21C6,U+21F5,U+1F441,U+1F310,U+e1154,U+1F5BC,U+1F512,U+1F513,U+e1155,U+e1156;
}
@font-face {
  font-family: "icons-20-outline";
  src: url('icons-20-outline-av.f93a0026447b61d1.woff2?4r2095') format("woff2"), url('icons-20-outline-av.3e5ed12542eb9853.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+e1085-e1088,U+e1091,U+293A,U+293B,U+23F8,U+25B7,U+23EE,U+23ED,U+25A1,U+1F507-1F509,U+1F50A;
}
@font-face {
  font-family: "icons-20-outline";
  src: url('icons-20-outline-brand.9365588da90d8399.woff2?4r2095') format("woff2"), url('icons-20-outline-brand.7eb205c4d37e8b08.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+e1152;
}
@font-face {
  font-family: "icons-20-outline";
  src: url('icons-20-outline-communication.1b78bba21ece7f8c.woff2?4r2095') format("woff2"), url('icons-20-outline-communication.5f25ba16ef736629.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+1F4DE,U+e1095,U+e1098,U+e1149,U+1F4AC,U+1F5B7,U+2370,U+2709,U+1F4F4,U+1F4F1;
}
@font-face {
  font-family: "icons-20-outline";
  src: url('icons-20-outline-covid-19.f6303f6a9fc710e7.woff2?4r2095') format("woff2"), url('icons-20-outline-covid-19.7069ddc1c6c91532.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+1F637;
}
@font-face {
  font-family: "icons-20-outline";
  src: url('icons-20-outline-facilities.d49a63170fe75160.woff2?4r2095') format("woff2"), url('icons-20-outline-facilities.bd672a85559a8b68.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+1F6BB,U+1F6B9,U+1F6BE,U+1F6BA,U+e1163;
}
@font-face {
  font-family: "icons-20-outline";
  src: url('icons-20-outline-feature.6e6dce6de1846235.woff2?4r2095') format("woff2"), url('icons-20-outline-feature.a7537d119b0430db.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+1F9F3,U+271A,U+1F50C,U+e1150,U+e1153,U+e1160,U+2744;
}
@font-face {
  font-family: "icons-20-outline";
  src: url('icons-20-outline-food.aca3bd0ff5c20d25.woff2?4r2095') format("woff2"), url('icons-20-outline-food.294a61bcad884b92.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+2615,U+e1101,U+1F37D;
}
@font-face {
  font-family: "icons-20-outline";
  src: url('icons-20-outline-journey.7c8de6ca42e2f1e0.woff2?4r2095') format("woff2"), url('icons-20-outline-journey.ee2393963cd479e7.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+2488,U+2489,U+e1102-e1104,U+e1106,U+e1108,U+e1110,U+e1113,U+1F4CD,U+2B94,U+291E,U+29BF,U+e1161;
}
@font-face {
  font-family: "icons-20-outline";
  src: url('icons-20-outline-maps.76f673badedad2ff.woff2?4r2095') format("woff2"), url('icons-20-outline-maps.0e509c2156644ea7.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+2302,U+e1115-e1116,U+1F5FA,U+1F78B,U+1F689,U+e1157;
}
@font-face {
  font-family: "icons-20-outline";
  src: url('icons-20-outline-navigation.49d43c387295e033.woff2?4r2095') format("woff2"), url('icons-20-outline-navigation.866f360e379cba3f.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+2190-2193,U+2BBE,U+e1119-e1122,U+003C,U+003E,U+2A2F,U+2303,U+2304,U+1030E,U+1F862,U+1F865,U+22EF,U+22EE,U+1F5D8;
}
@font-face {
  font-family: "icons-20-outline";
  src: url('icons-20-outline-notification.561a32c268490a80.woff2?4r2095') format("woff2"), url('icons-20-outline-notification.e7c434183e8a412a.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+e1123,U+26A0,U+e1126,U+e1127;
}
@font-face {
  font-family: "icons-20-outline";
  src: url('icons-20-outline-seat.d948b752d85b48eb.woff2?4r2095') format("woff2"), url('icons-20-outline-seat.bcdb14812000d6ea.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+e1128-e1130,U+1F4BA,U+267F,U+1F37C,U+1F46A,U+1F92B,U+e1162;
}
@font-face {
  font-family: "icons-20-outline";
  src: url('icons-20-outline-ticket.8245c13837ae6f26.woff2?4r2095') format("woff2"), url('icons-20-outline-ticket.7ac781ec37ecdb76.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+e1131-e1132,U+e1134,U+e1136-e1146,U+2714,U+1F907,U+1F3AB;
}
@font-face {
  font-family: "icons-20-outline";
  src: url('icons-20-outline-transportation.dba02734311cd673.woff2?4r2095') format("woff2"), url('icons-20-outline-transportation.3e10f5653538459c.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+1F6B2,U+e1147,U+e1151,U+1F684,U+e1148,U+1F6B6,U+1F688,U+e1159;
}
@font-face {
  font-family: "icons-24-outline";
  src: url('icons-24-outline-action.2d49761a6fbddb98.woff2?4r2095') format("woff2"), url('icons-24-outline-action.8f3744dd570b9981.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+1f642,U+002B,U+2295,U+23F0,U+e1062,U+1F514-1F515,U+1F4C6,U+2705,U+1F4B3,U+1F5D1,U+e1066,U+1F5B9,U+2713,U+2B73,U+270F,U+e1069,U+e1070,U+1F381,U+2661,U+003F,U+1F6C8,U+00A7,U+21A6,U+2212,U+1F5A8,U+229D,U+1F553,U+1F50D,U+25F9,U+2699,U+e1077,U+1F6CD,U+e1079,U+1F6D2,U+2606,U+21C6,U+21F5,U+e1081,U+1F441,U+e1082,U+1F310,U+e1083,U+e1084,U+e1154,U+1F512,U+1F513,U+e1155,U+e1156;
}
@font-face {
  font-family: "icons-24-outline";
  src: url('icons-24-outline-av.51cf59023769ecca.woff2?4r2095') format("woff2"), url('icons-24-outline-av.111730276fa951eb.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+e1085,U+e1086,U+293A,U+e1087,U+e1088,U+293B,U+23F8,U+25B7,U+23EE,U+23ED,U+25A1,U+e1091,U+1F509,U+1F508,U+1F507,U+1F50A;
}
@font-face {
  font-family: "icons-24-outline";
  src: url('icons-24-outline-brand.428a27e4814ac348.woff2?4r2095') format("woff2"), url('icons-24-outline-brand.dbc5b5ad6dee8d81.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+e1152;
}
@font-face {
  font-family: "icons-24-outline";
  src: url('icons-24-outline-communication.b952bc42e32fdc48.woff2?4r2095') format("woff2"), url('icons-24-outline-communication.12dd4722ba6f0be6.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+1F4DE,U+1F4AC,U+e1095,U+1F5B7,U+2370,U+2709,U+1F4F4,U+1F4F1,U+e1098,U+e1149;
}
@font-face {
  font-family: "icons-24-outline";
  src: url('icons-24-outline-covid-19.37df99a6642b6941.woff2?4r2095') format("woff2"), url('icons-24-outline-covid-19.a8a084f3fff9ad16.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+1F637;
}
@font-face {
  font-family: "icons-24-outline";
  src: url('icons-24-outline-facilities.d8ff35397f9ecacb.woff2?4r2095') format("woff2"), url('icons-24-outline-facilities.9b738625446e0ce5.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+1F6BB,U+1F6B9,U+1F6BE,U+1F6BA,U+e1163;
}
@font-face {
  font-family: "icons-24-outline";
  src: url('icons-24-outline-feature.401b59d6b8b2e113.woff2?4r2095') format("woff2"), url('icons-24-outline-feature.05268216dfa5eebc.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+1F9F3,U+271A,U+1F50C,U+e1150,U+e1153,U+e1160,U+2744;
}
@font-face {
  font-family: "icons-24-outline";
  src: url('icons-24-outline-food.a49451f296891e0d.woff2?4r2095') format("woff2"), url('icons-24-outline-food.7984736b1c568b5a.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+2615,U+e1101,U+1F37D;
}
@font-face {
  font-family: "icons-24-outline";
  src: url('icons-24-outline-journey.5f70a97711f296ae.woff2?4r2095') format("woff2"), url('icons-24-outline-journey.d8876ff8e0ad6dfb.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+2488-2489,U+e1102-e1104,U+e1106,U+e1108,U+e1110,U+1F4CD,U+e1113,U+2B94,U+291E,U+29BF,U+e1161;
}
@font-face {
  font-family: "icons-24-outline";
  src: url('icons-24-outline-maps.d91d4d6cf2ee654b.woff2?4r2095') format("woff2"), url('icons-24-outline-maps.d30dbdcfa3353e41.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+2302,U+1F5FA,U+e1115,U+e1116,U+1F78B,U+1F689,U+e1157;
}
@font-face {
  font-family: "icons-24-outline";
  src: url('icons-24-outline-navigation.e44a4cfc384edcfe.woff2?4r2095') format("woff2"), url('icons-24-outline-navigation.ae1e8c0ed6357248.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+2190-2193,U+2BBE,U+003C,U+003E,U+2A2F,U+2303,U+2304,U+e1119,U+e1120,U+1030E,U+1F862,U+1F865,U+e1121,U+e1122,U+22EF,U+22EE,U+1F5D8;
}
@font-face {
  font-family: "icons-24-outline";
  src: url('icons-24-outline-notification.b0e564be1a137b15.woff2?4r2095') format("woff2"), url('icons-24-outline-notification.9854c9fc2c84d33c.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+e1123-e1127,U+26A0;
}
@font-face {
  font-family: "icons-24-outline";
  src: url('icons-24-outline-seat.cba797a700f7515d.woff2?4r2095') format("woff2"), url('icons-24-outline-seat.4bdc9bf56b9a4668.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+1F4BA,U+e1128-e1130,U+267F,U+1F37C,U+1F46A,U+1F92B,U+e1162;
}
@font-face {
  font-family: "icons-24-outline";
  src: url('icons-24-outline-ticket.e24b15cd1d7cf43c.woff2?4r2095') format("woff2"), url('icons-24-outline-ticket.5018f0a0658f2ecb.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+e1131-e1146,U+2714,U+1F907,U+1F3AB;
}
@font-face {
  font-family: "icons-24-outline";
  src: url('icons-24-outline-transportation.799c77adbfa4fcfb.woff2?4r2095') format("woff2"), url('icons-24-outline-transportation.cfec0949fd57b26e.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+1F6B2,U+e1147,U+e1151,U+1F684,U+e1148,U+1F6B6,U+1F688,U+e1159;
}
@font-face {
  font-family: "icons-32-outline";
  src: url('icons-32-outline-action.85172caba863aadd.woff2?4r2095') format("woff2"), url('icons-32-outline-action.0f72e1ea6b205a09.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+1f642,U+2295,U+002B,U+e1062,U+e1064-e1084,U+23F0,U+1F514-1F515,U+1F4C6,U+2705,U+1F4B3,U+1F5D1,U+1F5B9,U+2713,U+2B73,U+270F,U+1F381,U+2661,U+003F,U+1F6C8,U+00A7,U+21A6,U+2212,U+1F5A8,U+229D,U+1F553,U+1F50D,U+25F9,U+2699,U+1F6CD,U+1F6D2,U+2606,U+21C6,U+21F5,U+1F441,U+1F310,U+e1154,U+1F512,U+1F513,U+e1155,U+e1156;
}
@font-face {
  font-family: "icons-32-outline";
  src: url('icons-32-outline-av.6f5c803de6bd838f.woff2?4r2095') format("woff2"), url('icons-32-outline-av.01cfb6ad925b9ac8.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+e1085-e1088,U+e1091,U+293A,U+293B,U+23F8,U+25B7,U+23EE,U+23ED,U+25A1,U+1F509,U+1F508,U+1F507,U+1F50A;
}
@font-face {
  font-family: "icons-32-outline";
  src: url('icons-32-outline-brand.e4955cbbebd05e36.woff2?4r2095') format("woff2"), url('icons-32-outline-brand.62f5603a4b29619d.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+e1152;
}
@font-face {
  font-family: "icons-32-outline";
  src: url('icons-32-outline-communication.a9fb653e46cb17e6.woff2?4r2095') format("woff2"), url('icons-32-outline-communication.10be59cbff569c92.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+1F4DE,U+1F4AC,U+e1095,U+1F5B7,U+2370,U+2709,U+1F4F4,U+1F4F1,U+e1098,U+e1149;
}
@font-face {
  font-family: "icons-32-outline";
  src: url('icons-32-outline-covid-19.7096be379c9a6309.woff2?4r2095') format("woff2"), url('icons-32-outline-covid-19.0ea59aed72eebc19.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+1F637;
}
@font-face {
  font-family: "icons-32-outline";
  src: url('icons-32-outline-facilities.b152efeca1e31c6f.woff2?4r2095') format("woff2"), url('icons-32-outline-facilities.a7532e70e392f1b0.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+1F6B9,U+1F6BE,U+1F6BA,U+1F6BB,U+e1163;
}
@font-face {
  font-family: "icons-32-outline";
  src: url('icons-32-outline-feature.0a5c7241b20fc6b1.woff2?4r2095') format("woff2"), url('icons-32-outline-feature.427633f5a74dbcb7.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+1F9F3,U+271A,U+1F50C,U+e1150,U+e1153,U+e1160,U+2744;
}
@font-face {
  font-family: "icons-32-outline";
  src: url('icons-32-outline-food.fba5a5258a6b94fe.woff2?4r2095') format("woff2"), url('icons-32-outline-food.44adea13929bdced.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+2615,U+e1101,U+1F37D;
}
@font-face {
  font-family: "icons-32-outline";
  src: url('icons-32-outline-journey.8926cc39c95b754d.woff2?4r2095') format("woff2"), url('icons-32-outline-journey.05273fb3095cbe3b.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+2488,U+2489,U+e1102-e1110,U+1F4CD,U+e1113,U+2B94,U+291E,U+29BF,U+e1161;
}
@font-face {
  font-family: "icons-32-outline";
  src: url('icons-32-outline-maps.bddb5bd7dfff06a7.woff2?4r2095') format("woff2"), url('icons-32-outline-maps.54aea185e106abbb.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+2302,U+1F5FA,U+e1116,U+1F78B,U+e1115,U+1F689,U+e1157;
}
@font-face {
  font-family: "icons-32-outline";
  src: url('icons-32-outline-navigation.4784c2a96207fe44.woff2?4r2095') format("woff2"), url('icons-32-outline-navigation.640c0fdf7c6fa627.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+2190-2193,U+e1119-e1122,U+2BBE,U+003C,U+003E,U+2A2F,U+2303,U+2304,U+1030E,U+1F865,U+1F862,U+22EF,U+22EE,U+1F5D8;
}
@font-face {
  font-family: "icons-32-outline";
  src: url('icons-32-outline-notification.a31c05ba09d17f44.woff2?4r2095') format("woff2"), url('icons-32-outline-notification.63717529fe0dce35.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+26A0,U+e1123-e1127;
}
@font-face {
  font-family: "icons-32-outline";
  src: url('icons-32-outline-seat.352f8609b702a2cf.woff2?4r2095') format("woff2"), url('icons-32-outline-seat.b6ef176c43c23a05.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+1F4BA,U+e1128-e1130,U+267F,U+1F37C,U+1F46A,U+1F92B,U+e1162;
}
@font-face {
  font-family: "icons-32-outline";
  src: url('icons-32-outline-ticket.0f749a78afa628ae.woff2?4r2095') format("woff2"), url('icons-32-outline-ticket.831299d7a0fed189.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+e1131-e1146,U+2714,U+1F907,U+1F3AB;
}
@font-face {
  font-family: "icons-32-outline";
  src: url('icons-32-outline-transportation.b77c746cc8ad4ab7.woff2?4r2095') format("woff2"), url('icons-32-outline-transportation.8c9572af04d00654.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+1F6B2,U+e1147,U+e1151,U+1F684,U+e1148,U+1F6B6,U+1F688,U+e1159;
}
@font-face {
  font-family: "icons-48-outline";
  src: url('icons-48-outline-av.ddfc25ba8753fa13.woff2?4r2095') format("woff2"), url('icons-48-outline-av.fbf55e834e38fffc.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+23F8,U+25B7;
}
@font-face {
  font-family: "icons-64-outline";
  src: url('icons-64-outline-av.ac03d9163f8f7ac6.woff2?4r2095') format("woff2"), url('icons-64-outline-av.8dd69373a79ee427.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+23F8,U+25B7;
}
@font-face {
  font-family: "icons-20-filled";
  src: url('icons-20-filled-action.5aede8553b311e27.woff2?4r2095') format("woff2"), url('icons-20-filled-action.e4745684c8dd7bb0.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+1f642,U+2295,U+1F514,U+2705,U+1F4B3,U+e1066,U+270F,U+e1070,U+003F,U+1F6C8,U+1F5A8,U+229D,U+1F553,U+1F6CD,U+e1079,U+2661,U+2606,U+e1154,U+1F5BC,U+1F512,U+1F513,U+e1155;
}
@font-face {
  font-family: "icons-20-filled";
  src: url('icons-20-filled-av.2c32e255f5ad0348.woff2?4r2095') format("woff2"), url('icons-20-filled-av.ce3712d83c03908e.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+25B7,U+23EE,U+23ED,U+25A1,U+e1091;
}
@font-face {
  font-family: "icons-20-filled";
  src: url('icons-20-filled-communication.b25212da6015b239.woff2?4r2095') format("woff2"), url('icons-20-filled-communication.3bd3a59623acfc91.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+1F4DE,U+1F4AC,U+e1095,U+2370;
}
@font-face {
  font-family: "icons-20-filled";
  src: url('icons-20-filled-feature.4665abddbb2c8b0f.woff2?4r2095') format("woff2"), url('icons-20-filled-feature.dd3f8b0ec4f42574.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+271A;
}
@font-face {
  font-family: "icons-20-filled";
  src: url('icons-20-filled-food.7490359bd2beeff6.woff2?4r2095') format("woff2"), url('icons-20-filled-food.5f200c1e69213505.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+2615;
}
@font-face {
  font-family: "icons-20-filled";
  src: url('icons-20-filled-journey.e052acdc99165e0d.woff2?4r2095') format("woff2"), url('icons-20-filled-journey.6741ea507a524adc.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+e1104,U+e1106,U+e1108,U+e1110,U+1F4CD;
}
@font-face {
  font-family: "icons-20-filled";
  src: url('icons-20-filled-maps.bd06b663d297644d.woff2?4r2095') format("woff2"), url('icons-20-filled-maps.7ede4bb5eb26005b.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+2302,U+e1116,U+e1157,U+e1158;
}
@font-face {
  font-family: "icons-20-filled";
  src: url('icons-20-filled-navigation.8f5a82b8756a44e1.woff2?4r2095') format("woff2"), url('icons-20-filled-navigation.7c9c10fba182106c.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+2BBE;
}
@font-face {
  font-family: "icons-20-filled";
  src: url('icons-20-filled-notification.7070a408796a31e9.woff2?4r2095') format("woff2"), url('icons-20-filled-notification.c5b78a9ae8fd54af.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+e1123,U+26A0;
}
@font-face {
  font-family: "icons-20-filled";
  src: url('icons-20-filled-ticket.9da941367ecbb7b0.woff2?4r2095') format("woff2"), url('icons-20-filled-ticket.e0e9a72d799cadf9.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+e1132,U+e1134;
}
@font-face {
  font-family: "icons-24-filled";
  src: url('icons-24-filled-action.896363e3d72b58ca.woff2?4r2095') format("woff2"), url('icons-24-filled-action.07d25d344ac442ca.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+1F514,U+2661,U+2606;
}
@font-face {
  font-family: "icons-24-filled";
  src: url('icons-24-filled-av.6d59bb15dbf92fb4.woff2?4r2095') format("woff2"), url('icons-24-filled-av.051edca3bdf36f49.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+25B7,U+25A1,U+23EE,U+23ED;
}
@font-face {
  font-family: "icons-24-filled";
  src: url('icons-24-filled-journey.a6060d11e5a470e7.woff2?4r2095') format("woff2"), url('icons-24-filled-journey.0563ee76c6121acb.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+e1104,U+e1106,U+e1108,U+e1110,U+1F4CD;
}
@font-face {
  font-family: "icons-24-filled";
  src: url('icons-24-filled-maps.b56bf82e284ecdf2.woff2?4r2095') format("woff2"), url('icons-24-filled-maps.a7b1e3105b03c82c.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+e1157,U+e1158;
}
@font-face {
  font-family: "icons-24-filled";
  src: url('icons-24-filled-navigation.c4de4584497f74b9.woff2?4r2095') format("woff2"), url('icons-24-filled-navigation.cda3c30d35be253c.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+2BBE;
}
@font-face {
  font-family: "icons-32-filled";
  src: url('icons-32-filled-action.8f594990c11247df.woff2?4r2095') format("woff2"), url('icons-32-filled-action.87add06ca92518d7.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+1F514,U+2661,U+2606;
}
@font-face {
  font-family: "icons-32-filled";
  src: url('icons-32-filled-av.568fffcae116b6fe.woff2?4r2095') format("woff2"), url('icons-32-filled-av.cd747bae9fa1bb27.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+25B7,U+23EE,U+23ED,U+25A1;
}
@font-face {
  font-family: "icons-32-filled";
  src: url('icons-32-filled-maps.02c9994ca8b9419d.woff2?4r2095') format("woff2"), url('icons-32-filled-maps.af360a9357d1931a.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+e1157,U+e1158;
}
@font-face {
  font-family: "icons-32-filled";
  src: url('icons-32-filled-navigation.873f7fd9a4af97b4.woff2?4r2095') format("woff2"), url('icons-32-filled-navigation.9001af0ae20ee365.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+2BBE;
}
@font-face {
  font-family: "icons-48-filled";
  src: url('icons-48-filled-av.0bea7b8cea279104.woff2?4r2095') format("woff2"), url('icons-48-filled-av.4d5900b93eccca4d.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+25B7;
}
@font-face {
  font-family: "icons-64-filled";
  src: url('icons-64-filled-av.f27fcef0ce39c299.woff2?4r2095') format("woff2"), url('icons-64-filled-av.033dd068a18a52bf.woff?4r2095') format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+25B7;
}
.cmp-dropdown.is-account summary::before, .cmp-overflow-menu summary::before, .cmp-dropdown summary::before, .cmp-link-list li .elm-link::before, .cmp-sidenavi .elm-link[rel=help]::before, .cmp-sidenavi .elm-link[rel=calendar]::before, .cmp-sidenavi .elm-link[rel=cloud]::before, .cmp-sidenavi .elm-link[rel=dashboard]::before, .cmp-sidenavi .elm-link[rel=documents]::before, .cmp-sidenavi .elm-link[rel=index]::before, .cmp-pagination .elm-link[rel=next]::before, .cmp-pagination .elm-link[rel=prev]::before, .cmp-language-switcher li[aria-selected] .elm-link::after, .cmp-dialog header .elm-link.is-close::before, .cmp-sitesearch .elm-button::before, .cmp-sitesearch .elm-label::before, .cmp-mainnavigation li[aria-haspopup=true]::after, .cmp-mainnavigation > label[for]::after, .cmp-mainnavigation > label[for]::before, .cmp-accordion summary::before, .cmp-breadcrumb ol > *:not(:first-child)::before, .cmp-breadcrumb ol > *:first-child::before, .elm-checkbox::before, .elm-link[rel=account]::before, .elm-link[rel=messages]::before, .elm-link[rel=configuration]::before, [data-icon-after]::after, [data-icon]::before,
[data-icon-before]::before {
  /*** icon - placeholder ***/
  font-family: var(--icon-font-family) !important;
  font-size: var(--icon-font-size);
  vertical-align: middle;
  content: var(--icon-glyph);
  font-style: normal;
  font-weight: normal;
  font-weight: var(--icon-font-weight, normal);
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-alt: "";
  alt: "";
  speak: none;
}
@supports (content: ""/"") {
  .cmp-dropdown.is-account summary::before, .cmp-overflow-menu summary::before, .cmp-dropdown summary::before, .cmp-link-list li .elm-link::before, .cmp-sidenavi .elm-link[rel=help]::before, .cmp-sidenavi .elm-link[rel=calendar]::before, .cmp-sidenavi .elm-link[rel=cloud]::before, .cmp-sidenavi .elm-link[rel=dashboard]::before, .cmp-sidenavi .elm-link[rel=documents]::before, .cmp-sidenavi .elm-link[rel=index]::before, .cmp-pagination .elm-link[rel=next]::before, .cmp-pagination .elm-link[rel=prev]::before, .cmp-language-switcher li[aria-selected] .elm-link::after, .cmp-dialog header .elm-link.is-close::before, .cmp-sitesearch .elm-button::before, .cmp-sitesearch .elm-label::before, .cmp-mainnavigation li[aria-haspopup=true]::after, .cmp-mainnavigation > label[for]::after, .cmp-mainnavigation > label[for]::before, .cmp-accordion summary::before, .cmp-breadcrumb ol > *:not(:first-child)::before, .cmp-breadcrumb ol > *:first-child::before, .elm-checkbox::before, .elm-link[rel=account]::before, .elm-link[rel=messages]::before, .elm-link[rel=configuration]::before, [data-icon-after]::after, [data-icon]::before,
  [data-icon-before]::before {
    content: var(--icon-glyph)/"";
  }
}
@media aural {
  .cmp-dropdown.is-account summary::before, .cmp-overflow-menu summary::before, .cmp-dropdown summary::before, .cmp-link-list li .elm-link::before, .cmp-sidenavi .elm-link[rel=help]::before, .cmp-sidenavi .elm-link[rel=calendar]::before, .cmp-sidenavi .elm-link[rel=cloud]::before, .cmp-sidenavi .elm-link[rel=dashboard]::before, .cmp-sidenavi .elm-link[rel=documents]::before, .cmp-sidenavi .elm-link[rel=index]::before, .cmp-pagination .elm-link[rel=next]::before, .cmp-pagination .elm-link[rel=prev]::before, .cmp-language-switcher li[aria-selected] .elm-link::after, .cmp-dialog header .elm-link.is-close::before, .cmp-sitesearch .elm-button::before, .cmp-sitesearch .elm-label::before, .cmp-mainnavigation li[aria-haspopup=true]::after, .cmp-mainnavigation > label[for]::after, .cmp-mainnavigation > label[for]::before, .cmp-accordion summary::before, .cmp-breadcrumb ol > *:not(:first-child)::before, .cmp-breadcrumb ol > *:first-child::before, .elm-checkbox::before, .elm-link[rel=account]::before, .elm-link[rel=messages]::before, .elm-link[rel=configuration]::before, [data-icon-after]::after, [data-icon]::before,
  [data-icon-before]::before {
    content: none;
  }
}
@media speech {
  .cmp-dropdown.is-account summary::before, .cmp-overflow-menu summary::before, .cmp-dropdown summary::before, .cmp-link-list li .elm-link::before, .cmp-sidenavi .elm-link[rel=help]::before, .cmp-sidenavi .elm-link[rel=calendar]::before, .cmp-sidenavi .elm-link[rel=cloud]::before, .cmp-sidenavi .elm-link[rel=dashboard]::before, .cmp-sidenavi .elm-link[rel=documents]::before, .cmp-sidenavi .elm-link[rel=index]::before, .cmp-pagination .elm-link[rel=next]::before, .cmp-pagination .elm-link[rel=prev]::before, .cmp-language-switcher li[aria-selected] .elm-link::after, .cmp-dialog header .elm-link.is-close::before, .cmp-sitesearch .elm-button::before, .cmp-sitesearch .elm-label::before, .cmp-mainnavigation li[aria-haspopup=true]::after, .cmp-mainnavigation > label[for]::after, .cmp-mainnavigation > label[for]::before, .cmp-accordion summary::before, .cmp-breadcrumb ol > *:not(:first-child)::before, .cmp-breadcrumb ol > *:first-child::before, .elm-checkbox::before, .elm-link[rel=account]::before, .elm-link[rel=messages]::before, .elm-link[rel=configuration]::before, [data-icon-after]::after, [data-icon]::before,
  [data-icon-before]::before {
    content: none;
  }
}

[data-icon]:not([data-icon-variant])::before,
[data-icon-before]:not([data-icon-variant-before])::before, [data-icon-after]:not([data-icon-variant-after])::after {
  --icon-font-family: icons-24-outline, "missing-icons" !important;
  --icon-font-size: 1.5rem;
}

[data-icon]::before,
[data-icon-before]::before {
  margin-inline-end: var(--icon-margin-after, 0.375rem);
}

[data-icon-after]::after {
  margin-inline-start: var(--icon-margin-before, 0.375rem);
}

.is-icon-text-replace[data-icon], .is-icon-text-replace[data-icon-before] {
  font-size: 0;
}
.is-icon-text-replace[data-icon]::before, .is-icon-text-replace[data-icon-before]::before {
  --icon-margin-after: 0;
}
.is-icon-text-replace[data-icon-after] {
  font-size: 0;
}
.is-icon-text-replace[data-icon-after]::after {
  --icon-margin-before: 0;
}

/* button */
.elm-button {
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  min-height: 2.75rem;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  /*&:focus {
  	border-color: #fff;
  }*/
  /*	&:active {
  	filter: brightness(75%);
  }*/
}
.elm-button[data-icon], .elm-button[data-icon-before] {
  padding-left: 0.875rem;
}
.elm-button[data-icon-after] {
  padding-right: 0.875rem;
}
.elm-button.is-icon-text-replace {
  width: 2.75rem;
}
.elm-button[data-variant=brand-primary], .elm-button[data-variant=primary] {
  color: #fff;
}
.elm-button[data-variant=brand-primary], .elm-button[data-variant=primary] {
  font-weight: 700;
}
.elm-button[data-variant=brand-primary] {
  background-color: #ec0016;
}
.elm-button[data-variant=brand-primary]:not(:disabled):hover {
  background-color: #db0016;
}
.elm-button[data-variant=brand-primary]:active {
  background-color: #cc0013;
}
.elm-button[data-variant=primary] {
  background-color: #282d37;
}
.elm-button[data-variant=primary]:not(:disabled):hover {
  background-color: #2f3541;
}
.elm-button[data-variant=primary]:active {
  background-color: #363c4a;
}
.elm-button[data-variant=secondary-outline] {
  background-color: transparent;
  border-color: #282d37;
  color: #282d37;
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
}
.elm-button[data-variant=secondary-outline]:not(:disabled):hover {
  background-color: rgba(40, 45, 55, 0.03);
}
.elm-button[data-variant=secondary-outline]:disabled {
  background-color: transparent;
  color: #282d37;
}
.elm-button[data-variant=secondary-outline]:active {
  background-color: rgba(40, 45, 55, 0.06);
}
.elm-button[data-variant=secondary-solid] {
  color: #282d37;
  background-color: #d7dce1;
}
.elm-button[data-variant=secondary-solid]:not(:disabled):hover {
  background-color: #cdd4da;
}
.elm-button[data-variant=secondary-solid]:active {
  background-color: #c5ccd3;
}
.elm-button[data-variant=tertiary-plain] {
  color: #282d37;
  border: 2px solid transparent;
  background-color: transparent;
}
.elm-button[data-variant=tertiary-plain]:not(:disabled):hover {
  background-color: rgba(40, 45, 55, 0.03);
}
.elm-button[data-variant=tertiary-plain]:disabled {
  background-color: transparent;
}
.elm-button[data-variant=tertiary-plain]:active {
  background-color: rgba(40, 45, 55, 0.06);
}
.elm-button[data-size=small] {
  min-height: 2.25rem;
}
.elm-button[data-size=small]:not(.is-icon-text-replace) {
  font-size: 0.875rem;
}
.elm-button[data-icon][data-size=small]::before, .elm-button[data-icon-before][data-size=small]::before {
  --icon-font-family: "icons-20-outline",
  	"missing-icons" !important;
  --icon-font-size: 1.25rem;
}
.elm-button[data-icon-after][data-size=small]::after {
  --icon-font-family: "icons-20-outline",
  	"missing-icons" !important;
  --icon-font-size: 1.25rem;
}
.elm-button.is-icon-text-replace[data-size=small] {
  width: 2.25rem;
}
.elm-button[data-size=large] {
  min-height: 3.25rem;
}
.elm-button[data-size=large]:not(.is-icon-text-replace) {
  font-size: 1.25rem;
}
.elm-button.is-icon-text-replace[data-size=large] {
  width: 3.25rem;
}
.elm-button:disabled {
  background-color: #d7dce1;
  color: #282d37;
  opacity: 0.5;
}

/* headline */
h1.elm-headline,
h2.elm-headline,
h3.elm-headline {
  font-family: "DB Screen Head", Helvetica, Arial, sans-serif;
}

.elm-headline {
  margin-bottom: 1rem;
  margin-top: 1.5rem;
}

h1.elm-headline,
h2.elm-headline,
h3.elm-headline {
  font-weight: 700;
}

h4.elm-headline {
  font-weight: 400;
  font-size: 1.125rem;
}

h1.elm-headline {
  font-size: 2.25rem;
}

h2.elm-headline {
  font-size: 1.5rem;
}

h3.elm-headline {
  font-size: 1.25rem;
}

/* link */
.elm-link {
  display: inline-block;
  border-radius: 0.25rem;
  text-underline-offset: 0.1875rem;
  text-underline-position: under;
}
@supports (text-underline-offset: 3px) {
  .elm-link {
    text-underline-position: auto;
  }
}
.elm-link:active {
  color: initial;
}
.elm-link[aria-disabled=true] {
  opacity: 0.4;
  pointer-events: none;
}
.elm-link[aria-disabled=true]::before {
  color: initial;
}
.elm-link[data-size=small]:not(.is-icon-text-replace) {
  font-size: 0.875rem;
}
.elm-link[rel][data-size=small]::before, .elm-link[data-icon][data-size=small]::before, .elm-link[data-icon-before][data-size=small]::before {
  --icon-font-family: "icons-20-outline",
  	"missing-icons" !important;
  --icon-font-size: 1.25rem;
}
.elm-link[data-icon-after][data-size=small]::after::after {
  --icon-font-family: "icons-20-outline",
  	"missing-icons" !important;
  --icon-font-size: 1.25rem;
}
.elm-link[rel=configuration]::before {
  --icon-font-family: "icons-24-outline",
  	"missing-icons" !important;
  --icon-font-size: 1.5rem;
  margin-inline-end: var(--icon-margin-after, 0.375rem);
  --icon-glyph: "⚙";
}
.elm-link[rel=messages]::before {
  --icon-font-family: "icons-24-outline",
  	"missing-icons" !important;
  --icon-font-size: 1.5rem;
  margin-inline-end: var(--icon-margin-after, 0.375rem);
  --icon-glyph: "💬";
}
.elm-link[rel=account]::before {
  --icon-font-family: "icons-24-outline",
  	"missing-icons" !important;
  --icon-font-size: 1.5rem;
  margin-inline-end: var(--icon-margin-after, 0.375rem);
  --icon-glyph: "🙂";
}
.elm-link[rel=configuration]::before, .elm-link[rel=messages]::before, .elm-link[rel=account]::before {
  display: inline-block;
}

/* image */
.elm-image.is-logo {
  height: auto;
  margin: 0.2rem 1rem 0.2rem 0;
  max-width: 200px;
}
.cmp-brand .elm-image.is-logo {
  width: 39px;
}

/* checkbox */
.elm-checkbox {
  appearance: none;
  background-color: rgba(255, 255, 255, 0.1);
  border: 0.0625rem solid #282d37;
  border-radius: 4px;
  color: #282d37;
  height: 1.5rem;
  width: 1.5rem;
  display: inline-flex;
  justify-content: center;
  align-content: center;
}
.elm-checkbox::before {
  --icon-font-family: "icons-24-outline",
  	"missing-icons" !important;
  --icon-font-size: 1.5rem;
  margin-inline-end: var(--icon-margin-after, 0.375rem);
  --icon-glyph: " ";
}
.elm-checkbox[type=checkbox] {
  vertical-align: top;
}
.elm-checkbox::before {
  --icon-font-size: 1.375rem;
  margin-right: 0;
}
.elm-checkbox:checked {
  background-color: #282d37;
}
.elm-checkbox:checked::before {
  content: "✓";
  color: #fff;
}
.elm-checkbox:not(:disabled):hover {
  background-color: rgba(40, 45, 55, 0.14);
}
.elm-checkbox:not(:disabled):checked:hover {
  background-color: #52575f;
}
.elm-checkbox:not(:disabled):active {
  background-color: rgba(40, 45, 55, 0.14);
}
.elm-checkbox:indeterminate:before {
  content: "−";
}
.elm-checkbox:disabled, .elm-checkbox:disabled + label {
  opacity: 0.5;
}
.elm-checkbox + label {
  margin-left: 0.25rem;
}

/* input */
.elm-select, .elm-textarea, .elm-input {
  border-radius: 4px;
  color: #282d37;
  caret-color: #ec0016;
  --formElement---borderTopColor: var(--formElement---borderColor, transparent);
  --formElement---borderRightColor: var(
  	--formElement---borderColor,
  	transparent
  );
  --formElement---borderBottomColor: var(
  	--formElement---borderColor,
  	rgba(40, 45, 55, 0.5)
  );
  --formElement---borderLeftColor: var(
  	--formElement---borderColor,
  	transparent
  );
}
.elm-select ~ .description, .elm-textarea ~ .description, .elm-input ~ .description {
  font-size: 0.875rem;
  color: #646973;
  padding: 0.375rem 0.625rem;
}

.elm-select[data-variant=semitransparent], :where(.elm-select:not([multiple])), .elm-textarea[data-variant=semitransparent], :where(.elm-textarea), :not(.cmp-sitesearch) > .elm-input[type=search], .elm-input[type=color][data-variant=semitransparent], .elm-input[type=date][data-variant=semitransparent], .elm-input[type=datetime-local][data-variant=semitransparent], .elm-input[type=email][data-variant=semitransparent], .elm-input[type=month][data-variant=semitransparent], .elm-input[type=number][data-variant=semitransparent], .elm-input[type=password][data-variant=semitransparent], :where(:not(.cmp-sitesearch)) > .elm-input[type=search][data-variant=semitransparent], .elm-input[type=tel][data-variant=semitransparent], .elm-input[type=text][data-variant=semitransparent], .elm-input[type=time][data-variant=semitransparent], .elm-input[type=url][data-variant=semitransparent], .elm-input[type=week][data-variant=semitransparent], :where(.elm-input[type=color], .elm-input[type=date], .elm-input[type=datetime-local], .elm-input[type=email], .elm-input[type=month], .elm-input[type=number], .elm-input[type=password], :where(:not(.cmp-sitesearch)) > .elm-input[type=search], .elm-input[type=tel], .elm-input[type=text], .elm-input[type=time], .elm-input[type=url], .elm-input[type=week]) {
  background-color: rgba(40, 45, 55, 0.05);
  border-top: 1px solid var(--formElement---borderTopColor);
  border-right: 1px solid var(--formElement---borderRightColor);
  border-bottom: 1px solid var(--formElement---borderBottomColor);
  border-left: 1px solid var(--formElement---borderLeftColor);
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
  --formElement---borderColor: rgba(40, 45, 55, 0.5);
}

.elm-select[data-variant=white], .elm-textarea[data-variant=white], .elm-input[type=color][data-variant=white], .elm-input[type=date][data-variant=white], .elm-input[type=datetime-local][data-variant=white], .elm-input[type=email][data-variant=white], .elm-input[type=month][data-variant=white], .elm-input[type=number][data-variant=white], .elm-input[type=password][data-variant=white], :where(:not(.cmp-sitesearch)) > .elm-input[type=search][data-variant=white], .elm-input[type=tel][data-variant=white], .elm-input[type=text][data-variant=white], .elm-input[type=time][data-variant=white], .elm-input[type=url][data-variant=white], .elm-input[type=week][data-variant=white] {
  background-color: #ffffff;
  border-top: 1px solid var(--formElement---borderTopColor);
  border-right: 1px solid var(--formElement---borderRightColor);
  border-bottom: 1px solid var(--formElement---borderBottomColor);
  border-left: 1px solid var(--formElement---borderLeftColor);
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
  --formElement---borderColor: rgba(40, 45, 55, 0.5);
}

.elm-select[data-variant=solid], .elm-textarea[data-variant=solid], .elm-input[type=color][data-variant=solid], .elm-input[type=date][data-variant=solid], .elm-input[type=datetime-local][data-variant=solid], .elm-input[type=email][data-variant=solid], .elm-input[type=month][data-variant=solid], .elm-input[type=number][data-variant=solid], .elm-input[type=password][data-variant=solid], :where(:not(.cmp-sitesearch)) > .elm-input[type=search][data-variant=solid], .elm-input[type=tel][data-variant=solid], .elm-input[type=text][data-variant=solid], .elm-input[type=time][data-variant=solid], .elm-input[type=url][data-variant=solid], .elm-input[type=week][data-variant=solid] {
  background-color: #f0f3f5;
  border-top: 1px solid var(--formElement---borderTopColor);
  border-right: 1px solid var(--formElement---borderRightColor);
  border-bottom: 1px solid var(--formElement---borderBottomColor);
  border-left: 1px solid var(--formElement---borderLeftColor);
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
  --formElement---borderColor: rgba(40, 45, 55, 0.5);
}

.elm-select[data-variant=outline], .elm-textarea[data-variant=outline], .elm-input[type=color][data-variant=outline], .elm-input[type=date][data-variant=outline], .elm-input[type=datetime-local][data-variant=outline], .elm-input[type=email][data-variant=outline], .elm-input[type=month][data-variant=outline], .elm-input[type=number][data-variant=outline], .elm-input[type=password][data-variant=outline], :where(:not(.cmp-sitesearch)) > .elm-input[type=search][data-variant=outline], .elm-input[type=tel][data-variant=outline], .elm-input[type=text][data-variant=outline], .elm-input[type=time][data-variant=outline], .elm-input[type=url][data-variant=outline], .elm-input[type=week][data-variant=outline] {
  background-color: transparent;
  border-top: 1px solid var(--formElement---borderTopColor);
  border-right: 1px solid var(--formElement---borderRightColor);
  border-bottom: 1px solid var(--formElement---borderBottomColor);
  border-left: 1px solid var(--formElement---borderLeftColor);
  --formElement---borderColor: rgba(40, 45, 55, 0.25);
}

.elm-input {
  font-size: 1rem;
  line-height: 1.5rem;
  color: #282d37;
  width: 100%;
  max-width: 100%;
  height: 2.75rem;
  padding: 0.5rem 1rem;
}
.elm-input:not(:placeholder-shown) {
  padding-top: 1rem;
  padding-bottom: 0;
}
.elm-input:focus:not(:disabled), .elm-input:hover:not(:disabled) {
  --formElement---borderColor: #878c96;
}
.elm-input:disabled, .elm-input:disabled + .elm-label, fieldset:disabled .elm-input, fieldset:disabled .elm-input + .elm-label {
  opacity: 0.4;
}
.elm-input[type=color], .elm-input[type=date], .elm-input[type=datetime-local], .elm-input[type=email], .elm-input[type=month], .elm-input[type=number], .elm-input[type=password], :where(:not(.cmp-sitesearch)) > .elm-input[type=search], .elm-input[type=tel], .elm-input[type=text], .elm-input[type=time], .elm-input[type=url], .elm-input[type=week] {
  margin-top: 1rem;
}
.elm-input[type=color]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=time]):not([type=week]) + .elm-label, .elm-input[type=date]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=time]):not([type=week]) + .elm-label, .elm-input[type=datetime-local]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=time]):not([type=week]) + .elm-label, .elm-input[type=email]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=time]):not([type=week]) + .elm-label, .elm-input[type=month]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=time]):not([type=week]) + .elm-label, .elm-input[type=number]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=time]):not([type=week]) + .elm-label, .elm-input[type=password]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=time]):not([type=week]) + .elm-label, :where(:not(.cmp-sitesearch)) > .elm-input[type=search]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=time]):not([type=week]) + .elm-label, .elm-input[type=tel]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=time]):not([type=week]) + .elm-label, .elm-input[type=text]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=time]):not([type=week]) + .elm-label, .elm-input[type=time]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=time]):not([type=week]) + .elm-label, .elm-input[type=url]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=time]):not([type=week]) + .elm-label, .elm-input[type=week]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=time]):not([type=week]) + .elm-label {
  transition: opacity, transform 250ms;
}
@media (prefers-reduced-motion: reduce) {
  .elm-input[type=color]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=time]):not([type=week]) + .elm-label, .elm-input[type=date]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=time]):not([type=week]) + .elm-label, .elm-input[type=datetime-local]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=time]):not([type=week]) + .elm-label, .elm-input[type=email]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=time]):not([type=week]) + .elm-label, .elm-input[type=month]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=time]):not([type=week]) + .elm-label, .elm-input[type=number]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=time]):not([type=week]) + .elm-label, .elm-input[type=password]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=time]):not([type=week]) + .elm-label, :where(:not(.cmp-sitesearch)) > .elm-input[type=search]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=time]):not([type=week]) + .elm-label, .elm-input[type=tel]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=time]):not([type=week]) + .elm-label, .elm-input[type=text]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=time]):not([type=week]) + .elm-label, .elm-input[type=time]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=time]):not([type=week]) + .elm-label, .elm-input[type=url]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=time]):not([type=week]) + .elm-label, .elm-input[type=week]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=time]):not([type=week]) + .elm-label {
    transition-duration: 0.01s !important;
  }
}
.elm-input[type=color] + .elm-label, .elm-input[type=date] + .elm-label, .elm-input[type=datetime-local] + .elm-label, .elm-input[type=email] + .elm-label, .elm-input[type=month] + .elm-label, .elm-input[type=number] + .elm-label, .elm-input[type=password] + .elm-label, :where(:not(.cmp-sitesearch)) > .elm-input[type=search] + .elm-label, .elm-input[type=tel] + .elm-label, .elm-input[type=text] + .elm-label, .elm-input[type=time] + .elm-label, .elm-input[type=url] + .elm-label, .elm-input[type=week] + .elm-label {
  transform: translate(1rem, -2.5625rem);
  font-size: 0.75rem;
  display: block;
  color: #646973;
  margin-bottom: -1.125rem;
}
.elm-input[type=color] + .elm-label + .description, .elm-input[type=date] + .elm-label + .description, .elm-input[type=datetime-local] + .elm-label + .description, .elm-input[type=email] + .elm-label + .description, .elm-input[type=month] + .elm-label + .description, .elm-input[type=number] + .elm-label + .description, .elm-input[type=password] + .elm-label + .description, :where(:not(.cmp-sitesearch)) > .elm-input[type=search] + .elm-label + .description, .elm-input[type=tel] + .elm-label + .description, .elm-input[type=text] + .elm-label + .description, .elm-input[type=time] + .elm-label + .description, .elm-input[type=url] + .elm-label + .description, .elm-input[type=week] + .elm-label + .description {
  margin-top: 0;
}
.elm-input[type=color][placeholder]:placeholder-shown + label, .elm-input[type=date][placeholder]:placeholder-shown + label, .elm-input[type=datetime-local][placeholder]:placeholder-shown + label, .elm-input[type=email][placeholder]:placeholder-shown + label, .elm-input[type=month][placeholder]:placeholder-shown + label, .elm-input[type=number][placeholder]:placeholder-shown + label, .elm-input[type=password][placeholder]:placeholder-shown + label, :where(:not(.cmp-sitesearch)) > .elm-input[type=search][placeholder]:placeholder-shown + label, .elm-input[type=tel][placeholder]:placeholder-shown + label, .elm-input[type=text][placeholder]:placeholder-shown + label, .elm-input[type=time][placeholder]:placeholder-shown + label, .elm-input[type=url][placeholder]:placeholder-shown + label, .elm-input[type=week][placeholder]:placeholder-shown + label {
  opacity: 0;
}
.elm-input[type=color]::placeholder, .elm-input[type=date]::placeholder, .elm-input[type=datetime-local]::placeholder, .elm-input[type=email]::placeholder, .elm-input[type=month]::placeholder, .elm-input[type=number]::placeholder, .elm-input[type=password]::placeholder, :where(:not(.cmp-sitesearch)) > .elm-input[type=search]::placeholder, .elm-input[type=tel]::placeholder, .elm-input[type=text]::placeholder, .elm-input[type=time]::placeholder, .elm-input[type=url]::placeholder, .elm-input[type=week]::placeholder {
  color: #646973;
}
.elm-input[type=color]:required:valid:not([aria-invalid]), .elm-input[type=date]:required:valid:not([aria-invalid]), .elm-input[type=datetime-local]:required:valid:not([aria-invalid]), .elm-input[type=email]:required:valid:not([aria-invalid]), .elm-input[type=month]:required:valid:not([aria-invalid]), .elm-input[type=number]:required:valid:not([aria-invalid]), .elm-input[type=password]:required:valid:not([aria-invalid]), :where(:not(.cmp-sitesearch)) > .elm-input[type=search]:required:valid:not([aria-invalid]), .elm-input[type=tel]:required:valid:not([aria-invalid]), .elm-input[type=text]:required:valid:not([aria-invalid]), .elm-input[type=time]:required:valid:not([aria-invalid]), .elm-input[type=url]:required:valid:not([aria-invalid]), .elm-input[type=week]:required:valid:not([aria-invalid]) {
  --formElement---borderColor: #2a7230;
}
.elm-input[type=color]:required:invalid:not(:placeholder-shown), .elm-input[type=date]:required:invalid:not(:placeholder-shown), .elm-input[type=datetime-local]:required:invalid:not(:placeholder-shown), .elm-input[type=email]:required:invalid:not(:placeholder-shown), .elm-input[type=month]:required:invalid:not(:placeholder-shown), .elm-input[type=number]:required:invalid:not(:placeholder-shown), .elm-input[type=password]:required:invalid:not(:placeholder-shown), :where(:not(.cmp-sitesearch)) > .elm-input[type=search]:required:invalid:not(:placeholder-shown), .elm-input[type=tel]:required:invalid:not(:placeholder-shown), .elm-input[type=text]:required:invalid:not(:placeholder-shown), .elm-input[type=time]:required:invalid:not(:placeholder-shown), .elm-input[type=url]:required:invalid:not(:placeholder-shown), .elm-input[type=week]:required:invalid:not(:placeholder-shown) {
  --formElement---borderColor: #ec0016;
}
.elm-input[type=color][aria-invalid=true], .elm-input[type=date][aria-invalid=true], .elm-input[type=datetime-local][aria-invalid=true], .elm-input[type=email][aria-invalid=true], .elm-input[type=month][aria-invalid=true], .elm-input[type=number][aria-invalid=true], .elm-input[type=password][aria-invalid=true], :where(:not(.cmp-sitesearch)) > .elm-input[type=search][aria-invalid=true], .elm-input[type=tel][aria-invalid=true], .elm-input[type=text][aria-invalid=true], .elm-input[type=time][aria-invalid=true], .elm-input[type=url][aria-invalid=true], .elm-input[type=week][aria-invalid=true] {
  --formElement---borderColor: #ec0016;
}
.elm-input[type=search] {
  background: var(--db_ic_search_24, url('db_ic_search_24.eabb6d7f040bdf87.svg')) no-repeat 0.375rem 0.75rem;
  background-size: 1.1875rem;
  padding-left: 2rem;
}
:not(.cmp-sitesearch) > .elm-input[type=search] + .elm-label {
  margin-left: 1rem;
}

/* progress */
.elm-progress progress {
  --progress-value--backgroundColor: #ec0016;
}

.elm-progress progress {
  appearance: none;
  background-color: #fcfdfd;
  border: none;
  height: 0.25rem;
  vertical-align: middle;
}
.elm-progress progress::-webkit-progress-bar {
  background-color: #fcfdfd;
}
.elm-progress progress::-webkit-progress-value {
  background-color: var(--progress-value--backgroundColor);
}
.elm-progress progress::-moz-progress-bar {
  background-color: var(--progress-value--backgroundColor);
}
.elm-progress progress::-ms-fill {
  background-color: var(--progress-value--backgroundColor);
  border: none;
}
.elm-progress progress:not([style^="--progress-conic:"])::-webkit-progress-value {
  border-radius: 12px;
}
.elm-progress progress:not([style^="--progress-conic:"])::-moz-progress-bar {
  border-radius: 12px;
}
.elm-progress progress:not([style^="--progress-conic:"])::-ms-fill {
  border-radius: 12px;
}
.elm-progress progress:indeterminate {
  animation-duration: 2.1s;
  animation-iteration-count: infinite;
  animation-name: elmProgressMoveIndeterminate;
  animation-timing-function: linear;
  animation-timing-function: cubic-bezier(0.65, 0.815, 0.735, 0.395);
  background-color: #fcfdfd;
  background-image: linear-gradient(to right, #ec0016 100%, #fcfdfd 100%);
  background-position: top left;
  background-repeat: no-repeat;
  background-size: 150% 150%;
}
@media (prefers-reduced-motion: reduce) {
  .elm-progress progress:indeterminate {
    animation-duration: 15s;
  }
}
.elm-progress progress:indeterminate::-webkit-progress-bar {
  background-color: transparent;
}
.elm-progress progress:indeterminate::-moz-progress-bar {
  background-color: transparent;
}
@supports (background: conic-gradient(#000, #fff)) {
  .elm-progress progress[style^="--progress-conic:"] {
    --fill: calc(var(--progress-conic) * 1%);
    background: conic-gradient(var(--progress-value--backgroundColor) var(--fill), #fff 0);
    border: 0;
    border-radius: 50%;
    height: 4.6875rem;
    width: 4.6875rem;
  }
  .elm-progress progress[style^="--progress-conic:"]::-webkit-progress-inner-element, .elm-progress progress[style^="--progress-conic:"]::-webkit-progress-bar, .elm-progress progress[style^="--progress-conic:"]::-webkit-progress-value {
    display: none;
  }
  .elm-progress progress[style^="--progress-conic:"]::-moz-progress-bar {
    background: none;
  }
  .elm-progress progress[style^="--progress-conic:"] + output {
    align-items: center;
    background: #fdfdfd;
    border-radius: 50%;
    display: block;
    display: flex;
    height: 3.4375rem;
    justify-content: center;
    position: absolute;
    transform: translate(0.625rem, -4.0625rem);
    width: 3.4375rem;
  }
}

@keyframes elmProgressMoveIndeterminate {
  0% {
    background-position: -35% 0;
    background-size: 35% 100%;
  }
  60% {
    background-position: 101% 0;
    background-size: 65% 100%;
  }
  100% {
    background-position: 101% 0;
    background-size: 1% 100%;
  }
}
/* radio */
.elm-radio {
  appearance: none;
  display: inline-block;
  width: 1.25em;
  height: 1.25em;
  border-radius: 1em;
  border: 0.0625rem solid #282d37;
  background-color: rgba(255, 255, 255, 0.1);
  transition: border-width 0.5s ease;
  font-size: 1rem;
}
.elm-radio[type=radio] {
  vertical-align: sub;
}
@media (prefers-reduced-motion: reduce) {
  .elm-radio {
    transition-duration: 0.01s !important;
  }
}
.elm-radio:hover {
  background-color: rgba(40, 45, 55, 0.14);
}
.elm-radio:active {
  background-color: rgba(40, 45, 55, 0.3);
}
.elm-radio:checked, .elm-radio:invalid, .elm-radio[aria-invalid=true] {
  border-width: 0.375em;
}
.elm-radio:invalid, .elm-radio[aria-invalid=true] {
  border-color: #c13e34;
}
.elm-radio + label {
  margin-left: 0.25rem;
}
.elm-radio:disabled, .elm-radio:disabled + label {
  opacity: 0.5;
}

/* textarea */
.elm-select, .elm-textarea, .elm-input {
  border-radius: 4px;
  color: #282d37;
  caret-color: #ec0016;
  --formElement---borderTopColor: var(--formElement---borderColor, transparent);
  --formElement---borderRightColor: var(
  	--formElement---borderColor,
  	transparent
  );
  --formElement---borderBottomColor: var(
  	--formElement---borderColor,
  	rgba(40, 45, 55, 0.5)
  );
  --formElement---borderLeftColor: var(
  	--formElement---borderColor,
  	transparent
  );
}
.elm-select ~ .description, .elm-textarea ~ .description, .elm-input ~ .description {
  font-size: 0.875rem;
  color: #646973;
  padding: 0.375rem 0.625rem;
}

.elm-select[data-variant=semitransparent], :where(.elm-select:not([multiple])), .elm-textarea[data-variant=semitransparent], :where(.elm-textarea), :where(.elm-input[type=color], .elm-input[type=date], .elm-input[type=datetime-local], .elm-input[type=email], .elm-input[type=month], .elm-input[type=number], .elm-input[type=password], :where(:not(.cmp-sitesearch)) > .elm-input[type=search], .elm-input[type=tel], .elm-input[type=text], .elm-input[type=time], .elm-input[type=url], .elm-input[type=week]), .elm-input[type=color][data-variant=semitransparent], .elm-input[type=date][data-variant=semitransparent], .elm-input[type=datetime-local][data-variant=semitransparent], .elm-input[type=email][data-variant=semitransparent], .elm-input[type=month][data-variant=semitransparent], .elm-input[type=number][data-variant=semitransparent], .elm-input[type=password][data-variant=semitransparent], :where(:not(.cmp-sitesearch)) > .elm-input[type=search][data-variant=semitransparent], .elm-input[type=tel][data-variant=semitransparent], .elm-input[type=text][data-variant=semitransparent], .elm-input[type=time][data-variant=semitransparent], .elm-input[type=url][data-variant=semitransparent], .elm-input[type=week][data-variant=semitransparent], :not(.cmp-sitesearch) > .elm-input[type=search] {
  background-color: rgba(40, 45, 55, 0.05);
  border-top: 1px solid var(--formElement---borderTopColor);
  border-right: 1px solid var(--formElement---borderRightColor);
  border-bottom: 1px solid var(--formElement---borderBottomColor);
  border-left: 1px solid var(--formElement---borderLeftColor);
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
  --formElement---borderColor: rgba(40, 45, 55, 0.5);
}

.elm-select[data-variant=white], .elm-textarea[data-variant=white], .elm-input[type=color][data-variant=white], .elm-input[type=date][data-variant=white], .elm-input[type=datetime-local][data-variant=white], .elm-input[type=email][data-variant=white], .elm-input[type=month][data-variant=white], .elm-input[type=number][data-variant=white], .elm-input[type=password][data-variant=white], :where(:not(.cmp-sitesearch)) > .elm-input[type=search][data-variant=white], .elm-input[type=tel][data-variant=white], .elm-input[type=text][data-variant=white], .elm-input[type=time][data-variant=white], .elm-input[type=url][data-variant=white], .elm-input[type=week][data-variant=white] {
  background-color: #ffffff;
  border-top: 1px solid var(--formElement---borderTopColor);
  border-right: 1px solid var(--formElement---borderRightColor);
  border-bottom: 1px solid var(--formElement---borderBottomColor);
  border-left: 1px solid var(--formElement---borderLeftColor);
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
  --formElement---borderColor: rgba(40, 45, 55, 0.5);
}

.elm-select[data-variant=solid], .elm-textarea[data-variant=solid], .elm-input[type=color][data-variant=solid], .elm-input[type=date][data-variant=solid], .elm-input[type=datetime-local][data-variant=solid], .elm-input[type=email][data-variant=solid], .elm-input[type=month][data-variant=solid], .elm-input[type=number][data-variant=solid], .elm-input[type=password][data-variant=solid], :where(:not(.cmp-sitesearch)) > .elm-input[type=search][data-variant=solid], .elm-input[type=tel][data-variant=solid], .elm-input[type=text][data-variant=solid], .elm-input[type=time][data-variant=solid], .elm-input[type=url][data-variant=solid], .elm-input[type=week][data-variant=solid] {
  background-color: #f0f3f5;
  border-top: 1px solid var(--formElement---borderTopColor);
  border-right: 1px solid var(--formElement---borderRightColor);
  border-bottom: 1px solid var(--formElement---borderBottomColor);
  border-left: 1px solid var(--formElement---borderLeftColor);
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
  --formElement---borderColor: rgba(40, 45, 55, 0.5);
}

.elm-select[data-variant=outline], .elm-textarea[data-variant=outline], .elm-input[type=color][data-variant=outline], .elm-input[type=date][data-variant=outline], .elm-input[type=datetime-local][data-variant=outline], .elm-input[type=email][data-variant=outline], .elm-input[type=month][data-variant=outline], .elm-input[type=number][data-variant=outline], .elm-input[type=password][data-variant=outline], :where(:not(.cmp-sitesearch)) > .elm-input[type=search][data-variant=outline], .elm-input[type=tel][data-variant=outline], .elm-input[type=text][data-variant=outline], .elm-input[type=time][data-variant=outline], .elm-input[type=url][data-variant=outline], .elm-input[type=week][data-variant=outline] {
  background-color: transparent;
  border-top: 1px solid var(--formElement---borderTopColor);
  border-right: 1px solid var(--formElement---borderRightColor);
  border-bottom: 1px solid var(--formElement---borderBottomColor);
  border-left: 1px solid var(--formElement---borderLeftColor);
  --formElement---borderColor: rgba(40, 45, 55, 0.25);
}

.elm-textarea {
  font-size: 1rem;
  line-height: 1.5rem;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 2.75rem;
  padding: 0.375rem 0.625rem;
  resize: vertical;
}
.elm-textarea:not([rows]) {
  max-height: 37.5rem;
  min-height: 7.5rem;
}
.elm-textarea[rows] {
  height: initial;
}
.elm-textarea:focus:not(:disabled), .elm-textarea:hover:not(:disabled) {
  --formElement---borderColor: #878c96;
}
.elm-textarea:disabled, .elm-textarea:disabled + .elm-label, fieldset:disabled .elm-textarea, fieldset:disabled .elm-textarea + .elm-label {
  opacity: 0.4;
}
.elm-textarea:required:valid:not([aria-invalid]), .elm-textarea:focus:valid:not([aria-invalid]) {
  --formElement---borderColor: #2a7230;
}
.elm-textarea:required:invalid:not(:placeholder-shown), .elm-textarea:focus:invalid:not(:placeholder-shown) {
  --formElement---borderColor: #ec0016;
}
.elm-textarea:required:invalid:not(:placeholder-shown) ~ .description, .elm-textarea:focus:invalid:not(:placeholder-shown) ~ .description {
  color: #ec0016;
}
.elm-textarea[aria-invalid=true] {
  --formElement---borderColor: #ec0016;
}
.elm-textarea + output {
  float: right;
  margin-top: 0.75rem;
}
.elm-textarea + output, .elm-textarea + output + .description {
  font-size: 0.875rem;
  color: #646973;
  padding: 0.375rem 0.625rem;
}
/* select */
.elm-select, .elm-input, .elm-textarea {
  border-radius: 4px;
  color: #282d37;
  caret-color: #ec0016;
  --formElement---borderTopColor: var(--formElement---borderColor, transparent);
  --formElement---borderRightColor: var(
  	--formElement---borderColor,
  	transparent
  );
  --formElement---borderBottomColor: var(
  	--formElement---borderColor,
  	rgba(40, 45, 55, 0.5)
  );
  --formElement---borderLeftColor: var(
  	--formElement---borderColor,
  	transparent
  );
}
.elm-select ~ .description, .elm-input ~ .description, .elm-textarea ~ .description {
  font-size: 0.875rem;
  color: #646973;
  padding: 0.375rem 0.625rem;
}

.elm-select[data-variant=semitransparent], :where(.elm-select:not([multiple])), :where(.elm-input[type=color], .elm-input[type=date], .elm-input[type=datetime-local], .elm-input[type=email], .elm-input[type=month], .elm-input[type=number], .elm-input[type=password], :where(:not(.cmp-sitesearch)) > .elm-input[type=search], .elm-input[type=tel], .elm-input[type=text], .elm-input[type=time], .elm-input[type=url], .elm-input[type=week]), .elm-input[type=color][data-variant=semitransparent], .elm-input[type=date][data-variant=semitransparent], .elm-input[type=datetime-local][data-variant=semitransparent], .elm-input[type=email][data-variant=semitransparent], .elm-input[type=month][data-variant=semitransparent], .elm-input[type=number][data-variant=semitransparent], .elm-input[type=password][data-variant=semitransparent], :where(:not(.cmp-sitesearch)) > .elm-input[type=search][data-variant=semitransparent], .elm-input[type=tel][data-variant=semitransparent], .elm-input[type=text][data-variant=semitransparent], .elm-input[type=time][data-variant=semitransparent], .elm-input[type=url][data-variant=semitransparent], .elm-input[type=week][data-variant=semitransparent], :not(.cmp-sitesearch) > .elm-input[type=search], :where(.elm-textarea), .elm-textarea[data-variant=semitransparent] {
  background-color: rgba(40, 45, 55, 0.05);
  border-top: 1px solid var(--formElement---borderTopColor);
  border-right: 1px solid var(--formElement---borderRightColor);
  border-bottom: 1px solid var(--formElement---borderBottomColor);
  border-left: 1px solid var(--formElement---borderLeftColor);
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
  --formElement---borderColor: rgba(40, 45, 55, 0.5);
}

.elm-select[data-variant=white], .elm-input[type=color][data-variant=white], .elm-input[type=date][data-variant=white], .elm-input[type=datetime-local][data-variant=white], .elm-input[type=email][data-variant=white], .elm-input[type=month][data-variant=white], .elm-input[type=number][data-variant=white], .elm-input[type=password][data-variant=white], :where(:not(.cmp-sitesearch)) > .elm-input[type=search][data-variant=white], .elm-input[type=tel][data-variant=white], .elm-input[type=text][data-variant=white], .elm-input[type=time][data-variant=white], .elm-input[type=url][data-variant=white], .elm-input[type=week][data-variant=white], .elm-textarea[data-variant=white] {
  background-color: #ffffff;
  border-top: 1px solid var(--formElement---borderTopColor);
  border-right: 1px solid var(--formElement---borderRightColor);
  border-bottom: 1px solid var(--formElement---borderBottomColor);
  border-left: 1px solid var(--formElement---borderLeftColor);
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
  --formElement---borderColor: rgba(40, 45, 55, 0.5);
}

.elm-select[data-variant=solid], .elm-input[type=color][data-variant=solid], .elm-input[type=date][data-variant=solid], .elm-input[type=datetime-local][data-variant=solid], .elm-input[type=email][data-variant=solid], .elm-input[type=month][data-variant=solid], .elm-input[type=number][data-variant=solid], .elm-input[type=password][data-variant=solid], :where(:not(.cmp-sitesearch)) > .elm-input[type=search][data-variant=solid], .elm-input[type=tel][data-variant=solid], .elm-input[type=text][data-variant=solid], .elm-input[type=time][data-variant=solid], .elm-input[type=url][data-variant=solid], .elm-input[type=week][data-variant=solid], .elm-textarea[data-variant=solid] {
  background-color: #f0f3f5;
  border-top: 1px solid var(--formElement---borderTopColor);
  border-right: 1px solid var(--formElement---borderRightColor);
  border-bottom: 1px solid var(--formElement---borderBottomColor);
  border-left: 1px solid var(--formElement---borderLeftColor);
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
  --formElement---borderColor: rgba(40, 45, 55, 0.5);
}

.elm-select[data-variant=outline], .elm-input[type=color][data-variant=outline], .elm-input[type=date][data-variant=outline], .elm-input[type=datetime-local][data-variant=outline], .elm-input[type=email][data-variant=outline], .elm-input[type=month][data-variant=outline], .elm-input[type=number][data-variant=outline], .elm-input[type=password][data-variant=outline], :where(:not(.cmp-sitesearch)) > .elm-input[type=search][data-variant=outline], .elm-input[type=tel][data-variant=outline], .elm-input[type=text][data-variant=outline], .elm-input[type=time][data-variant=outline], .elm-input[type=url][data-variant=outline], .elm-input[type=week][data-variant=outline], .elm-textarea[data-variant=outline] {
  background-color: transparent;
  border-top: 1px solid var(--formElement---borderTopColor);
  border-right: 1px solid var(--formElement---borderRightColor);
  border-bottom: 1px solid var(--formElement---borderBottomColor);
  border-left: 1px solid var(--formElement---borderLeftColor);
  --formElement---borderColor: rgba(40, 45, 55, 0.25);
}

.elm-select {
  appearance: none;
  font-size: 1rem;
}
.elm-select::-ms-expand {
  display: none;
}
.elm-select:not([multiple]) {
  background-image: var(--db_ic_expand_more_20, url('db_ic_expand_more_20.af0eec50a50c9d09.svg'));
  background-position: right 0.6875rem center;
  background-repeat: no-repeat;
  height: 2.75rem;
  padding: 1.25rem 2.625rem 0 1rem;
}
.elm-select:not([multiple]) + .elm-label {
  pointer-events: none;
  position: absolute;
  transition: opacity, transform 250ms;
}
@media (prefers-reduced-motion: reduce) {
  .elm-select:not([multiple]) + .elm-label {
    transition-duration: 0.01s !important;
  }
}
.elm-select:not([multiple]):required:invalid + .elm-label, .elm-select:not([multiple])[aria-invalid=true] + .elm-label {
  color: #646973;
  font-size: 1rem;
  transform: translate(1rem, -2.1875rem);
}
.elm-select + .elm-label {
  display: block;
  font-size: 0.75rem;
  margin-bottom: -1.125rem;
  transform: translate(1rem, -2.5625rem);
  transition: opacity, transform 150ms;
}
@media (prefers-reduced-motion: reduce) {
  .elm-select + .elm-label {
    transition-duration: 0.01s !important;
  }
}
.elm-select[multiple] {
  margin-top: 1rem;
}
.elm-select[multiple] + .elm-label {
  transform: translate(1rem, -6.25rem);
}
.elm-select optgroup {
  background-color: transparent;
  font-style: normal;
}
.elm-select option:not(:disabled):hover {
  background-color: #d7dce1;
}
.elm-select option:checked {
  background-color: #d7dce1;
}

/* chip */
.elm-chip + label {
  align-items: center;
  border: solid 1px #878c96;
  border-radius: 0.25rem;
  display: inline-flex;
  font-size: 0.875rem;
  height: 1.75rem;
  justify-content: center;
  padding-left: 0.4375rem;
  padding-right: 0.4375rem;
  white-space: nowrap;
  text-decoration: none;
  text-transform: capitalize;
}
.elm-chip + label[data-icon]::before, .elm-chip + label[data-icon-before]::before {
  --icon-font-family: "icons-20-outline",
  	"missing-icons" !important;
  --icon-font-size: 1.25rem;
}
.elm-chip + label[data-icon-after]::after {
  --icon-font-family: "icons-20-outline",
  	"missing-icons" !important;
  --icon-font-size: 1.25rem;
}
.elm-chip:not(:disabled, :checked) + label:hover {
  background-color: rgba(40, 45, 55, 0.03);
}
.elm-chip:checked + label {
  border-color: transparent;
  background-color: #282d37;
  color: #ffffff;
}
.elm-chip:checked + label[data-variant=warning] {
  background-color: #f75f00;
}
.elm-chip:checked + label[data-variant=error] {
  background-color: #ec0016;
}
.elm-chip:checked + label[data-variant=informative] {
  background-color: #0087b9;
}
.elm-chip:checked + label[data-variant=success] {
  background-color: #508b1b;
}
.elm-chip:checked + label[data-variant=poi-essen-trinken] {
  background-color: #f39200;
  color: #282d37;
}
.elm-chip:checked + label[data-variant=poi-einkaufen] {
  background-color: #814997;
}
.elm-chip:checked + label[data-variant=poi-gesundheit] {
  background-color: #a9455d;
}
.elm-chip:checked + label[data-variant=poi-kunst-kultur] {
  background-color: #e93e8f;
}
.elm-chip:checked + label[data-variant=poi-wissenswertes] {
  background-color: #858379;
}
.elm-chip:checked + label[data-variant=poi-freizeit] {
  background-color: #408335;
}
.elm-chip:checked + label[data-variant=poi-zivile-rel-einrichtungen] {
  background-color: #00a099;
}
.elm-chip:checked + label[data-variant=poi-dienstleistungen] {
  background-color: #309fd1;
  color: #282d37;
}
.elm-chip:checked + label[data-variant=poi-db-services-einrichtung] {
  background-color: #0c3992;
}
.elm-chip:checked + label[data-variant=poi-wegeleitung] {
  background-color: #3c414b;
}
.elm-chip:disabled + label {
  opacity: 0.5;
}
.elm-chip[type=checkbox], .elm-chip[type=radio] {
  display: none;
}

/* tag */
.elm-tag {
  align-items: center;
  background-color: #282d37;
  border-radius: 0.25rem;
  color: #ffffff;
  display: inline-flex;
  font-size: 0.75rem;
  font-weight: initial;
  height: 1.5rem;
  justify-content: center;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  white-space: nowrap;
  text-transform: capitalize;
}
.elm-tag[data-size=small] {
  height: 1.25rem;
}
.elm-tag[data-variant=warning] {
  background-color: #cc4e00;
}
.elm-tag[data-variant=error] {
  background-color: #db0014;
}
.elm-tag[data-variant=informative] {
  background-color: #006a96;
}
.elm-tag[data-variant=success] {
  background-color: #467a18;
}
.elm-tag[data-variant=track] {
  font-weight: 700;
  background-color: #0a1e6e;
}
.elm-tag[data-variant=poi-essen-trinken] {
  background-color: #f39200;
  color: #282d37;
}
.elm-tag[data-variant=poi-einkaufen] {
  background-color: #814997;
}
.elm-tag[data-variant=poi-gesundheit] {
  background-color: #a9455d;
}
.elm-tag[data-variant=poi-kunst-kultur] {
  background-color: #e93e8f;
}
.elm-tag[data-variant=poi-wissenswertes] {
  background-color: #858379;
}
.elm-tag[data-variant=poi-freizeit] {
  background-color: #408335;
}
.elm-tag[data-variant=poi-zivile-rel-einrichtungen] {
  background-color: #00a099;
}
.elm-tag[data-variant=poi-dienstleistungen] {
  background-color: #309fd1;
  color: #282d37;
}
.elm-tag[data-variant=poi-db-services-einrichtung] {
  background-color: #0c3992;
}
.elm-tag[data-variant=poi-wegeleitung] {
  background-color: #3c414b;
}
.elm-tag[data-icon]::before, .elm-tag[data-icon-before]::before {
  --icon-font-family: "icons-20-outline",
  	"missing-icons" !important;
  --icon-font-size: 1.25rem;
}
.elm-tag[data-icon]::before, .elm-tag[data-icon-before]::before {
  --icon-margin-after: 0.125rem;
}
.elm-tag[data-icon-after]::after {
  --icon-font-family: "icons-20-outline",
  	"missing-icons" !important;
  --icon-font-size: 1.25rem;
}
.elm-tag[data-icon-after]::after {
  --icon-margin-before: 0.125rem;
}

/* toggle */
.elm-toggle {
  appearance: none;
  width: 3rem;
  height: 1.5rem;
  display: inline-block;
  position: relative;
  border-radius: 0.75rem;
  border: none;
  background-color: #646973;
  transition: background-color 0.3s ease;
}
@media (prefers-reduced-motion: reduce) {
  .elm-toggle {
    transition-duration: 0.01s !important;
  }
}
.elm-toggle + .elm-label {
  vertical-align: top;
}
.elm-toggle::before {
  content: "";
  display: block;
  position: absolute;
  left: 0.125rem;
  top: 0.125rem;
  width: 1.25rem;
  height: 1.25rem;
  background-color: #ffffff;
  border-radius: 0.625rem;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.05), 0 1px 5px 0 rgba(0, 0, 0, 0.15);
  transition: border-color, left 0.3s cubic-bezier(0.3, 1.5, 0.7, 1);
}
@media (prefers-reduced-motion: reduce) {
  .elm-toggle::before {
    transition-duration: 0.01s !important;
  }
}
.elm-toggle:hover::before {
  background-color: #f7f7f7;
}
.elm-toggle:active::before {
  width: 1.5rem;
  transition: width 0.3s cubic-bezier(0.3, 1.5, 0.7, 1);
}
@media (prefers-reduced-motion: reduce) {
  .elm-toggle:active::before {
    transition-duration: 0.01s !important;
  }
}
.elm-toggle:checked {
  background-color: #131821;
}
.elm-toggle:checked::before {
  left: unset;
  right: 0.125rem;
}
.elm-toggle:disabled, .elm-toggle:disabled + label {
  opacity: 0.5;
}

/* youtube */
.elm-youtube {
  height: 19.6875rem;
  width: 35rem;
}

/* breadcrumb */
.cmp-breadcrumb {
  color: #646973;
  font-size: 0.75rem;
}
.cmp-breadcrumb a {
  color: inherit;
}
.cmp-breadcrumb ol {
  display: flex;
}
.cmp-breadcrumb ol > * {
  align-items: center;
  display: inline-flex;
  margin-right: 0.1875rem;
}
.cmp-breadcrumb ol > * [aria-current=location],
.cmp-breadcrumb ol > * [aria-current=page] {
  font-size: 0.875rem;
  color: #282d37;
}
.cmp-breadcrumb ol > * .elm-link {
  text-decoration: none;
  padding: 0.0625rem 0.1875rem 0.0625rem 0.25rem;
  border-radius: 0.25rem;
}
.cmp-breadcrumb ol > * .elm-link:hover {
  background-color: rgba(40, 45, 55, 0.03);
}
.cmp-breadcrumb ol > * .elm-link:active {
  background-color: rgba(40, 45, 55, 0.06);
}
.cmp-breadcrumb ol > *:first-child::before {
  --icon-font-family: "icons-20-outline",
  	"missing-icons" !important;
  --icon-font-size: 1.25rem;
  margin-inline-end: var(--icon-margin-after, 0.375rem);
  --icon-glyph: "⌂";
}
.cmp-breadcrumb ol > *:first-child::before {
  --icon-margin-after: 0.1875rem;
}
.cmp-breadcrumb ol > *:not(:first-child)::before {
  --icon-font-family: "icons-20-outline",
  	"missing-icons" !important;
  --icon-font-size: 1.25rem;
  margin-inline-end: var(--icon-margin-after, 0.375rem);
  --icon-glyph: ">";
}
.cmp-breadcrumb ol > *:not(:first-child)::before {
  --icon-font-size: 0.875rem;
  --icon-margin-after: 0;
  margin-left: -0.125rem;
  color: #878c96;
}

/* cards */
.cmp-cards {
  list-style: none;
  margin: 0;
  padding: 0;
}
@supports (display: grid) {
  .cmp-cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(21.25rem, 1fr));
    grid-gap: 1.5rem;
  }
}

.cmp-card {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.08), 0 5px 30px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  background-color: #fff;
}
.cmp-card > a {
  text-decoration: none;
}
.cmp-card figure {
  margin: 1rem 1rem 1rem 0.5rem;
}
.cmp-card[data-variant=banner] figure {
  display: flex;
}
.cmp-card[data-variant=banner] figcaption {
  margin-left: 1rem;
}
.cmp-card[data-variant=banner] figcaption h1,
.cmp-card[data-variant=banner] figcaption h2,
.cmp-card[data-variant=banner] figcaption h3,
.cmp-card[data-variant=banner] figcaption h4,
.cmp-card[data-variant=banner] figcaption h5,
.cmp-card[data-variant=banner] figcaption h6 {
  font-size: 1.125rem;
}
.cmp-card[data-variant=banner] figcaption h1, .cmp-card[data-variant=banner] figcaption h1 ~ p,
.cmp-card[data-variant=banner] figcaption h2,
.cmp-card[data-variant=banner] figcaption h2 ~ p,
.cmp-card[data-variant=banner] figcaption h3,
.cmp-card[data-variant=banner] figcaption h3 ~ p,
.cmp-card[data-variant=banner] figcaption h4,
.cmp-card[data-variant=banner] figcaption h4 ~ p,
.cmp-card[data-variant=banner] figcaption h5,
.cmp-card[data-variant=banner] figcaption h5 ~ p,
.cmp-card[data-variant=banner] figcaption h6,
.cmp-card[data-variant=banner] figcaption h6 ~ p {
  margin: 0.125rem 0;
}
.cmp-card[data-variant=banner] figcaption p {
  font-size: 0.875rem;
  max-width: 18.125rem;
}
.cmp-card[data-variant=tile] {
  text-align: center;
}
.cmp-card figcaption {
  max-width: 60ch;
}

/* accordion */
.cmp-accordion {
  border-bottom: 1px solid #d7dce1;
  padding-left: var(--db-accordion---paddingLeft);
  padding-right: 1rem;
  --db-accordion---paddingLeft: 2.875rem;
}
.cmp-accordion summary {
  height: 3rem;
  display: flex;
  align-items: center;
  transform: translateX(calc(var(--db-accordion---paddingLeft) * -1));
  width: calc(100% + var(--db-accordion---paddingLeft) + 1rem);
}
.cmp-accordion summary::before {
  --icon-font-family: "icons-24-outline",
  	"missing-icons" !important;
  --icon-font-size: 1.5rem;
  margin-inline-end: var(--icon-margin-after, 0.375rem);
  --icon-glyph: ">";
}
.cmp-accordion summary::-webkit-details-marker {
  display: none;
}
.cmp-accordion summary:first-of-type {
  list-style-type: none;
}
.cmp-accordion summary::before {
  margin-left: 0.875rem;
  --icon-margin-after: 0.5rem;
}
.cmp-accordion summary + p {
  margin-top: 0;
}
.cmp-accordion[open] > summary::before {
  content: "⌃";
}
.cmp-accordion[data-emphasis=high] {
  --db-accordion---paddingLeft: 3.375rem;
}
.cmp-accordion[data-emphasis=high] summary {
  font-weight: 700;
  height: 3.5rem;
}
.cmp-accordion[data-emphasis=high] summary::before {
  --icon-font-size: 2rem;
}
.cmp-accordion[data-size=small] {
  --db-accordion---paddingLeft: 2.125rem;
}
.cmp-accordion[data-size=small] summary {
  height: 2.5rem;
  font-size: 0.875rem;
}
.cmp-accordion[data-size=small] summary::before {
  --icon-font-size: 1.25rem;
  margin-left: 0.375rem;
  --icon-margin-after: 0.375rem;
}
.cmp-accordion[data-size=large] {
  --db-accordion---paddingLeft: 3.375rem;
}
.cmp-accordion[data-size=large] summary {
  height: 4rem;
  font-size: 1.25rem;
}
.cmp-accordion[data-size=large] summary::before {
  --icon-font-size: 2rem;
}
/* overflow-menu */
/* ### this one is already included through the dropdown component that it extends */
/* mainnavigation */
.cmp-mainnavigation {
  position: relative;
  top: 0;
}
.rea-header .cmp-mainnavigation {
  order: 1;
}
.cmp-mainnavigation .elm-link {
  text-decoration: none;
  display: inline-block;
  color: inherit;
  border-radius: initial;
}
@media screen and (min-width: 1024px) {
  .cmp-mainnavigation > input[type=checkbox][id], .cmp-mainnavigation > input[type=checkbox][id] + label[for] {
    display: none;
  }
}
.cmp-mainnavigation > label[for] {
  padding: 0.5rem 1.375rem 0.625rem;
  display: block;
  background-color: #fdfdfd;
  border-bottom: 1px solid #f5f4f1;
}
.cmp-mainnavigation > label[for]::before {
  --icon-font-family: "icons-24-outline",
  	"missing-icons" !important;
  --icon-font-size: 1.5rem;
  margin-inline-end: var(--icon-margin-after, 0.375rem);
  --icon-glyph: "󡄢";
}
.cmp-mainnavigation > label[for]::after {
  --icon-font-family: "icons-24-outline",
  	"missing-icons" !important;
  --icon-font-size: 1.5rem;
  margin-inline-start: var(--icon-margin-before, 0.375rem);
  --icon-glyph: "⨯";
}
.cmp-mainnavigation > label[for]::before {
  margin-right: 1rem;
}
.cmp-mainnavigation > label[for]::after {
  transform: translateY(0.125rem);
  position: absolute;
  right: 1rem;
}
@media screen and (max-width: 1023px) {
  .cmp-mainnavigation {
    position: absolute;
    top: 4rem;
    left: 0;
    z-index: 30;
    width: 100vw;
    background-color: #3c414b;
  }
  .cmp-mainnavigation > input[type=checkbox][id]:not(:checked) + label[for]::after {
    content: none;
  }
  .cmp-mainnavigation > input[type=checkbox][id]:not(:checked) ~ ul {
    display: none;
  }
}
.cmp-mainnavigation ul {
  background-color: #fff;
}
@media screen and (min-width: 1024px) {
  .cmp-mainnavigation ul ul {
    visibility: hidden;
    position: absolute;
    z-index: 70;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.08), 0 5px 30px rgba(0, 0, 0, 0.05);
  }
}
@media screen and (max-width: 1023px) {
  .cmp-mainnavigation ul ul {
    display: none;
  }
}
@media screen and (min-width: 1024px) {
  .cmp-mainnavigation > ul {
    display: flex;
  }
}
@media screen and (min-width: 1024px) {
  .cmp-mainnavigation > ul > li > .elm-link, .cmp-mainnavigation > ul > li > * > .elm-link, .cmp-mainnavigation > ul > * > li > .elm-link, .cmp-mainnavigation > ul > * > li > * > .elm-link {
    padding: 2rem 1.625rem 1.1875rem;
    border-bottom: 4px solid transparent;
  }
  .cmp-mainnavigation > ul > li > .elm-link:hover, .cmp-mainnavigation > ul > li > .elm-link:focus, .cmp-mainnavigation > ul > li > * > .elm-link:hover, .cmp-mainnavigation > ul > li > * > .elm-link:focus, .cmp-mainnavigation > ul > * > li > .elm-link:hover, .cmp-mainnavigation > ul > * > li > .elm-link:focus, .cmp-mainnavigation > ul > * > li > * > .elm-link:hover, .cmp-mainnavigation > ul > * > li > * > .elm-link:focus {
    border-bottom-color: #d7dce1;
  }
  .cmp-mainnavigation > ul > li > .elm-link[aria-current=page], .cmp-mainnavigation > ul > li > * > .elm-link[aria-current=page], .cmp-mainnavigation > ul > * > li > .elm-link[aria-current=page], .cmp-mainnavigation > ul > * > li > * > .elm-link[aria-current=page] {
    border-bottom-color: #ec0016;
  }
}
@media screen and (min-width: 1024px) {
  .cmp-mainnavigation > ul > li ul, .cmp-mainnavigation > ul > * > li ul {
    min-width: 100%;
    left: 0;
    top: 4.9375rem;
  }
  .cmp-mainnavigation > ul > li ul ul, .cmp-mainnavigation > ul > * > li ul ul {
    left: 100%;
    transform: translateY(-3.3125rem);
    top: auto;
  }
  .cmp-mainnavigation > ul > li ul .elm-link, .cmp-mainnavigation > ul > * > li ul .elm-link {
    padding-left: 1.125rem;
    padding-right: 1.125rem;
    min-height: 3.3125rem;
    display: flex;
    align-items: center;
  }
}
@media screen and (max-width: 1023px) {
  .cmp-mainnavigation > ul > li ul > li > .elm-link, .cmp-mainnavigation > ul > li ul > li > * > .elm-link, .cmp-mainnavigation > ul > li ul > * > li > .elm-link, .cmp-mainnavigation > ul > li ul > * > li > * > .elm-link, .cmp-mainnavigation > ul > * > li ul > li > .elm-link, .cmp-mainnavigation > ul > * > li ul > li > * > .elm-link, .cmp-mainnavigation > ul > * > li ul > * > li > .elm-link, .cmp-mainnavigation > ul > * > li ul > * > li > * > .elm-link {
    padding-left: 2.125rem;
  }
}
@media screen and (max-width: 1023px) {
  .cmp-mainnavigation > ul > li ul > li ul > li > .elm-link, .cmp-mainnavigation > ul > li ul > li ul > li > * > .elm-link, .cmp-mainnavigation > ul > li ul > li ul > * > li > .elm-link, .cmp-mainnavigation > ul > li ul > li ul > * > li > * > .elm-link, .cmp-mainnavigation > ul > li ul > * > li ul > li > .elm-link, .cmp-mainnavigation > ul > li ul > * > li ul > li > * > .elm-link, .cmp-mainnavigation > ul > li ul > * > li ul > * > li > .elm-link, .cmp-mainnavigation > ul > li ul > * > li ul > * > li > * > .elm-link, .cmp-mainnavigation > ul > * > li ul > li ul > li > .elm-link, .cmp-mainnavigation > ul > * > li ul > li ul > li > * > .elm-link, .cmp-mainnavigation > ul > * > li ul > li ul > * > li > .elm-link, .cmp-mainnavigation > ul > * > li ul > li ul > * > li > * > .elm-link, .cmp-mainnavigation > ul > * > li ul > * > li ul > li > .elm-link, .cmp-mainnavigation > ul > * > li ul > * > li ul > li > * > .elm-link, .cmp-mainnavigation > ul > * > li ul > * > li ul > * > li > .elm-link, .cmp-mainnavigation > ul > * > li ul > * > li ul > * > li > * > .elm-link {
    padding-left: 3.75rem;
  }
}
.cmp-mainnavigation li {
  display: block;
  position: relative;
}
@media screen and (max-width: 1023px) {
  .cmp-mainnavigation li {
    color: #fdfdfd;
    background-color: #3c414b;
  }
  .cmp-mainnavigation li:hover {
    background-color: #afb4bb;
    color: #131821;
  }
  .cmp-mainnavigation li:hover > a {
    font-weight: 700;
  }
  .cmp-mainnavigation li .elm-link {
    color: inherit;
    padding-left: 1.375rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .cmp-mainnavigation li[aria-haspopup=true] {
    position: relative;
  }
}
.cmp-mainnavigation li[aria-haspopup=true]::after {
  --icon-font-family: "icons-24-outline",
  	"missing-icons" !important;
  --icon-font-size: 1.5rem;
  margin-inline-start: var(--icon-margin-before, 0.375rem);
  --icon-glyph: ">";
}
.cmp-mainnavigation li[aria-haspopup=true]::after {
  top: 0.5625rem;
  position: absolute;
  right: 1rem;
}
@media screen and (min-width: 1024px) {
  .cmp-mainnavigation li[aria-haspopup=true]::after {
    content: none;
  }
  .cmp-mainnavigation li .elm-link:hover, .cmp-mainnavigation li .elm-link:focus {
    background-color: #d7dce1;
    color: initial;
  }
  .cmp-mainnavigation li .elm-link[aria-current=page] {
    font-weight: 700;
  }
}
.cmp-mainnavigation li:hover > ul, .cmp-mainnavigation li:hover > * > ul {
  visibility: visible;
}
@media screen and (max-width: 1023px) {
  .cmp-mainnavigation li:hover > ul, .cmp-mainnavigation li:hover > * > ul {
    display: initial;
  }
}

/* metanavigation */
.cmp-metanavigation {
  display: flex;
  list-style: none;
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 0;
}
.cmp-metanavigation li {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.rea-footer .cmp-metanavigation li {
  padding-left: 0.375rem;
  padding-right: 0.375rem;
}
.cmp-metanavigation li .elm-link {
  text-decoration: none;
}
.cmp-sitesearch:focus-within ~ .cmp-metanavigation li .elm-link {
  font-size: 0;
}
.cmp-sitesearch:focus-within ~ .cmp-metanavigation li .elm-link::before {
  --icon-margin-after: 0;
}
@media screen and (max-width: 41rem) {
  .rea-header .cmp-metanavigation li .elm-link {
    font-size: 0;
  }
  .rea-header .cmp-metanavigation li .elm-link::before {
    --icon-margin-after: 0;
  }
}
.rea-footer .cmp-metanavigation {
  justify-content: center;
  flex-wrap: wrap;
}
@media screen and (max-width: 767px) {
  .rea-footer .cmp-metanavigation {
    margin-bottom: 1rem;
  }
}
@media screen and (min-width: 768px) {
  .rea-footer .cmp-metanavigation {
    float: right;
  }
}

/* brand */
.cmp-brand .elm-image.is-logo {
  float: left;
  margin-top: -0.125rem;
}
.rea-header .cmp-brand {
  padding-top: 1.125rem;
}
@media screen and (min-width: 1024px) {
  .rea-header .cmp-brand {
    padding-top: 2rem;
  }
  .rea-header .cmp-brand .is-site-name::before {
    content: none !important;
  }
}
@media screen and (max-width: 1023px) {
  .rea-header .cmp-mainnavigation ~ .cmp-brand .is-site-name {
    display: none;
  }
}

/* sitesearch */
.cmp-sitesearch {
  position: relative;
}
.cmp-sitesearch .elm-input[type=search] {
  background-color: #fdfdfd;
  background-image: none;
  opacity: 0;
  padding-left: 2.25em;
  width: 0;
}
.cmp-sitesearch .elm-input[type=search]:focus, .cmp-sitesearch .elm-input[type=search]:not(:placeholder-shown) {
  opacity: 1;
  width: 15rem;
}
.cmp-sitesearch .elm-input[type=search]:focus + .elm-label, .cmp-sitesearch .elm-input[type=search]:not(:placeholder-shown) + .elm-label {
  display: none;
}
.cmp-sitesearch .elm-input[type=search]:focus ~ .elm-button, .cmp-sitesearch .elm-input[type=search]:not(:placeholder-shown) ~ .elm-button {
  display: initial;
}
.cmp-sitesearch .elm-label {
  display: inline-block;
}
.cmp-sitesearch .elm-label::before {
  --icon-font-family: "icons-24-outline",
  	"missing-icons" !important;
  --icon-font-size: 1.5rem;
  margin-inline-end: var(--icon-margin-after, 0.375rem);
  --icon-glyph: "🔍";
}
.cmp-sitesearch .elm-label::before {
  font-size: 1.375rem;
  vertical-align: top;
}
.rea-header .cmp-sitesearch .elm-label {
  padding-top: 0.625rem;
}
@media screen and (max-width: 41rem) {
  .rea-meta .cmp-sitesearch .elm-label {
    font-size: 0;
  }
  .rea-meta .cmp-sitesearch .elm-label::before {
    --icon-margin-after: 0;
  }
}
.cmp-sitesearch .elm-button {
  display: none;
  left: 0;
  position: absolute;
  top: 0.5em;
  visibility: hidden;
}
.cmp-sitesearch .elm-button::before {
  --icon-font-family: "icons-24-outline",
  	"missing-icons" !important;
  --icon-font-size: 1.5rem;
  margin-inline-end: var(--icon-margin-after, 0.375rem);
  --icon-glyph: "🔍";
}
.cmp-sitesearch .elm-button::before {
  left: 0.25rem;
  margin: -9px;
  padding: 9px;
  position: absolute;
  top: -0.125rem;
  visibility: visible;
}
.rea-meta .cmp-sitesearch {
  margin-right: 0.5rem;
}
@media screen and (max-width: 31.25rem) {
  .rea-meta .cmp-sitesearch:focus-within {
    left: 1rem;
    position: absolute;
    z-index: 10;
  }
  .rea-meta .cmp-sitesearch:focus-within .elm-input[type=search] {
    width: calc(100vw - 2rem);
  }
}

/* notification */
.cmp-notification {
  background-color: #282d37;
  padding: 1rem;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
}
section[data-variant=hovering] .cmp-notification {
  background-color: #fff;
}
section[data-variant=alert] .cmp-notification {
  color: #fff;
}
section[data-variant=hovering] .cmp-notification {
  border-radius: 0.5rem;
  border: 2px solid transparent;
  padding: 0.875rem 1rem;
}
.cmp-notification[data-type=success] {
  background-color: #508b1b;
}
section[data-variant=hovering] .cmp-notification[data-type=success] {
  border-color: #508b1b;
}
.cmp-notification[data-type=warning] {
  background-color: #f75f00;
}
section[data-variant=hovering] .cmp-notification[data-type=warning] {
  border-color: #f75f00;
}
.cmp-notification[data-type=error] {
  background-color: #ec0016;
}
section[data-variant=hovering] .cmp-notification[data-type=error] {
  border-color: #ec0016;
}
.cmp-notification[data-type=informative] {
  background-color: #0087b9;
}
section[data-variant=hovering] .cmp-notification[data-type=informative] {
  border-color: #0087b9;
}
.cmp-notification[data-icon], .cmp-notification[data-icon-before], .cmp-notification[data-icon-after] {
  display: flex;
  align-items: center;
}
.cmp-notification[data-icon]::before, .cmp-notification[data-icon-before]::before {
  --icon-margin-after: 0.625rem;
}
.cmp-notification[data-icon-after] {
  justify-content: space-between;
}
.cmp-notification[data-icon-after]::after {
  --icon-margin-before: 0.625rem;
}

/* dialog */
.cmp-dialog {
  border: 0;
  border-radius: 8px;
  max-width: 90vw;
  padding: 1rem;
  font-size: 0.875rem;
  color: #282d37;
  position: fixed;
  top: 50%;
  transform: translate(0, -50%);
  margin-top: 0;
}
@media screen and (min-width: 768px) {
  .cmp-dialog {
    padding: 1.5rem;
  }
}
.cmp-dialog::backdrop {
  background-color: rgba(0, 0, 0, 0.9);
}
.cmp-dialog + .backdrop {
  background-color: rgba(0, 0, 0, 0.9);
}
.no-js .cmp-dialog:target {
  display: initial;
}
.cmp-dialog header {
  color: #131821;
  transform: translateY(-0.5625rem);
}
.cmp-dialog header .elm-headline {
  font-weight: 700;
  margin-top: 0;
  margin-right: 1.5rem;
  margin-bottom: 0.375rem;
  font-size: 1.125rem;
  line-height: 1.5rem;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .cmp-dialog header .elm-headline {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }
}
.cmp-dialog header .elm-link.is-close {
  font-size: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.cmp-dialog header .elm-link.is-close::before {
  --icon-font-family: "icons-24-outline",
  	"missing-icons" !important;
  --icon-font-size: 1.5rem;
  margin-inline-end: var(--icon-margin-after, 0.375rem);
  --icon-glyph: "⨯";
}
.cmp-dialog header .elm-link.is-close::before {
  --icon-margin-after: 0;
}
.cmp-dialog main p:first-child {
  margin-top: 0;
}
.cmp-dialog footer {
  margin-top: 1.125rem;
  display: flex;
}
.cmp-dialog footer .elm-button + .elm-button {
  margin-left: 1rem;
}
.cmp-dialog footer[data-alignment=end] {
  justify-content: flex-end;
}
.cmp-dialog footer:not([data-alignment=end]) .elm-button {
  flex-grow: 1;
}

/* language-switcher */
.cmp-language-switcher {
  position: relative;
  list-style: none;
  margin-right: 1rem;
}
.cmp-language-switcher:hover li:not([aria-selected]), .cmp-language-switcher:focus li:not([aria-selected]) {
  visibility: visible;
}
.cmp-language-switcher:hover li[aria-selected=true], .cmp-language-switcher:focus li[aria-selected=true] {
  color: #ec0016;
}
.cmp-language-switcher li {
  background-color: #fdfdfd;
  position: absolute;
  top: 1.625rem;
}
.rea-header .cmp-language-switcher li {
  top: 3.4375rem;
  z-index: 40;
}
@media screen and (min-width: 1024px) {
  .rea-header .cmp-language-switcher li {
    top: 3.5rem;
  }
}
.rea-header .cmp-language-switcher li:not([aria-selected=true]) ~ li:not([aria-selected=true]) {
  top: 6.75rem;
}
.cmp-language-switcher li .elm-link {
  color: inherit;
  text-decoration: none;
}
.cmp-language-switcher li:not([aria-selected]) {
  visibility: hidden;
}
.cmp-language-switcher li:not([aria-selected]) .elm-link {
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem;
  padding-right: 2.125rem;
}
.cmp-language-switcher li:not([aria-selected]) .elm-link:hover, .cmp-language-switcher li:not([aria-selected]) .elm-link:focus {
  background-color: #d7dce1;
}
.cmp-language-switcher li[aria-selected] {
  position: initial;
}
.cmp-language-switcher li[aria-selected] .elm-link {
  padding-bottom: 1.375rem;
}
.cmp-language-switcher li[aria-selected] .elm-link:hover {
  color: #ec0016;
}
.cmp-language-switcher li[aria-selected] .elm-link::after {
  float: right;
  margin-left: 0.3125rem;
}
.cmp-language-switcher li[aria-selected] .elm-link::after {
  --icon-font-family: "icons-24-outline",
  	"missing-icons" !important;
  --icon-font-size: 1.5rem;
  margin-inline-start: var(--icon-margin-before, 0.375rem);
  --icon-glyph: "⌄";
}
.cmp-language-switcher li .elm-link {
  display: inline-block;
  padding-left: 1.125rem;
}
.cmp-language-switcher li .elm-link:hover, .cmp-language-switcher li .elm-link:focus {
  color: initial;
}

/* pagination */
.cmp-pagination {
  color: #282d37;
  font-size: 0.875rem;
  -webkit-user-select: none;
          user-select: none;
}
.cmp-pagination > ol {
  display: flex;
}
.cmp-pagination li,
.cmp-pagination .elm-link {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.cmp-pagination li {
  width: 2.25rem;
  height: 2.25rem;
}
.cmp-pagination .elm-link {
  width: 100%;
  height: 100%;
  color: inherit;
  text-decoration: none;
}
.cmp-pagination .elm-link[rel=prev], .cmp-pagination .elm-link[rel=next] {
  padding: 0;
  vertical-align: bottom;
}
.cmp-pagination .elm-link[rel=prev]::before {
  margin-left: 0.6875rem;
}
.cmp-pagination .elm-link[rel=next]::before {
  margin-right: 0.6875rem;
}
.cmp-pagination .elm-link[rel=prev] {
  font-size: 0;
}
.cmp-pagination .elm-link[rel=prev]::before {
  --icon-font-family: "icons-24-outline",
  	"missing-icons" !important;
  --icon-font-size: 1.5rem;
  margin-inline-end: var(--icon-margin-after, 0.375rem);
  --icon-glyph: "<";
}
.cmp-pagination .elm-link[rel=prev]::before {
  --icon-margin-after: 0;
}
.cmp-pagination .elm-link[rel=next] {
  font-size: 0;
}
.cmp-pagination .elm-link[rel=next]::before {
  --icon-font-family: "icons-24-outline",
  	"missing-icons" !important;
  --icon-font-size: 1.5rem;
  margin-inline-end: var(--icon-margin-after, 0.375rem);
  --icon-glyph: ">";
}
.cmp-pagination .elm-link[rel=next]::before {
  --icon-margin-after: 0;
}
.cmp-pagination .elm-link[aria-current=page] {
  font-weight: 700;
}

/* sidenavi */
.cmp-sidenavi {
  background-color: #3c414b;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.08), 0 5px 30px rgba(0, 0, 0, 0.05);
  display: inline-block;
}
.cmp-sidenavi .elm-link {
  color: #d7dce1;
  display: block;
  padding: 1.25rem 1rem;
  text-decoration: none;
  border-radius: initial;
}
.cmp-sidenavi .elm-link:hover, .cmp-sidenavi .elm-link:focus, .cmp-sidenavi .elm-link[aria-current=page] {
  color: #3c414b;
}
.cmp-sidenavi .elm-link:hover, .cmp-sidenavi .elm-link:focus {
  background-color: #afb4bb;
}
.cmp-sidenavi .elm-link[aria-current=page] {
  background-color: #d7dce1;
  font-weight: 700;
}
.cmp-sidenavi .elm-link[rel] {
  padding-left: 1.3125rem;
}
.cmp-sidenavi .elm-link[rel=index]::before {
  --icon-font-family: "icons-32-outline",
  	"missing-icons" !important;
  --icon-font-size: 2rem;
  margin-inline-end: var(--icon-margin-after, 0.375rem);
  --icon-glyph: "⌂";
}
.cmp-sidenavi .elm-link[rel=documents]::before {
  --icon-font-family: "icons-32-outline",
  	"missing-icons" !important;
  --icon-font-size: 2rem;
  margin-inline-end: var(--icon-margin-after, 0.375rem);
  --icon-glyph: "🖹";
}
.cmp-sidenavi .elm-link[rel=dashboard]::before {
  --icon-font-family: "icons-32-enterprise-outline",
  	"missing-icons" !important;
  --icon-font-size: 2rem;
  margin-inline-end: var(--icon-margin-after, 0.375rem);
  --icon-glyph: "";
}
.cmp-sidenavi .elm-link[rel=cloud]::before {
  --icon-font-family: "icons-32-enterprise-outline",
  	"missing-icons" !important;
  --icon-font-size: 2rem;
  margin-inline-end: var(--icon-margin-after, 0.375rem);
  --icon-glyph: "";
}
.cmp-sidenavi .elm-link[rel=calendar]::before {
  --icon-font-family: "icons-32-outline",
  	"missing-icons" !important;
  --icon-font-size: 2rem;
  margin-inline-end: var(--icon-margin-after, 0.375rem);
  --icon-glyph: "📆";
}
.cmp-sidenavi .elm-link[rel=help]::before {
  --icon-font-family: "icons-32-outline",
  	"missing-icons" !important;
  --icon-font-size: 2rem;
  margin-inline-end: var(--icon-margin-after, 0.375rem);
  --icon-glyph: "?";
}

/* tab-bar */
.cmp-tab-bar {
  display: flex;
  flex-wrap: wrap;
}
.cmp-tab-bar label[role=tab],
.cmp-tab-bar section[role=tabpanel] {
  background-color: #fdfdfd;
}
.cmp-tab-bar > input[type=radio] + label[role=tab], .cmp-tab-bar > * > input[type=radio] + label[role=tab] {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  cursor: pointer;
  display: inline-block;
  margin-left: 0.5rem;
  padding: 0.3125rem 2rem;
  position: relative;
  z-index: 1;
}
.cmp-tab-bar > input[type=radio] + label[role=tab]:first-of-type, .cmp-tab-bar > * > input[type=radio] + label[role=tab]:first-of-type {
  margin-left: 1rem;
}
.cmp-tab-bar > input[type=radio]:focus + label, .cmp-tab-bar > * > input[type=radio]:focus + label {
  outline: 1px dotted #878c96;
}
.cmp-tab-bar > input[type=radio]:not(:checked) + label[role=tab]:hover, .cmp-tab-bar > input[type=radio]:not(:checked) + label[role=tab]:focus, .cmp-tab-bar > * > input[type=radio]:not(:checked) + label[role=tab]:hover, .cmp-tab-bar > * > input[type=radio]:not(:checked) + label[role=tab]:focus {
  background-color: #f0f3f5;
}
.cmp-tab-bar > input[type=radio]:disabled + label[role=tab], .cmp-tab-bar > * > input[type=radio]:disabled + label[role=tab] {
  color: #878c96;
}
.cmp-tab-bar > input[type=radio]:disabled + label[role=tab]:hover, .cmp-tab-bar > input[type=radio]:disabled + label[role=tab]:focus, .cmp-tab-bar > * > input[type=radio]:disabled + label[role=tab]:hover, .cmp-tab-bar > * > input[type=radio]:disabled + label[role=tab]:focus {
  background-color: unset;
}
.cmp-tab-bar > input[type=radio]:checked + label[role=tab], .cmp-tab-bar > * > input[type=radio]:checked + label[role=tab] {
  border: 0.0625rem solid #646973;
  border-bottom: none;
  font-weight: 700;
  padding-top: 0.1875rem;
  transform: translateY(0.0625rem);
}
.cmp-tab-bar > input[type=radio]:checked + label[role=tab] + section[role=tabpanel], .cmp-tab-bar > * > input[type=radio]:checked + label[role=tab] + section[role=tabpanel] {
  display: initial;
}
.cmp-tab-bar section[role=tabpanel] {
  border-top: 0.0625rem solid #646973;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08), 0 15px 30px rgba(0, 0, 0, 0.05);
  display: none;
  flex-basis: 100%;
  order: 1;
  padding: 2.875rem 3.125rem;
}

/* table */
.cmp-table {
  border-collapse: collapse;
  font-size: var(--table---fontSize, inherit);
  text-align: left;
}
.cmp-table, .cmp-table > caption {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.08), 0 5px 30px rgba(0, 0, 0, 0.05);
}
.cmp-table caption {
  font-weight: 700;
  padding: 1rem 0.75rem;
  text-align: left;
}
.cmp-table thead {
  border-bottom: 0.0625rem solid #282d37;
  border-top: 0.0625rem solid #282d37;
}
.cmp-table thead th {
  color: #646973;
}
.cmp-table th {
  font-weight: 400;
}
.cmp-table th,
.cmp-table td {
  padding: 0.75rem;
  text-align: inherit;
  vertical-align: inherit;
}
.cmp-table th:hover,
.cmp-table td:hover {
  overflow: auto;
  resize: horizontal;
}
.cmp-table tbody {
  border-bottom: 0.0625rem solid #282d37;
}
.cmp-table tbody th:first-child {
  border-right: 1px solid #4f4b41;
}
.cmp-table thead[data-align=left],
.cmp-table tbody[data-align=left],
.cmp-table tr[data-align=left],
.cmp-table th[data-align=left],
.cmp-table td[data-align=left] {
  text-align: left;
}
.cmp-table thead[data-align=center],
.cmp-table tbody[data-align=center],
.cmp-table tr[data-align=center],
.cmp-table th[data-align=center],
.cmp-table td[data-align=center] {
  text-align: center;
}
.cmp-table thead[data-align=right],
.cmp-table tbody[data-align=right],
.cmp-table tr[data-align=right],
.cmp-table th[data-align=right],
.cmp-table td[data-align=right] {
  text-align: right;
}
.cmp-table thead[data-vertical-align=top],
.cmp-table tbody[data-vertical-align=top],
.cmp-table tr[data-vertical-align=top],
.cmp-table th[data-vertical-align=top],
.cmp-table td[data-vertical-align=top] {
  vertical-align: top;
}
.cmp-table thead[data-vertical-align=middle],
.cmp-table tbody[data-vertical-align=middle],
.cmp-table tr[data-vertical-align=middle],
.cmp-table th[data-vertical-align=middle],
.cmp-table td[data-vertical-align=middle] {
  vertical-align: middle;
}
.cmp-table thead[data-vertical-align=bottom],
.cmp-table tbody[data-vertical-align=bottom],
.cmp-table tr[data-vertical-align=bottom],
.cmp-table th[data-vertical-align=bottom],
.cmp-table td[data-vertical-align=bottom] {
  vertical-align: bottom;
}
.cmp-table[data-border=horizontal] tbody tr:not(:last-child) th,
.cmp-table[data-border=horizontal] tbody tr:not(:last-child) td, .cmp-table[data-border=around] tbody tr:not(:last-child) th,
.cmp-table[data-border=around] tbody tr:not(:last-child) td {
  border-bottom: 1px solid #d7dce1;
}
.cmp-table[data-border=around] tbody th:not(:first-child),
.cmp-table[data-border=around] tbody td:not(:first-child) {
  border-left: 1px solid #d7dce1;
}
.cmp-table[data-rows=zebra] tbody tr:nth-child(odd) {
  background-color: rgba(40, 45, 55, 0.07);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
}
.cmp-table[data-density=small] th,
.cmp-table[data-density=small] td {
  padding: 0.5rem;
}
.cmp-table[data-density=large] th,
.cmp-table[data-density=large] td {
  padding: 1rem;
}
.cmp-table[data-sticky=header] thead,
.cmp-table[data-sticky=header] tbody th {
  position: sticky;
  top: -1px;
  background-color: #fff;
}

/* link-list */
.cmp-link-list {
  list-style: none;
  padding-left: 0;
}
.cmp-link-list li .elm-link {
  font-weight: 700;
  text-decoration: none;
}
.cmp-link-list li .elm-link::before {
  --icon-font-family: "icons-24-outline",
  	"missing-icons" !important;
  --icon-font-size: 1.5rem;
  margin-inline-end: var(--icon-margin-after, 0.375rem);
  --icon-glyph: ">";
}
.cmp-link-list li .elm-link:hover, .cmp-link-list li .elm-link:active {
  text-decoration: underline;
}
.cmp-link-list li .elm-link::before {
  color: #ec0016;
  display: inline-block;
  font-size: 0.75rem;
  font-weight: 700;
  margin-top: -0.125rem;
  vertical-align: middle;
}

/* dropdown */
.cmp-overflow-menu, .cmp-dropdown {
  position: relative;
  display: inline-block;
  color: #2d333f;
}
.cmp-overflow-menu:not(.is-account) summary, .cmp-dropdown:not(.is-account) summary {
  font-size: 0;
}
.cmp-overflow-menu:not(.is-account) summary::before, .cmp-dropdown:not(.is-account) summary::before {
  --icon-margin-after: 0;
}
.cmp-overflow-menu summary, .cmp-dropdown summary {
  display: inline-block;
}
.cmp-overflow-menu summary::before, .cmp-dropdown summary::before {
  --icon-font-family: "icons-20-outline",
  	"missing-icons" !important;
  --icon-font-size: 1.25rem;
  margin-inline-end: var(--icon-margin-after, 0.375rem);
  --icon-glyph: "⋮";
}
.cmp-overflow-menu summary::before, .cmp-dropdown summary::before {
  display: inline-block;
  padding: 0.5625rem 0.75rem;
  transform: translateX(-0.125rem);
}
.cmp-overflow-menu summary:hover::before, .cmp-dropdown summary:hover::before, .cmp-overflow-menu summary:focus::before, .cmp-dropdown summary:focus::before {
  background-color: #d7dce1;
}
.cmp-overflow-menu summary::-webkit-details-marker, .cmp-dropdown summary::-webkit-details-marker {
  display: none;
}
.cmp-overflow-menu summary:first-of-type, .cmp-dropdown summary:first-of-type {
  list-style-type: none;
}
.cmp-overflow-menu menu, .cmp-dropdown menu,
.cmp-overflow-menu ul,
.cmp-dropdown ul {
  filter: drop-shadow(0 2px 8px rgba(0, 0, 0, 0.3));
  margin-block-start: 1rem;
  padding-left: 0;
  position: absolute;
  width: min-content;
  z-index: 60;
  border-radius: 8px;
  background-color: #fff;
}
.cmp-overflow-menu menu::before, .cmp-dropdown menu::before,
.cmp-overflow-menu ul::before,
.cmp-dropdown ul::before {
  border-bottom: 0.8125rem solid #fff;
  border-left: 0.8125rem solid transparent;
  border-right: 0.8125rem solid transparent;
  content: "";
  height: 0;
  position: absolute;
  transform: translate(0.75rem, -0.8125rem);
  width: 0;
}
.cmp-overflow-menu menu li button, .cmp-dropdown menu li button,
.cmp-overflow-menu menu li a,
.cmp-dropdown menu li a,
.cmp-overflow-menu ul li button,
.cmp-dropdown ul li button,
.cmp-overflow-menu ul li a,
.cmp-dropdown ul li a {
  background-color: #fff;
  display: block;
  padding: 1rem 2rem 1rem 1rem;
  font-weight: 400;
  text-decoration: none;
  border-radius: unset;
}
.cmp-overflow-menu menu li button:hover, .cmp-dropdown menu li button:hover, .cmp-overflow-menu menu li button:focus, .cmp-dropdown menu li button:focus,
.cmp-overflow-menu menu li a:hover,
.cmp-dropdown menu li a:hover,
.cmp-overflow-menu menu li a:focus,
.cmp-dropdown menu li a:focus,
.cmp-overflow-menu ul li button:hover,
.cmp-dropdown ul li button:hover,
.cmp-overflow-menu ul li button:focus,
.cmp-dropdown ul li button:focus,
.cmp-overflow-menu ul li a:hover,
.cmp-dropdown ul li a:hover,
.cmp-overflow-menu ul li a:focus,
.cmp-dropdown ul li a:focus {
  background-color: #d7dce1;
  color: inherit;
}
.cmp-overflow-menu menu li:first-child button, .cmp-dropdown menu li:first-child button,
.cmp-overflow-menu menu li:first-child a,
.cmp-dropdown menu li:first-child a,
.cmp-overflow-menu ul li:first-child button,
.cmp-dropdown ul li:first-child button,
.cmp-overflow-menu ul li:first-child a,
.cmp-dropdown ul li:first-child a {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.cmp-overflow-menu menu li:last-child button, .cmp-dropdown menu li:last-child button,
.cmp-overflow-menu menu li:last-child a,
.cmp-dropdown menu li:last-child a,
.cmp-overflow-menu ul li:last-child button,
.cmp-dropdown ul li:last-child button,
.cmp-overflow-menu ul li:last-child a,
.cmp-dropdown ul li:last-child a {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.cmp-overflow-menu[data-horizontal-position=opposite] menu, [data-horizontal-position=opposite].cmp-dropdown menu,
.cmp-overflow-menu[data-horizontal-position=opposite] ul,
[data-horizontal-position=opposite].cmp-dropdown ul {
  right: 0;
}
.cmp-overflow-menu[data-horizontal-position=opposite] menu::before, [data-horizontal-position=opposite].cmp-dropdown menu::before,
.cmp-overflow-menu[data-horizontal-position=opposite] ul::before,
[data-horizontal-position=opposite].cmp-dropdown ul::before {
  right: 0;
  transform: translate(-0.75rem, -0.8125rem);
}
.cmp-overflow-menu menu, .cmp-dropdown menu {
  list-style-type: "";
}
.cmp-overflow-menu menu li, .cmp-dropdown menu li {
  overflow-x: hidden;
}
.cmp-overflow-menu menu button, .cmp-dropdown menu button {
  line-height: inherit;
  text-align: left;
  width: 100%;
}
.cmp-overflow-menu[open] summary::after, [open].cmp-dropdown summary::after {
  content: " ";
  display: inline-block;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 50;
}

.cmp-dropdown.is-account summary::before {
  --icon-font-family: "icons-24-outline",
  	"missing-icons" !important;
  --icon-font-size: 1.5rem;
  margin-inline-end: var(--icon-margin-after, 0.375rem);
  --icon-glyph: "🙂";
}
.cmp-dropdown.is-account summary:hover::before {
  color: #ec0016;
}
@media screen and (max-width: 41rem) {
  .cmp-dropdown.is-account summary {
    font-size: 0;
  }
  .cmp-dropdown.is-account summary::before {
    --icon-margin-after: 0;
  }
}
.cmp-dropdown menu,
.cmp-dropdown ul {
  margin-block-start: initial;
  right: 0;
  transform: translateY(0.875rem);
  background-color: #fff;
}
.cmp-dropdown menu::before,
.cmp-dropdown ul::before {
  content: none;
}
.cmp-dropdown[data-horizontal-position=opposite] menu,
.cmp-dropdown[data-horizontal-position=opposite] ul {
  right: unset;
}

body {
  display: grid;
  grid-template-rows: auto 1fr auto;
}

body.has-fixed-header .rea-header,
body.has-fixed-footer .rea-footer {
  position: fixed;
  width: 100%;
  z-index: 10;
}

body.has-fixed-header {
  padding-top: 5.9375rem;
}
body.has-fixed-header .rea-header {
  top: 0;
}
@media screen and (max-width: 1023px) {
  body.has-fixed-header {
    padding-top: 5rem;
  }
}

body.has-fixed-footer {
  padding-bottom: 5.5rem;
}
body.has-fixed-footer .rea-footer {
  bottom: 0;
}
@media screen and (max-width: 1023px) {
  body.has-fixed-footer {
    padding-bottom: 5.875rem;
  }
}

.rea-header {
  background-color: #fdfdfd;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 4px 8px 1px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  min-height: 4.9375rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.rea-header .elm-link {
  display: inline-block;
}
@media screen and (max-width: 1023px) {
  .rea-header {
    min-height: 4rem;
    border-bottom: 0.0625rem solid #9c9a8e;
  }
}

.rea-meta {
  display: flex;
}
.rea-meta ul,
.rea-meta ol {
  margin: 0;
  padding: 0;
}
.rea-meta ul {
  list-style: none;
}
.rea-header .rea-meta {
  margin-top: 1.4375rem;
  order: 2;
}
.rea-header .rea-meta .elm-link {
  padding-bottom: 0.5625rem;
  padding-top: 0.5625rem;
}
@media screen and (max-width: 1023px) {
  .rea-header .rea-meta {
    margin-top: 0.5rem;
  }
}
.rea-meta > .elm-link[rel=account] {
  font-size: 0;
  margin-left: 0.25rem;
}
.rea-meta > .elm-link[rel=account]::before {
  --icon-margin-after: 0;
}
@media screen and (min-width: 27.25rem) {
  .rea-meta > .elm-link[rel=account] {
    margin-left: 0.5rem;
  }
}
@media screen and (max-width: 26.25rem) {
  .rea-meta > .elm-link[rel=account] {
    font-size: 0;
  }
  .rea-meta > .elm-link[rel=account]::before {
    --icon-margin-after: 0;
  }
}
.rea-meta .cmp-sitesearch:focus-within ~ .elm-link[rel=account] {
  font-size: 0;
}
.rea-meta .cmp-sitesearch:focus-within ~ .elm-link[rel=account]::before {
  --icon-margin-after: 0;
}

.rea-footer::after, .rea-main::after, .rea-header::after {
  clear: both;
  content: " ";
  display: table;
}

.rea-main {
  margin: 0 auto;
  position: relative;
  padding: 1rem;
  background-color: transparent;
}
@media screen and (min-width: 1088px) {
  .rea-main {
    max-width: 960px;
    width: 960px;
  }
}
@media screen and (min-width: 1280px) {
  .rea-main {
    max-width: 1152px;
    width: 1152px;
  }
}
@media screen and (min-width: 1472px) {
  .rea-main {
    max-width: 1344px;
    width: 1344px;
  }
}

.rea-grid {
  margin-left: -0.625rem;
  margin-right: -0.625rem;
  margin-top: -0.625rem;
  flex-wrap: wrap;
}
@media screen and (min-width: 769px), print {
  .rea-grid {
    display: flex;
  }
}
.rea-grid > div, .rea-grid > area, .rea-grid > section, .rea-grid > li {
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0.625rem;
}
@media screen and (max-width: 1199px) {
  .rea-grid > div, .rea-grid > area, .rea-grid > section, .rea-grid > li {
    padding: 0.375rem;
  }
}
.rea-grid:last-child {
  margin-bottom: -0.625rem;
}
.rea-grid:not(:last-child) {
  margin-bottom: 0.875rem;
}
@media screen and (max-width: 1199px) {
  .rea-grid {
    margin-left: -0.375rem;
    margin-right: -0.375rem;
    margin-top: -0.375rem;
  }
  .rea-grid:last-child {
    margin-bottom: -0.375rem;
  }
  .rea-grid:not(:last-child) {
    margin-bottom: calc(1.5rem - 0.375rem);
  }
}
.rea-grid [data-type=four-fifths] {
  flex: none;
  width: 80%;
}
.rea-grid [data-type=three-quarters] {
  flex: none;
  width: 75%;
}
.rea-grid [data-type=two-thirds] {
  flex: none;
  width: 66.6666%;
}
.rea-grid [data-type=three-fifths] {
  flex: none;
  width: 60%;
}
.rea-grid [data-type=half] {
  flex: none;
  width: 50%;
}
.rea-grid [data-type=two-fifths] {
  flex: none;
  width: 40%;
}
.rea-grid [data-type=one-third] {
  flex: none;
  width: 33.3333%;
}
.rea-grid [data-type=one-quarter] {
  flex: none;
  width: 25%;
}
.rea-grid [data-type=one-fifth] {
  flex: none;
  width: 20%;
}
.rea-footer {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.08), 0 5px 30px rgba(0, 0, 0, 0.05);
  margin-top: 1rem;
  padding: 1.3125rem 1rem;
  color: #646973;
  background-color: #fdfdfd;
}
@media screen and (max-width: 767px) {
  .rea-footer {
    text-align: center;
  }
}
.rea-footer.has-border {
  border-top: 0.375rem solid #d7dce1;
}

@font-face {
  font-family: "icons-24-outline";
  font-weight: 100;
  font-style: normal;
  src: url("/src/assets/fonts/icons-24-outline-action.woff2") format("woff2"), url("/src/assets/fonts/icons-24-outline-action.woff") format("woff");
}
* {
  box-sizing: border-box;
}

html {
  overflow-x: auto;
}

html,
body {
  height: 100%;
  width: 100%;
}

select {
  outline: 0px;
}

input {
  caret-color: black !important;
}

.elm-input {
  --db-focus-outline-color: whitesmoke;
}

:root {
  --db-focus-outline-offset: 1px;
  --db-focus-outline-color: whitesmoke;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

body, input {
  margin: 0;
  font-family: "DB Screen Sans", Helvetica, Arial, sans-serif, icons-24-outline;
}

.modal-backdrop {
  visibility: hidden !important;
}

.modal.in {
  background-color: rgba(0, 0, 0, 0.5);
}

.full-screen-modal {
  width: 100%;
  height: 100%;
}

.dim-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0.5;
  z-index: 1001;
}

.elm-progress {
  display: flex;
  height: 20px;
  justify-content: center;
  align-items: center;
}

.input-field {
  position: relative;
  width: 349px;
}
.input-field input,
.input-field select {
  font-size: 13px;
  height: 52px;
  margin: 0 0 4px;
  padding: 14px 16px 14px 17px;
  background-color: #f0f3f5;
  border-bottom: none;
}
.input-field select {
  width: 349px;
}
.input-field textarea {
  background-color: #f0f3f5;
}
.input-field input[type=radio] {
  background-color: white;
  margin: 0 5px 0px 4px;
  padding: 14px 0px 14px 17px;
}

.input-field .elm-radio + label {
  margin-left: 0;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  margin: 0;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  margin: 0;
}

.elm-input[type=text] + .elm-label,
.elm-input[type=text] + .elm-label,
.elm-input[type=number] + .elm-label,
.elm-input[type=datetime-local] + .elm-label,
.elm-input[type=date] + .elm-label,
.elm-select + .elm-label .elm-textarea + .elm-label {
  transform: translateY(-3.4rem) translateX(0.3rem);
  -webkit-user-select: none;
          user-select: none;
}

.date-field {
  position: relative;
}
.date-field:not(.focused):in-range::-webkit-datetime-edit-year-field, .date-field:not(.focused):in-range::-webkit-datetime-edit-month-field, .date-field:not(.focused):in-range::-webkit-datetime-edit-day-field, .date-field:not(.focused):in-range::-webkit-datetime-edit-hour-field, .date-field:not(.focused):in-range::-webkit-datetime-edit-minute-field, .date-field:not(.focused):in-range::-webkit-datetime-edit-text {
  color: transparent;
}

.date-field.focused + .date-label {
  font-size: 0.75rem;
  color: #131821;
  top: 18px;
  left: 4px;
}

.date-label {
  position: absolute;
  color: #afb1b5;
  font-size: 13px;
  top: 33px;
  left: 16px;
}

.invalid-input {
  border-bottom: 1px solid #ec0016 !important;
}

.input-error {
  display: block;
  font-size: 12px;
  margin-left: 16px;
  color: #ec0016;
}

.input-info {
  display: block;
  font-size: 12px;
  margin-left: 16px;
  color: #9e9e9e;
}

.cdk-global-overlay-wrapper {
  display: flex;
}

.cdk-global-overlay-wrapper {
  background-color: rgba(0, 0, 0, 0.55);
}

.mat-dialog-container {
  background-color: #fff;
  padding: 0 !important;
  margin-top: 10vh;
}

.modal-header {
  position: relative;
  background: #f0f3f5;
  padding: 30px;
  margin: 0;
  height: 132px;
}
.modal-header span {
  font-weight: normal;
}
.modal-header h2 {
  margin-top: 16px;
}
.modal-header .close-icon {
  position: absolute;
  top: 35px;
  right: 35px;
}

.modal-main {
  max-height: calc(100vh - 294px);
  overflow-y: auto;
  padding: 30px;
}

.modal-footer {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 15px;
  left: 0;
  bottom: 0;
  background-color: #fff;
  width: 100%;
  height: 70px;
}

.cmp-dialog {
  position: fixed;
  top: 0;
  left: 0;
  transform: unset;
  margin-top: 10vh;
  margin-bottom: 10vh;
  padding: 0;
  width: max-content;
  min-width: 400px;
  max-width: 80vw;
}
.cmp-dialog hr {
  margin: 0.5rem 0;
}

.table-header {
  position: sticky;
  top: 0%;
  padding: 0px;
  background-color: #fff;
  z-index: 10;
}

.list-table {
  width: 100%;
  min-width: 100%;
  border-spacing: 0;
  border-bottom: solid 1px #3c414b;
  line-height: normal;
}
.list-table th {
  padding: 23px 20px 23px 5px;
  border-top: 1px solid #3c414b;
  border-bottom: 1px solid #3c414b;
  color: #141923;
  font-weight: normal;
  line-height: 20px;
  vertical-align: middle;
}
.list-table th > div {
  display: flex;
  align-items: center;
}
.list-table th .sort-icons-block {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 10px;
  height: 100%;
  margin-left: 5px;
}
.list-table th .sort-ascending, .list-table th .sort-descending {
  width: 0;
  height: 0;
  margin: 2px;
}
.list-table th .sort-ascending {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #c8cdd2;
}
.list-table th .sort-ascending.active-sort {
  border-top: 5px solid #000;
}
.list-table th .sort-descending {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #c8cdd2;
}
.list-table th .sort-descending.active-sort {
  border-bottom: 5px solid #000;
}
.list-table tbody tr:nth-child(odd) {
  background-color: #eef1f3;
}
.list-table tbody tr:hover {
  background-color: rgba(0, 106, 150, 0.17);
}
.list-table tbody .icon:nth-of-type(even) {
  margin-left: 10px;
}
.list-table td {
  text-align: left;
  padding: 12px 16px 12px 10px;
  vertical-align: middle;
}
.list-table td .disruption-icon {
  color: #ff0000;
}
.list-table td.vertical-align {
  display: flex;
  align-items: center;
  justify-content: center;
}
.list-table td.align-center {
  text-align: center;
}
.list-table td:last-of-type {
  min-width: 70px;
}
.list-table td a {
  text-decoration: underline;
}
.list-table .menu li {
  min-width: max-content;
}

.bottom-controls {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 14px;
}
.bottom-controls .load-btn {
  position: absolute;
  cursor: pointer;
  display: block;
  width: 170px;
  height: 36px;
  left: calc(50% - 85px);
  top: 0;
  margin-top: 14px;
  padding: 8px 0;
  border-radius: 4px;
  font-size: 1rem;
  background-color: #fff;
  border: solid 1px #131821;
}
.bottom-controls a {
  text-decoration: underline;
}

.elm-link {
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.text {
  cursor: text;
}

db-button :hover {
  cursor: pointer;
}

.modal-header {
  position: relative;
  background: #f0f3f5;
  padding: 30px;
  margin: 0;
  height: 132px;
}
.modal-header span {
  font-weight: normal;
}
.modal-header h2 {
  margin-top: 16px;
}
.modal-header .close-icon {
  position: absolute;
  top: 35px;
  right: 35px;
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-column .parting-line {
  height: 1px;
  border: solid 1px #c8cdd2;
  margin-top: 10px;
}

.pointer {
  cursor: pointer;
}

::ng-deep .mat-tab-label-active {
  font-weight: bold;
}

.mat-tab-label {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  max-width: -moz-fit-content;
  max-width: fit-content;
}

::ng-deep .mat-dialog-container {
  margin-top: 0px;
  overflow: hidden;
  max-height: -moz-fit-content;
  max-height: fit-content;
}

pdf-viewer ::ng-deep .ng2-pdf-viewer-container {
  margin-left: 5%;
  margin-right: 5%;
  max-width: 95%;
  overflow-x: hidden;
}

::ng-deep .mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs > .mat-mdc-tab-header .mat-mdc-tab {
  flex-grow: 0;
}

::ng-deep .mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
  font-weight: bold;
  color: black;
}

::ng-deep .mat-mdc-tab.mdc-tab .mdc-tab__text-label {
  font-size: 115%;
}

::ng-deep .mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: white;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: grey;
  --mat-tab-header-active-ripple-color: white;
  --mat-tab-header-inactive-ripple-color: white;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: black;
  --mat-tab-header-active-hover-label-text-color: white;
  --mat-tab-header-active-focus-indicator-color: white;
  --mat-tab-header-active-hover-indicator-color: white;
}

::ng-deep .mat-tab-label-active {
  font-weight: bold;
}

::ng-deep .mat-mdc-tab-header {
  border: 1px solid white;
}

::ng-deep .mdc-tab-indicator {
  background-color: white;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
